import { Checkbox, CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Calculators from './components/calculators/Calculators';
import Chemistry from './components/calculators/Chemistry';
import Combat from './components/calculators/Combat';
import Destruction from './components/calculators/Destruction';
import Explosives from './components/calculators/Explosives';
import Farming from './components/calculators/Farming';
import Fletching from './components/calculators/Fletching';
import Mechanics from './components/calculators/Mechanics';
import Metalworking from './components/calculators/Metalworking';
import Mining from './components/calculators/Mining';
import Stealing from './components/calculators/Stealing';
import Survival from './components/calculators/Survival';
import Trading from './components/calculators/Trading';
import Guides from './components/guides/Guides';
import MiningGuide from './components/guides/Mining/MiningGuide';
import Enemies from './components/other/Enemies';
import Items from './components/other/Items';
import WorldMap from './components/other/Map';

// Define theme settings
const light = {
  palette: {
    type: 'light',
  },
};

const dark = {
  palette: {
    type: 'dark',
  },
};

const App = () => {
  // The light theme is used by default
  // const [isDarkTheme, setIsDarkTheme] = useState(false);

  // This function is triggered when the Switch component is toggled
  // const changeTheme = () => {
  //   setIsDarkTheme(!isDarkTheme);
  // };
  return (
    <div>
      {/* <ThemeProvider
        theme={isDarkTheme ? createTheme(dark) : createTheme(light)}
      >
        <CssBaseline />
        <Checkbox checked={isDarkTheme} onChange={changeTheme} />
      </ThemeProvider> */}

      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Calculators} />

          <Route exact path='/calculators' component={Calculators} />
          <Route exact path='/map' component={WorldMap} />
          <Route exact path='/items' component={Items} />
          <Route exact path='/enemies' component={Enemies} />
          <Route exact path='/guides' component={Guides} />

          <Route exact path='/calculators/combat' component={Combat} />
          <Route exact path='/calculators/chemistry' component={Chemistry} />
          <Route exact path='/calculators/destruction' component={Destruction} />
          <Route exact path='/calculators/explosives' component={Explosives} />
          <Route exact path='/calculators/farming' component={Farming} />
          <Route exact path='/calculators/fletching' component={Fletching} />
          <Route exact path='/calculators/mechanics' component={Mechanics} />
          <Route exact path='/calculators/metalwork' component={Metalworking} />
          <Route exact path='/calculators/mining' component={Mining} />
          <Route exact path='/calculators/stealing' component={Stealing} />
          <Route exact path='/calculators/survival' component={Survival} />
          <Route exact path='/calculators/trading' component={Trading} />

          <Route exact path='/guides/mining' component={MiningGuide} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
