import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import String from '../../images/chemistry/string.PNG';
import DragonGas from '../../images/explosives/Dragon Gas.PNG';
import Gunpowder from '../../images/explosives/Gunpowder.PNG';
import LargeFreezeBomb from '../../images/explosives/Large Freeze Bomb.PNG';
import LargeGasGrenade from '../../images/explosives/Large Gas Grenade.PNG';
import LargeGrenade from '../../images/explosives/Large Grenade.PNG';
import LargeNapalmCocktail from '../../images/explosives/Large Napalm Cocktail.PNG';
import LiquidNitrogen from '../../images/explosives/Liquid Nitrogen.PNG';
import MediumFreezeBomb from '../../images/explosives/Medium Freeze Bomb.PNG';
import MediumGasGrenade from '../../images/explosives/Medium Gas Grenade.PNG';
import MediumGrenade from '../../images/explosives/Medium Grenade.PNG';
import MediumLifestealSpellbomb from '../../images/explosives/Medium Lifesteal Spellbomb.PNG';
import MediumNapalmCocktail from '../../images/explosives/Medium Napalm Cocktail.PNG';
import Napalm from '../../images/explosives/Napalm.PNG';
import SmallFreezeBomb from '../../images/explosives/Small Freeze Bomb.PNG';
import SmallGasGrenade from '../../images/explosives/Small Gas Grenade.PNG';
import SmallGrenade from '../../images/explosives/Small Grenade.PNG';
import SmallLifestealSpellbomb from '../../images/explosives/Small Lifesteal Spellbomb.PNG';
import SmallNapalmCocktail from '../../images/explosives/Small Napalm Cocktail.PNG';
import UnicornBlood from '../../images/explosives/Unicorn Blood.PNG';
import BombIcon from '../../images/icons/Bomb Icon.svg';
import BulletsIcon from '../../images/icons/Bullets Icon.svg';
import ExplosivesIcon from '../../images/icons/Explosives Icon.png';
import DamascusBar from '../../images/Items/Damascus Bar.png';
import DamascusBullets from '../../images/Items/Damascus Bullets.png';
import ECopperBar from '../../images/Items/E. Copper Bar.png';
import ECopperBullets from '../../images/Items/E. Copper Bullets.png';
import EGoldBar from '../../images/Items/E. Gold Bar.png';
import EGoldBullets from '../../images/Items/E. Gold Bullets.png';
import PsywoodBar from '../../images/Items/Psywood Bar.png';
import PsywoodBullets from '../../images/Items/Psywood Bullets.png';
import SteelBullets from '../../images/Items/Steel Bullets.png';
import Sulphur from '../../images/Items/Sulphur.png';
import BronzeBar from '../../images/metalworking/bronzebar.png';
import IronBar from '../../images/metalworking/ironbar.png';
import SteelBar from '../../images/metalworking/steelbar.png';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';



const explosivesData = [
    {level: 1, item: 'Small Grenade', experience: 3, image: BronzeBar, secondaryImage: String, thirdImage: Gunpowder, fourthImage: SmallGrenade, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: Gunpowder, thirdMaterialName: 'Gunpowder', thirdMaterialNumber: 1, combined: true},
    {level: 5, item: 'Small Napalm Grenade', experience: 6, image: BronzeBar, secondaryImage: String, thirdImage: Napalm, fourthImage: SmallNapalmCocktail, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: Napalm, thirdMaterialName: 'Napalm', thirdMaterialNumber: 1, combined: true},
    {level: 15, item: 'Small Gas Grenade', experience: 12, image: BronzeBar, secondaryImage: String, thirdImage: DragonGas, fourthImage: SmallGasGrenade, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: DragonGas, thirdMaterialName: 'Dragon Gas', thirdMaterialNumber: 1, combined: true},
    {level: 20, item: 'Small Freeze Grenade', experience: 15, image: BronzeBar, secondaryImage: String, thirdImage: LiquidNitrogen, fourthImage: SmallFreezeBomb, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: LiquidNitrogen, thirdMaterialName: 'Liquid Nitrogen', thirdMaterialNumber: 1, combined: true},
    {level: 25, item: 'Medium Grenade', experience: 18, image: IronBar, secondaryImage: String, thirdImage: Gunpowder, fourthImage: MediumGrenade, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: Gunpowder, thirdMaterialName: 'Gunpowder', thirdMaterialNumber: 1, combined: true},
    {level: 35, item: 'Medium Napalm Grenade', experience: 24, image: IronBar, secondaryImage: String, thirdImage: Napalm, fourthImage: MediumNapalmCocktail, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: Napalm, thirdMaterialName: 'Napalm', thirdMaterialNumber: 1, combined: true},
    {level: 40, item: 'Medium Gas Grenade', experience: 27, image: IronBar, secondaryImage: String, thirdImage: DragonGas, fourthImage: MediumGasGrenade, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: DragonGas, thirdMaterialName: 'Dragon Gas', thirdMaterialNumber: 1, combined: true},
    {level: 45, item: 'Medium Freeze Grenade', experience: 30, image: IronBar, secondaryImage: String, thirdImage: LiquidNitrogen, fourthImage: MediumFreezeBomb, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: LiquidNitrogen, thirdMaterialName: 'Liquid Nitrogen', thirdMaterialNumber: 1, combined: true},
    {level: 55, item: 'Large Grenade', experience: 36, image: SteelBar, secondaryImage: String, thirdImage: Gunpowder, fourthImage: LargeGrenade, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: Gunpowder, thirdMaterialName: 'Gunpowder', thirdMaterialNumber: 1, combined: true},
    {level: 60, item: 'Large Napalm Grenade', experience: 39, image: SteelBar, secondaryImage: String, thirdImage: Napalm, fourthImage: LargeNapalmCocktail, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: Napalm, thirdMaterialName: 'Napalm', thirdMaterialNumber: 1, combined: true},
    {level: 65, item: 'Large Gas Grenade', experience: 42, image: SteelBar, secondaryImage: String, thirdImage: DragonGas, fourthImage: LargeGasGrenade, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: DragonGas, thirdMaterialName: 'Dragon Gas', thirdMaterialNumber: 1, combined: true},
    {level: 75, item: 'Large Freeze Grenade', experience: 48, image: SteelBar, secondaryImage: String, thirdImage: LiquidNitrogen, fourthImage: LargeFreezeBomb, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: LiquidNitrogen, thirdMaterialName: 'Liquid Nitrogen', thirdMaterialNumber: 1, combined: true},
    {level: 80, item: 'Small Lifesteal Grenade', experience: 51, image: IronBar, secondaryImage: String, thirdImage: UnicornBlood, fourthImage: Gunpowder, fifthImage: SmallLifestealSpellbomb, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: UnicornBlood, thirdMaterialName: 'Unicorn Blood', thirdMaterialNumber: 1, fourthMaterial: Gunpowder, fourthMaterialName: 'Gunpowder', fourthMaterialNumber: 1, combined: true},
    {level: 85, item: 'Medium Lifesteal Grenade', experience: 54, image: SteelBar, secondaryImage: String, thirdImage: UnicornBlood, fourthImage: Gunpowder, fifthImage: MediumLifestealSpellbomb, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: String, secondaryMaterialName: 'String', secondaryMaterialNumber: 1, thirdMaterial: UnicornBlood, thirdMaterialName: 'Unicorn Blood', thirdMaterialNumber: 1, fourthMaterial: Gunpowder, fourthMaterialName: 'Gunpowder', fourthMaterialNumber: 1, combined: true}
];

const bulletsData = [
    {level: 10, item: 'Steel Bullets', experience: 9, image: SteelBar, secondaryImage: Gunpowder, thirdImage: Sulphur, fourthImage: SteelBullets, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: Gunpowder, secondaryMaterialName: 'Gunpowder', secondaryMaterialNumber: 1, thirdMaterial: Sulphur, thirdMaterialName: 'Sulphur', thirdMaterialNumber: 1, combined: true},
    {level: 30, item: 'Psywood Bullets', experience: 21, image: PsywoodBar, secondaryImage: Gunpowder, thirdImage: Sulphur, fourthImage: PsywoodBullets, material: PsywoodBar, materialName: 'Psywood Bar', materialNumber: 1, secondaryMaterial: Gunpowder, secondaryMaterialName: 'Gunpowder', secondaryMaterialNumber: 1, thirdMaterial: Sulphur, thirdMaterialName: 'Sulphur', thirdMaterialNumber: 1, combined: true},
    {level: 50, item: 'E. Copper Bullets', experience: 33, image: ECopperBar, secondaryImage: Gunpowder, thirdImage: Sulphur, fourthImage: ECopperBullets, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 1, secondaryMaterial: Gunpowder, secondaryMaterialName: 'Gunpowder', secondaryMaterialNumber: 1, thirdMaterial: Sulphur, thirdMaterialName: 'Sulphur', thirdMaterialNumber: 1, combined: true},
    {level: 70, item: 'E. Gold Bullets', experience: 45, image: EGoldBar, secondaryImage: Gunpowder, thirdImage: Sulphur, fourthImage: EGoldBullets, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 1, secondaryMaterial: Gunpowder, secondaryMaterialName: 'Gunpowder', secondaryMaterialNumber: 1, thirdMaterial: Sulphur, thirdMaterialName: 'Sulphur', thirdMaterialNumber: 1, combined: true},
    {level: 90, item: 'Damascus Bullets', experience: 57, image: DamascusBar, secondaryImage: Gunpowder, thirdImage: Sulphur, fourthImage: DamascusBullets, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 1, secondaryMaterial: Gunpowder, secondaryMaterialName: 'Gunpowder', secondaryMaterialNumber: 1, thirdMaterial: Sulphur, thirdMaterialName: 'Sulphur', thirdMaterialNumber: 1, combined: true}
];

export default class Explosives extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {explosivesData: explosivesData, bulletsData: bulletsData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.explosivesData, this.state.bulletsData, level)
    }

    handleExperienceChange(event, explosivesData, bulletsData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        explosivesData = calculateNumberNeeded(explosivesData, experienceNeeded, currentLevel);
        this.setState({explosivesData: explosivesData});
        explosivesData = calculateMaterialsNeeded(explosivesData, currentLevel);
        this.setState({explosivesData: explosivesData});

        bulletsData = calculateNumberNeeded(bulletsData, experienceNeeded, currentLevel);
        this.setState({bulletsData: bulletsData});
        bulletsData = calculateMaterialsNeeded(bulletsData, currentLevel);
        this.setState({bulletsData: bulletsData});
    }

    handleDesiredLevelChange(event, explosivesData, bulletsData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience, levels);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        explosivesData = calculateNumberNeeded(explosivesData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({explosivesData: explosivesData});
        explosivesData = calculateMaterialsNeeded(explosivesData, currentLevel);
        this.setState({explosivesData: explosivesData});

        bulletsData = calculateNumberNeeded(bulletsData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({bulletsData: bulletsData});
        bulletsData = calculateMaterialsNeeded(bulletsData, currentLevel);
        this.setState({bulletsData: bulletsData});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={ExplosivesIcon} className="header-left"></Image>
                        <Header.Content>Explosives
                            <Header.Subheader>Artisan</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.explosivesData, this.state.bulletsData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.explosivesData, this.state.bulletsData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>

                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                                <Header as='h2'>
                                    <Image src={BombIcon} size="large"/>
                                    <span className="divider-spacer">Explosives</span>
                                </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.explosivesData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                                <Header as='h2'>
                                    <Image src={BulletsIcon} size="large"/>
                                    <span className="divider-spacer">Bullets</span>
                                </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.bulletsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
        )
    }
}