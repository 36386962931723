import React from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Button, Divider, Dropdown, Form, Grid, Header, Icon, Image, Input, Segment, Table } from "semantic-ui-react";
import Bells from '../../audio/Game Sounds/delayed/Bells.wav';
import DrinkPotion from '../../audio/Game Sounds/delayed/DrinkPotion Delayed.wav';
import FarmingPick from '../../audio/Game Sounds/delayed/FarmingPick Delayed.wav';
import FarmingPlant from '../../audio/Game Sounds/delayed/FarmingPlant Delayed.wav';
import LevelUpSound from '../../audio/Game Sounds/delayed/LevelUp Delayed.wav';
import OpenBank from '../../audio/Game Sounds/delayed/OpenBank Delayed.wav';
import TeleportSound from '../../audio/Game Sounds/delayed/Teleport Delayed.wav';
import { levels } from '../../config/Levels.js';
import AcaciaSeeds from '../../images/farming/Acacia Seed.PNG';
import AcaciaTree from '../../images/farming/acaciatree.PNG';
import AvocadoSeeds from '../../images/farming/Avacado Seeds.PNG';
import Cherries from '../../images/farming/cherries.PNG';
import CherrySeeds from '../../images/farming/Cherry Seeds.PNG';
import CucumberSeeds from '../../images/farming/Cucumber Seeds.PNG';
import Cucumber from '../../images/farming/cucumber.PNG';
import LettuceSeeds from '../../images/farming/Lettuce Seeds.PNG';
import Lettuce from '../../images/farming/lettuce.PNG';
import OakSeeds from '../../images/farming/Oak Seed.PNG';
import OakTree from '../../images/farming/oaktree.PNG';
import OnionSeeds from '../../images/farming/Onion Seeds.PNG';
import Onion from '../../images/farming/Onion.PNG';
import PotatoSeeds from '../../images/farming/Potato Seeds.PNG';
import Potato from '../../images/farming/potato.PNG';
import PsychicSeeds from '../../images/farming/Psychic Seed.png';
import PsychicTree from '../../images/farming/psywoodtree.png';
import RiceSeeds from '../../images/farming/Rice Seeds.PNG';
import TomatoSeeds from '../../images/farming/Tomato Seeds.PNG';
import WasabiSeeds from '../../images/farming/Wasabi Plant Starts.PNG';
import WeerwoodSeeds from '../../images/farming/Weerwood Seed.PNG';
import WeerwoodTree from '../../images/farming/WeerwoodTree.png';
import WheatSeeds from '../../images/farming/Wheat Seeds.PNG';
import Wheat from '../../images/farming/Wheat.PNG';
import WillowSeeds from '../../images/farming/Willow Seed.PNG';
import WillowTree from '../../images/farming/willowtree.PNG';
import FarmerIcon from "../../images/icons/Farmer Icon.svg";
import FarmingIcon from "../../images/icons/Farming Icon.png";
import TreeIcon from "../../images/icons/Tree Icon.svg";
import Avocado from '../../images/survival/avacadoes.PNG';
import Rice from '../../images/survival/rice.PNG';
import SkullkinSeed from '../../images/farming/Skullkin Seed.PNG';
import Tomato from '../../images/survival/tomato.png';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';


const farmingAllotmentsData = [
    {level: 1, item: 'Potato', experience: 2, image: PotatoSeeds, secondaryImage: Potato, material: PotatoSeeds, materialName: 'Potato Seeds', materialNumber: 1},
    {level: 10, item: 'Onion', experience: 4, image: OnionSeeds, secondaryImage: Onion, material: OnionSeeds, materialName: 'Onion Seeds', materialNumber: 1},
    {level: 20, item: 'Wheat', experience: 6, image: WheatSeeds, secondaryImage: Wheat, material: WheatSeeds, materialName: 'Wheat Seeds', materialNumber: 1},
    {level: 30, item: 'Lettuce', experience: 10, image: LettuceSeeds, secondaryImage: Lettuce, material: LettuceSeeds, materialName: 'Lettuce Seeds', materialNumber: 1},
    {level: 40, item: 'Cucumber', experience: 15, image: CucumberSeeds, secondaryImage: Cucumber, material: CucumberSeeds, materialName: 'Cucumber Seeds', materialNumber: 1},
    {level: 50, item: 'Rice', experience: 35, image: RiceSeeds, secondaryImage: Rice, material: RiceSeeds, materialName: 'Rice Seeds', materialNumber: 1},
    {level: 60, item: 'Tomato', experience: 45, image: TomatoSeeds, secondaryImage: Tomato, material: TomatoSeeds, materialName: 'Tomato Seeds', materialNumber: 1},
    {level: 70, item: 'Cherries', experience: 70, image: CherrySeeds, secondaryImage: Cherries, material: CherrySeeds, materialName: 'Cherry Seeds', materialNumber: 1},
    {level: 80, item: 'Avocado', experience: 150, image: AvocadoSeeds, secondaryImage: Avocado, material: AvocadoSeeds, materialName: 'Avocado Seeds', materialNumber: 1},
    {level: 90, item: 'Wasabi', experience: 200, image: WasabiSeeds, secondaryImage: WasabiSeeds, material: WasabiSeeds, materialName: 'Wasabi Seeds', materialNumber: 1},
];

const farmingTreesData = [
    {level: 5, item: 'Oak Tree', experience: 525, image: OakSeeds, secondaryImage: OakTree, material: OakSeeds, materialName: 'Oak Seeds', materialNumber: 1},
    {level: 25, item: 'Willow Tree', experience: 1125, image: WillowSeeds, secondaryImage: WillowTree, material: WillowSeeds, materialName: 'Willow Seeds', materialNumber: 1},
    {level: 45, item: 'Psychic Tree', experience: 2125, image: PsychicSeeds, secondaryImage: PsychicTree, material: PsychicSeeds, materialName: 'Psychic Seeds', materialNumber: 1},
    {level: 65, item: 'Acacia Tree', experience: 3375, image: AcaciaSeeds, secondaryImage: AcaciaTree, material: AcaciaSeeds, materialName: 'Acacia Seeds', materialNumber: 1},
    {level: 85, item: 'Weerwood Tree', experience: 6250, image: WeerwoodSeeds, secondaryImage: WeerwoodTree, material: WeerwoodSeeds, materialName: 'Weerwood Seeds', materialNumber: 1},
];

const farmingTimerData = [
    {item: 'Skullkin', duration: 3600, image: SkullkinSeed},
    {item: 'Potato', duration: 2400, image: PotatoSeeds},
    {item: 'Onion', duration: 2400, image: OnionSeeds},
    {item: 'Wheat', duration: 2400, image: WheatSeeds},
    {item: 'Lettuce', duration: 2400, image: LettuceSeeds},
    {item: 'Cucumber', duration: 2400, image: CucumberSeeds},
    {item: 'Rice', duration: 4800, image: RiceSeeds},
    {item: 'Tomato', duration: 4800, image: TomatoSeeds},
    {item: 'Cherries', duration: 4800, image: CherrySeeds},
    {item: 'Avocado', duration: 4800, image: AvocadoSeeds},
    {item: 'Wasabi', duration: 4800, image: WasabiSeeds},
    {item: 'Oak Tree', duration: 21600, image: OakSeeds},
    {item: 'Willow Tree', duration: 21600, image: WillowSeeds},
    {item: 'Psychic Tree', duration: 32400, image: PsychicSeeds},
    {item: 'Acacia Tree', duration: 32400, image: AcaciaSeeds},
    {item: 'Weerwood Tree', duration: 43200, image: WeerwoodSeeds},
];

const sounds = [
    {name: 'Level Up', file: LevelUpSound},
    {name: 'Teleport', file: TeleportSound},
    {name: 'Drink Potion', file: DrinkPotion},
    {name: 'Farming Pick', file: FarmingPick},
    {name: 'Farming Plant', file: FarmingPlant},
    {name: 'Open Bank', file: OpenBank},
    {name: 'Bells', file: Bells}

]

const renderTime = ({ remainingTime }) => {
    let display;
    let hours = Math.floor(remainingTime / (60 * 60));
    let minutes = Math.floor(remainingTime % (60 * 60) / 60);
    let seconds = Math.ceil(remainingTime % (60 * 60) % 60);

    if (remainingTime === 0) {
      return <div style={{fontSize: '30px'}}>Farm Run!</div>;
    }

    if(hours) {
        display = `${hours} : ${minutes} : ${seconds}`
    } else if(minutes === 0) {
        display = seconds;
    } else {
        display = `${minutes} : ${seconds}`
    }

    return (
        <div style={{fontSize: '35px'}}>{display}</div>
    );
  };


export default class Farming extends React.Component
{

    audio;

    constructor(props)
    {
        super(props);
        this.state = {farmingAllotmentsData: farmingAllotmentsData, farmingTreesData: farmingTreesData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0, isPlaying: false, duration: undefined, key: 1, sound: 'Bells', isSoundOn: false}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.farmingAllotmentsData, this.state.farmingTreesData, level)
    }

    handleExperienceChange(event, farmingAllotmentsData, farmingTreesData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        farmingAllotmentsData = calculateNumberNeeded(farmingAllotmentsData, experienceNeeded, currentLevel);
        this.setState({farmingAllotmentsData: farmingAllotmentsData});
        farmingAllotmentsData = calculateMaterialsNeeded(farmingAllotmentsData, currentLevel);
        this.setState({farmingAllotmentsData: farmingAllotmentsData});

        farmingTreesData = calculateNumberNeeded(farmingTreesData, experienceNeeded, currentLevel);
        this.setState({farmingTreesData: farmingTreesData});
        farmingTreesData = calculateMaterialsNeeded(farmingTreesData, currentLevel);
        this.setState({farmingTreesData: farmingTreesData});
    }

    handleDesiredLevelChange(event, farmingAllotmentsData, farmingTreesData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        farmingAllotmentsData = calculateNumberNeeded(farmingAllotmentsData, experienceGoal - this.state.currentExperience, this.state.farmingTreesData, currentLevel);
        this.setState({farmingAllotmentsData: farmingAllotmentsData});
        farmingAllotmentsData = calculateMaterialsNeeded(farmingAllotmentsData, currentLevel);
        this.setState({farmingAllotmentsData: farmingAllotmentsData});

        farmingTreesData = calculateNumberNeeded(farmingTreesData, experienceGoal - this.state.currentExperience, this.state.farmingTreesData, currentLevel);
        this.setState({farmingTreesData: farmingTreesData});
        farmingTreesData = calculateMaterialsNeeded(farmingTreesData, currentLevel);
        this.setState({farmingTreesData: farmingTreesData});
    }

    switchTimer(e) {
        if(this.state.isSoundOn) {
            this.setState({isSoundOn: false});
            this.setState({isPlaying: false});
            this.setState({key: this.state.key + 1});
            this.audio.pause();
            return;
        }

        this.setState({isPlaying: !this.state.isPlaying});
    }

    resetTimer(e) {
        let seconds = 0; 

        if(e.target.innerText === '') {
            this.setState({duration: undefined});
            this.setState({key: this.state.key + 1});
            this.setState({isPlaying: false});
            return;
        }

        if(e.target.innerText !== 'Reset' && e.target.innerText !== '') {
            seconds = farmingTimerData.filter(it => it.item === e.target.innerText)[0].duration
        } else {
            seconds = this.state.duration;
        }
        this.setState({isPlaying: false});
        this.setState({duration: seconds});
        this.setState({key: this.state.key + 1});
    }

    timerComplete() {
        this.setState({isSoundOn: true});
        this.audio = new Audio(sounds.filter(it => it.name === this.state.sound)[0].file);
        this.audio.load();
        this.audio.play();
        this.audio.loop = true;
    }

    handleSoundChange(e) {
        this.setState({sound: e.target.innerText});
    }

    render()
    {
        return (
            <div> 
                <TopNav></TopNav>

                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={FarmingIcon} className="header-left"></Image>
                        <Header.Content>Farming
                            <Header.Subheader>Gathering</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.farmingAllotmentsData, this.state.farmingTreesData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.farmingAllotmentsData, this.state.farmingTreesData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                    <Divider horizontal>
                        <Header as='h2'>
                            <Image src={FarmerIcon} size="large"/>
                            <span className="divider-spacer">Allotments</span>
                        </Header>
                    </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.farmingAllotmentsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={TreeIcon} size="large"/>
                                <span className="divider-spacer">Trees</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.farmingTreesData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={FarmerIcon} size="large"/>
                                <span className="divider-spacer">Timer</span>
                            </Header>
                        </Divider>

                        <Segment raised color='yellow'>
                            <Grid columns={2}>
                                <Grid.Column width={7}>
                                    <Form>
                                        <Form.Field>
                                            <label>Plant</label>
                                            <Dropdown
                                                placeholder='Select a plant'
                                                clearable
                                                fluid
                                                search
                                                selection
                                                scrolling
                                                style={{width: '250px'}}
                                                onChange={(e) => this.resetTimer(e)}
                                                options={farmingTimerData.map(plant => ({key: plant.item, text: plant.item, value: plant.item, image: plant.image}))}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Sound</label>
                                            <Dropdown
                                                placeholder='Select a sound'
                                                fluid
                                                defaultValue={this.state.sound}
                                                selection
                                                scrolling
                                                style={{width: '250px'}}
                                                onChange={(e) => this.handleSoundChange(e)}
                                                options={sounds.map(sound => ({key: sound.name, text: sound.name, value: sound.name}))}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            {this.state.duration ? <Button size='tiny' circular icon labelPosition='left' onClick={(e) => this.switchTimer(e)}>{this.state.isSoundOn ? 'Stop' : this.state.isPlaying ? 'Pause' : 'Play'} {this.state.isSoundOn ? <Icon name='stop' /> : this.state.isPlaying ? <Icon name='pause' /> : <Icon name='play' />} </Button> : null }
                                            {this.state.duration ? <Button size='tiny' circular onClick={(e) => this.resetTimer(e)}>Reset</Button> : null }

                                        </Form.Field>
                                    </Form>

                                </Grid.Column>

                                <Grid.Column>
                                    {this.state.duration ? 
                                        <CountdownCircleTimer
                                        key={this.state.key}
                                        isPlaying={this.state.isPlaying}
                                        size={200}
                                        duration={this.state.duration}
                                        colors={[["#DB0F13", 0.33], ["#FFD700", 0.33], ["#4BB543"]]}
                                        onComplete={(e) => this.timerComplete(e)}>
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    : null}
                                </Grid.Column>
                            </Grid>
                                 
                                        



                        </Segment>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
            </div>
        )
    }
}