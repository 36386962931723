import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import AccuracyPot from '../../images/chemistry/accuracypot.PNG';
import AdrenalGland from '../../images/chemistry/adrenalgland.PNG';
import BloodOfDestruction from '../../images/chemistry/bloodofdestruction.png';
import DefencePotion from '../../images/chemistry/defencepot.PNG';
import DestructionPotion from '../../images/chemistry/destructionpot.PNG';
import DragonEye from '../../images/chemistry/dragoneye.PNG';
import Feathers from '../../images/chemistry/feather.PNG';
import HealthPotion from '../../images/chemistry/healthpot.PNG';
import LargeAccuracyPotion from '../../images/chemistry/largeaccuracypot.PNG';
import LargeDefencePotion from '../../images/chemistry/largedefencepot.PNG';
import LargeDestructionPot from '../../images/chemistry/largedestructionpot.PNG';
import LargeHealthPotion from '../../images/chemistry/largehealthpot.PNG';
import LargeMeleePotion from '../../images/chemistry/largemeleepot.PNG';
import LargePsychicPotion from '../../images/chemistry/largepsychicpot.PNG';
import LargeRangingPotion from '../../images/chemistry/largerangingpot.PNG';
import LargeStaminaPotion from '../../images/chemistry/largestaminapot.PNG';
import MeleePotion from '../../images/chemistry/meleepot.PNG';
import PsychicPotion from '../../images/chemistry/psychicpot.PNG';
import PsyVialOfWater from '../../images/chemistry/psyvialofwater.png';
import RangingPotion from '../../images/chemistry/rangingpot.PNG';
import StaminaPotion from '../../images/chemistry/staminapot.PNG';
import TortoiseShell from '../../images/chemistry/Tortoise Shell.PNG';
import VialOfWater from '../../images/chemistry/vialofwater.PNG';
import BombIcon from '../../images/icons/Bomb Icon.svg';
import ChemistryIcon from '../../images/icons/Chemistry Icon.png';
import PotionIcon from '../../images/icons/PotionIcon.svg';
import AirtightContainer from '../../images/Items/Airtight Container.png';
import Coal from '../../images/Items/Coal Ore.png';
import DragonGas from '../../images/Items/Dragon Gas.png';
import EnchantedIce from '../../images/Items/Enchanted Ice.png';
import GunpowderKeg from '../../images/Items/Gunpowder Keg.png';
import LiquidNitrogen from '../../images/Items/Liquid Nitrogen.png';
import Napalm from '../../images/Items/Napalm.png';
import NitrogenGas from '../../images/Items/Nitrogen Gas.png';
import Potash from '../../images/Items/Potash.png';
import ReinforcedBoD from '../../images/Items/Reinforced BoD.png';
import Sulphur from '../../images/Items/Sulphur.png';
import LesserActuator from '../../images/mechanics/lesseractuator.png';
import ECopperBar from '../../images/metalworking/ecopperbar.png';
import IronBar from '../../images/metalworking/ironbar.png';
import AcaciaBark from '../../images/survival/Acacia Bark.PNG';
import PsywoodBark from '../../images/survival/Psywood Bark.PNG';
import RawBeef from '../../images/survival/rawmeat.png';
import Roast from '../../images/survival/roast.PNG';
import WeerwoodBark from '../../images/survival/Weerwood Bark.PNG';
import WillowBark from '../../images/survival/Willow Bark.PNG';
import YellowwoodBark from '../../images/survival/Yellowwood Bark.PNG';
import TopNav from '../other/TopNav';





const chemistryData = [
    {level: 1, item: 'Health Potion', experience: 3, image: VialOfWater, secondaryImage: TortoiseShell, thirdImage: HealthPotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: TortoiseShell, secondaryMaterialName: 'Tortoise Shell', secondaryMaterialNumber: 1, combined: true},
    {level: 5, item: 'Melee Potion', experience: 5, image: VialOfWater, secondaryImage: IronBar, thirdImage: MeleePotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: IronBar, secondaryMaterialName: 'Iron Bar', secondaryMaterialNumber: 1, combined: true},
    {level: 10, item: 'Ranging Potion', experience: 7, image: VialOfWater, secondaryImage: Feathers, thirdImage: RangingPotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: Feathers, secondaryMaterialName: 'Feathers', secondaryMaterialNumber: 10, combined: true},
    {level: 15, item: 'Psychic Potion', experience: 9, image: VialOfWater, secondaryImage: LesserActuator, thirdImage: PsychicPotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: LesserActuator, secondaryMaterialName: 'Lesser Actuator', secondaryMaterialNumber: 10, combined: true},
    {level: 20, item: 'Stamina Potion', experience: 11, image: VialOfWater, secondaryImage: WillowBark, thirdImage: StaminaPotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: WillowBark, secondaryMaterialName: 'Willow Bark', secondaryMaterialNumber: 1, combined: true},
    {level: 25, item: 'Defence Potion', experience: 13, image: VialOfWater, secondaryImage: RawBeef, thirdImage: DefencePotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: RawBeef, secondaryMaterialName: 'Any Raw Meat', secondaryMaterialNumber: 1, combined: true},
    {level: 30, item: 'Accuracy Potion', experience: 15, image: VialOfWater, secondaryImage: YellowwoodBark, thirdImage: AccuracyPot, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: YellowwoodBark, secondaryMaterialName: 'Yellowwood Bark', secondaryMaterialNumber: 1, combined: true},
    {level: 35, item: 'Destruction Potion', experience: 18, image: VialOfWater, secondaryImage: BloodOfDestruction, thirdImage: DestructionPotion, material: VialOfWater, materialName: 'Vial of Water', materialNumber: 1, secondaryMaterial: BloodOfDestruction, secondaryMaterialName: 'Blood of Destruction', secondaryMaterialNumber: 1, combined: true},
    {level: 40, item: 'Large Health Potion', experience: 21, image: PsyVialOfWater, secondaryImage: AcaciaBark, thirdImage: LargeHealthPotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: AcaciaBark, secondaryMaterialName: 'Acacia Bark', secondaryMaterialNumber: 1, combined: true},
    {level: 45, item: 'Large Melee Potion', experience: 24, image: PsyVialOfWater, secondaryImage: ECopperBar, thirdImage: LargeMeleePotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: ECopperBar, secondaryMaterialName: 'E. Copper Bar', secondaryMaterialNumber: 1, combined: true},
    {level: 50, item: 'Large Ranging Potion', experience: 27, image: PsyVialOfWater, secondaryImage: WeerwoodBark, thirdImage: LargeRangingPotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: WeerwoodBark, secondaryMaterialName: 'Weerwood Bark', secondaryMaterialNumber: 1, combined: true},
    {level: 55, item: 'Large Psychic Potion', experience: 30, image: PsyVialOfWater, secondaryImage: PsywoodBark, thirdImage: LargePsychicPotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: PsywoodBark, secondaryMaterialName: 'Psywood Bark', secondaryMaterialNumber: 1, combined: true},
    {level: 60, item: 'Large Stamina Potion', experience: 33, image: PsyVialOfWater, secondaryImage: AdrenalGland, thirdImage: LargeStaminaPotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: AdrenalGland, secondaryMaterialName: 'Adrenal Gland', secondaryMaterialNumber: 1, combined: true},
    {level: 70, item: 'Large Defence Potion', experience: 36, image: PsyVialOfWater, secondaryImage: Roast, thirdImage: LargeDefencePotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: Roast, secondaryMaterialName: 'Any Roast', secondaryMaterialNumber: 1, combined: true},
    {level: 80, item: 'Large Accuracy Potion', experience: 39, image: PsyVialOfWater, secondaryImage: DragonEye, thirdImage: LargeAccuracyPotion, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: DragonEye, secondaryMaterialName: 'Dragon\'s Eye', secondaryMaterialNumber: 1, combined: true},
    {level: 85, item: 'Large Destruction Potion', experience: 42, image: PsyVialOfWater, secondaryImage: ReinforcedBoD, thirdImage: LargeDestructionPot, material: PsyVialOfWater, materialName: 'Psyvial of Water', materialNumber: 1, secondaryMaterial: ReinforcedBoD, secondaryMaterialName: 'Reinforced Blood of Destruction', secondaryMaterialNumber: 1, combined: true},
];

const explosiveComponentData = [
    {level: 1, item: 'Dragon Gas', experience: 2, image: DragonGas, material: AirtightContainer, materialName: 'Airtight Container', materialNumber: 1},
    {level: 28, item: 'Gunpowder Keg', experience: 14, image: Potash, secondaryImage: Sulphur, thirdImage: Coal, fourthImage: GunpowderKeg, material: Potash, materialName: 'Potash', materialNumber: 1, secondaryMaterial: Sulphur, secondaryMaterialName: 'Sulphur', secondaryMaterialNumber: 1, thirdMaterial: Coal, thirdMaterialName: 'Coal', thirdMaterialNumber: 1, combined: true},
    {level: 38, item: 'Liquid Nitrogen', experience: 20, image: NitrogenGas, secondaryImage: EnchantedIce, thirdImage: LiquidNitrogen, material: NitrogenGas, materialName: 'Nitrogen Gas', materialNumber: 1, secondaryMaterial: EnchantedIce, secondaryMaterialName: 'Enchanted Ice', secondaryMaterialNumber: 1, combined: true},
    {level: 45, item: 'Nitrogen Gas', experience: 10, image: NitrogenGas, material: AirtightContainer, materialName: 'Airtight Container', materialNumber: 1},
    {level: 48, item: 'Napalm', experience: 26, image: NitrogenGas, secondaryImage: Sulphur, thirdImage: Napalm, material: NitrogenGas, materialName: 'Nitrogen Gas', materialNumber: 1, secondaryMaterial: Sulphur, secondaryMaterialName: 'Sulphur', secondaryMaterialNumber: 1, combined: true},

];

export default class Chemistry extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {chemistryData: chemistryData, explosiveComponentData: explosiveComponentData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.chemistryData, this.state.explosiveComponentData, level)
    }

    handleExperienceChange(event, chemistryData, explosiveComponentData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience, levels);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        chemistryData = calculateNumberNeeded(chemistryData, experienceNeeded, currentLevel);
        this.setState({chemistryData: chemistryData});
        chemistryData = calculateMaterialsNeeded(chemistryData, currentLevel);
        this.setState({chemistryData: chemistryData});

        explosiveComponentData = calculateNumberNeeded(explosiveComponentData, experienceNeeded, currentLevel);
        this.setState({explosiveComponentData: explosiveComponentData});
        explosiveComponentData = calculateMaterialsNeeded(explosiveComponentData, currentLevel);
        this.setState({explosiveComponentData: explosiveComponentData});
    }

    handleDesiredLevelChange(event, chemistryData, explosiveComponentData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        chemistryData = calculateNumberNeeded(chemistryData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({chemistryData: chemistryData});
        chemistryData = calculateMaterialsNeeded(chemistryData, currentLevel);
        this.setState({chemistryData: chemistryData});

        explosiveComponentData = calculateNumberNeeded(explosiveComponentData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({explosiveComponentData: explosiveComponentData});
        explosiveComponentData = calculateMaterialsNeeded(explosiveComponentData, currentLevel);
        this.setState({explosiveComponentData: explosiveComponentData});
    }

    render()
    {
        return (
            <div> 
                <TopNav></TopNav>

                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={ChemistryIcon} className="header-left"></Image>
                        <Header.Content>Chemistry
                            <Header.Subheader>Artisan</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.chemistryData, this.state.explosiveComponentData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.chemistryData, this.state.explosiveComponentData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                    <Grid.Row className="table-margin">
                        <Grid.Column stretched>

                        <Divider horizontal>
                        <Header as='h2'>
                            <Image src={PotionIcon} size="large"/>
                            <span className="divider-spacer">Potions</span>
                        </Header>
                        </Divider>

                            <Segment raised>
                                <Table celled color='yellow' compact="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                            <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.chemistryData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>

                        <Divider horizontal>
                        <Header as='h2'>
                            <Image src={BombIcon} size="large"/>
                            <span className="divider-spacer">Explosive Components</span>
                        </Header>
                        </Divider>

                            <Segment raised>
                                <Table celled color='yellow' compact="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                            <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.explosiveComponentData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>

                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}