import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import MiningIcon from "../../images/icons/Mining Icon.png";
import PickaxeIcon from "../../images/icons/Pickaxe Icon.svg";
import DemonicShard from '../../images/Items/Demonic Shards.png';
import Pot from '../../images/Items/Pot.png';
import Potash from '../../images/Items/Potash.png';
import Sulphur from '../../images/Items/Sulphur.png';
import Clay from '../../images/mining/clay.png';
import Coal from '../../images/mining/coal.png';
import CopperOre from '../../images/mining/copperore.png';
import DamascusOre from '../../images/mining/damascusore.png';
import EnchantedIce from '../../images/mining/elementalice.png';
import GoldOre from '../../images/mining/goldore.png';
import IronOre from '../../images/mining/ironore.png';
import SilverOre from '../../images/mining/silverore.png';
import TinOre from '../../images/mining/tinore.png';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';
import bro from '../../images/Items - Large/Coins.png';


const miningData = [
    {level: 1, item: 'Copper Ore', experience: 3, image: CopperOre},
    {level: 10, item: 'Tin Ore', experience: 5, image: TinOre},
    {level: 20, item: 'Iron Ore', experience: 9, image: IronOre},
    {level: 25, item: 'Clay', experience: 12, image: Clay},
    {level: 30, item: 'Coal', experience: 14, image: Coal},
    {level: 35, item: 'Potash', experience: 15, image: Potash, material: Pot, materialName: 'Pot', materialNumber: 1 },
    {level: 45, item: 'Silver Ore', experience: 15, image: SilverOre},
    {level: 50, item: 'Sulphur', experience: 25, image: Sulphur},
    {level: 55, item: 'Gold Ore', experience: 35, image: GoldOre},
    {level: 70, item: 'Enchanted Ice', experience: 70, image: EnchantedIce},
    {level: 80, item: 'Demonic Shards', experience: 65, image: DemonicShard},
    {level: 85, item: 'Damascus Ore', experience: 140, image: DamascusOre},
];

export default class Mining extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {miningData: miningData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.miningData, level)
    }

    handleExperienceChange(event, miningData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        miningData = calculateNumberNeeded(miningData, experienceNeeded, currentLevel);
        this.setState({miningData: miningData});
        miningData = calculateMaterialsNeeded(miningData, currentLevel);
        this.setState({miningData: miningData});
    }

    handleDesiredLevelChange(event, miningData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        miningData = calculateNumberNeeded(miningData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({miningData: miningData});
        miningData = calculateMaterialsNeeded(miningData, currentLevel);
        this.setState({miningData: miningData});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={MiningIcon} className="header-left"></Image>
                        <Header.Content>Mining
                            <Header.Subheader>Gathering</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.miningData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.miningData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={PickaxeIcon} size="large"/>
                                <span className="divider-spacer">Mining</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.miningData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
            </div>
        )
    }
}