import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import chemistry from "../../images/chemistry/chemistry.png";
import destruction from '../../images/destruction/destruction.png';
import explosives from '../../images/explosives/explosives.png';
import farming from '../../images/farming/farming.png';
import fletching from '../../images/fletching/fletching.png';
import mechanics from '../../images/mechanics/mechanics.png';
import metalworking from "../../images/metalworking/metalworking.png";
import mining from "../../images/mining/mining.png";
import melee from '../../images/random/melee.png';
import shalken from '../../images/random/Shalken.png';
import stealing from "../../images/stealing/stealing.png";
import survival from "../../images/survival/survival.png";
import trading from '../../images/trading/trading.png';
import TopNav from '../other/TopNav';
import Link from 'react-router-dom';

export default class Calculators extends React.Component
{
    render()
    {
        return (
            <div>
                <TopNav></TopNav>

                <div >
                <div className='center-calculators'>
                    <Card.Group itemsPerRow={6} stackable>
                        <Card raised color='yellow' href='calculators/combat' image={melee} header='Combat' meta='Combat' description='Combat is leveled by fighting monsters.' extra={<a href='https://damascus.fandom.com/wiki/Melee' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/chemistry' image={chemistry} header='Chemistry' meta='Artisan' description='Chemistry is leveled by crafting potions.' extra={<a href='https://damascus.fandom.com/wiki/Chemistry' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/destruction' image={destruction} header='Destruction' meta='Miscellaneous' description='Destruction is leveled by destroying items.' extra={<a href='https://damascus.fandom.com/wiki/Destruction' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/explosives' image={explosives} header='Explosives' meta='Artisan' description='Explosives is leveled by crafting bombs.' extra={<a href='https://damascus.fandom.com/wiki/Explosives' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/farming' image={farming} header='Farming' meta='Gathering' description='Farming is leveled by planting and picking trees and vegetables.' extra={<a href='https://damascus.fandom.com/wiki/Farming' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/fletching' image={fletching} header='Fletching' meta='Artisan' description='Fletching is leveled by crafting bows and arrows.' extra={<a href='https://damascus.fandom.com/wiki/Fletching' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/mechanics' image={mechanics} header='Mechanics' meta='Artisan' description='Mechanics is leveled by crafting magic and ranging items.' extra={<a href='https://damascus.fandom.com/wiki/Mechanics' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/metalwork' image={metalworking} header='Metalwork' meta='Artisan' description='Metalwork is leveled by crafting melee armor and weapons.' extra={<a href='https://damascus.fandom.com/wiki/Metalwork' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/mining' image={mining} header='Mining' meta='Gathering' description='Mining is leveled by gathering ores.' extra={<a href='https://damascus.fandom.com/wiki/Mining' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/stealing' image={stealing} header='Stealing' meta='Gathering' description='Stealing is leveled by stealing from stalls and creatures.' extra={<a href='https://damascus.fandom.com/wiki/Stealing' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/survival' image={survival} header='Survival' meta='Gathering' description='Survival is leveled by fishing, cooking, and firemaking.' extra={<a href='https://damascus.fandom.com/wiki/Survival' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                        <Card raised color='yellow' href='calculators/trading' image={trading} header='Trading' meta='Miscellaneous' description='Trading is leveled by buying and selling items.' extra={<a href='https://damascus.fandom.com/wiki/Trading' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                    </Card.Group>
                </div>
                </div>
                {/* <Grid>
                    <Grid.Row columns={2} only='large screen'>
                    <Grid.Column>
                        <Segment>Large Screen</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Large Screen</Segment>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} only='widescreen'>
                    <Grid.Column>
                        <Segment>Widescreen</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Widescreen</Segment>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} only='mobile'>
                    <Grid.Column>
                        <Image href='calculators/combat' image={melee}></Image>
                        <Card raised color='yellow' href='calculators/combat' image={melee} header='Combat' meta='Combat' description='Combat is leveled by fighting monsters.' extra={<a href='https://damascus.fandom.com/wiki/Melee' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4} only='computer'>
                    <Grid.Column>
                        <Segment>Computer</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Computer</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Computer</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Computer</Segment>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} only='tablet'>
                    <Grid.Column>
                        <Segment>Tablet</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Tablet</Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>Tablet</Segment>
                    </Grid.Column>
                    </Grid.Row>
                </Grid> */}






                <img src={shalken} alt="Shalken"  className="shalken"></img>

                {/* <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
                  <Container textAlign='center'>
                    <Image centered src={Banner} />
                    <Divider inverted section />
                    <List horizontal inverted divided link size='small'>
                      <List.Item as='a' href='#'>
                        Update History
                      </List.Item>
                      <List.Item as='a' href='#'>
                        Suggestions
                      </List.Item>
                    </List>
                  </Container>
              </Segment> */}
            </div>
            
        )
    }
}