import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import AcaciaArrowshafts from '../../images/fletching/Acacia Arrowshaft.png';
import AcaciaLongbow from '../../images/fletching/Acacia Longbow.png';
import AcaciaShortbow from '../../images/fletching/Acacia Shortbow.png';
import BaobabArrowshafts from '../../images/fletching/Baobab Arrowshafts.png';
import BaobabLongbow from '../../images/fletching/Baobab Longbow.png';
import BaobabShortbow from '../../images/fletching/Baobab Shortbow.png';
import Bowstring from '../../images/fletching/bowstring.png';
import BronzeArrowheads from '../../images/fletching/Bronze Arrow Head.png';
import BronzeArrows from '../../images/fletching/Bronze Arrow.png';
import CopperArrowheads from '../../images/fletching/Copper Arrow Head.png';
import CopperArrows from '../../images/fletching/Copper Arrow.png';
import DamascusArrowheads from '../../images/fletching/Damascus Arrow Head.png';
import DamascusArrows from '../../images/fletching/Damascus Arrows.png';
import DeadwoodArrowshafts from '../../images/fletching/Deadwood Arrowshafts.png';
import DeadwoodLongbow from '../../images/fletching/Deadwood Longbow.png';
import DeadwoodShortbow from '../../images/fletching/Deadwood Shortbow.png';
import ECopperArrows from '../../images/fletching/E Copper Arrows.png';
import EGoldArrows from '../../images/fletching/E Gold Arrows.png';
import ECopperArrowheads from '../../images/fletching/E. Copper Arrow Head.png';
import EGoldArrowheads from '../../images/fletching/E. Gold Arrow Head.png';
import IronArrowheads from '../../images/fletching/Iron Arrow Head.png';
import IronArrows from '../../images/fletching/Iron Arrow.png';
import OakArrowshafts from '../../images/fletching/Oak Arrowshafts.png';
import OakLongbow from '../../images/fletching/Oak Longbow.png';
import OakShortbow from '../../images/fletching/Oak Shortbow.png';
import PineArrowshafts from '../../images/fletching/Pine Arrowshaft.png';
import PineLongbow from '../../images/fletching/Pine Longbow.png';
import PineShortbow from '../../images/fletching/Pine Shortbow.png';
import PsywoodArrowheads from '../../images/fletching/Psychic Arrow Head.png';
import PsywoodArrows from '../../images/fletching/Psywood Arrow.png';
import PsywoodArrowshafts from '../../images/fletching/Psywood Arrowshaft.png';
import PsywoodLongbow from '../../images/fletching/Psywood Longbow.png';
import PsywoodShortbow from '../../images/fletching/Psywood Shortbow.png';
import RedwoodArrowshafts from '../../images/fletching/Redwood Arrowshaft.png';
import RedwoodLongbow from '../../images/fletching/Redwood Longbow.png';
import RedwoodShortbow from '../../images/fletching/Redwood Shortbow.png';
import SteelArrowheads from '../../images/fletching/Steel Arrow Head.png';
import SteelArrows from '../../images/fletching/Steel Arrow.png';
import WeerwoodArrowshafts from '../../images/fletching/Weerwood Arrowshaft.png';
import WeerwoodLongbow from '../../images/fletching/Weerwood Longbow.png';
import WeerwoodShortbow from '../../images/fletching/Weerwood Shortbow.png';
import WillowArrowshafts from '../../images/fletching/Willow Arrowshaft.png';
import WillowLongbow from '../../images/fletching/Willow Longbow.png';
import WillowShortbow from '../../images/fletching/Willow Shortbow.png';
import YellowwoodArrowshafts from '../../images/fletching/Yellowwood Arrowshaft.png';
import YellowwoodLongbow from '../../images/fletching/Yellowwood Longbow.png';
import YellowwoodShortbow from '../../images/fletching/Yellowwood Shortbow.png';
import ArrowIcon from '../../images/icons/Arrow Icon.svg';
import BoltIcon from '../../images/icons/Bolt Icon.svg';
import BowIcon from '../../images/icons/Bow Icon.svg';
import FletchingIcon from "../../images/icons/Fletching Icon.png";
import ThrowingKnifeIcon from '../../images/icons/Throwing Knife Icon.svg';
import BronzeBar from '../../images/Items/Bronze Bar.png';
import BronzeBolts from '../../images/Items/Bronze Bolts.png';
import BronzeThrowingKnife from '../../images/Items/Bronze Throwing Knife.png';
import CopperBar from '../../images/Items/Copper Bar.png';
import CopperBolts from '../../images/Items/Copper Bolts.png';
import CopperThrowingKnife from '../../images/Items/Copper Throwing Knife.png';
import DamascusBar from '../../images/Items/Damascus Bar.png';
import DamascusBolts from '../../images/Items/Damascus Bolts.png';
import DamascusThrowingKnife from '../../images/Items/Damascus Throwing Knife.png';
import ECopperBar from '../../images/Items/E. Copper Bar.png';
import ECopperBolts from '../../images/Items/E. Copper Bolts.png';
import ECopperThrowingKnife from '../../images/Items/E. Copper Throwing Knife.png';
import EGoldBar from '../../images/Items/E. Gold Bar.png';
import EGoldBolts from '../../images/Items/E. Gold Bolts.png';
import EGoldThrowingKnife from '../../images/Items/E. Gold Throwing Knife.png';
import Feather from '../../images/Items/Feather.png';
import IronBar from '../../images/Items/Iron Bar.png';
import IronBolts from '../../images/Items/Iron Bolts.png';
import IronThrowingKnife from '../../images/Items/Iron Throwing Knife.png';
import PsywoodBar from '../../images/Items/Psywood Bar.png';
import PsywoodBolts from '../../images/Items/Psywood Bolts.png';
import PsywoodThrowingKnifes from '../../images/Items/Psywood Throwing Knife.png';
import SteelBar from '../../images/Items/Steel Bar.png';
import SteelBolts from '../../images/Items/Steel Bolts.png';
import SteelThrowingKnife from '../../images/Items/Steel Throwing Knife.png';
import AcaciaLogs from '../../images/survival/acaciawood.PNG';
import BaobabLogs from '../../images/survival/baobabwood.PNG';
import DeadwoodLogs from '../../images/survival/deadwood.PNG';
import OakLogs from '../../images/survival/oakwood.PNG';
import PineLogs from '../../images/survival/pinewood.PNG';
import PsywoodLogs from '../../images/survival/psywoodlogs.png';
import RedwoodLogs from '../../images/survival/redwood.PNG';
import WeerwoodLogs from '../../images/survival/weerwood.PNG';
import WillowLogs from '../../images/survival/willowwood.PNG';
import YellowWoodLogs from '../../images/survival/yellowwood.PNG';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';





const fletchingArrowShaftsData = [
    {level: 1, item: 'Deadwood Arrowshafts', experience: 3, image: DeadwoodLogs, secondaryImage: DeadwoodArrowshafts, material: DeadwoodLogs, materialName: 'Deadwood Log', materialNumber: 1},
    {level: 10, item: 'Pine Arrowshafts', experience: 5, image: PineLogs, secondaryImage: PineArrowshafts, material: PineLogs, materialName: 'Pine Log', materialNumber: 1},
    {level: 20, item: 'Oak Arrowshafts', experience: 7, image: OakLogs, secondaryImage: OakArrowshafts, material: OakLogs, materialName: 'Oak Log', materialNumber: 1},
    {level: 30, item: 'Willow Arrowshafts', experience: 9, image: WillowLogs, secondaryImage: WillowArrowshafts, material: WillowLogs, materialName: 'Willow Log', materialNumber: 1},
    {level: 40, item: 'Yellowwood Arrowshafts', experience: 11, image: YellowWoodLogs, secondaryImage: YellowwoodArrowshafts, material: YellowWoodLogs, materialName: 'Yellowwood Log', materialNumber: 1},
    {level: 50, item: 'Psywood Arrowshafts', experience: 13, image: PsywoodLogs, secondaryImage: PsywoodArrowshafts, material: PsywoodLogs, materialName: 'Psywood Log', materialNumber: 1},
    {level: 60, item: 'Acacia Arrowshafts', experience: 15, image: AcaciaLogs, secondaryImage: AcaciaArrowshafts, material: AcaciaLogs, materialName: 'Acacia Log', materialNumber: 1},
    {level: 70, item: 'Weerwood Arrowshafts', experience: 17, image: WeerwoodLogs, secondaryImage: WeerwoodArrowshafts, material: WeerwoodLogs, materialName: 'Weerwood Log', materialNumber: 1},
    {level: 80, item: 'Redwood Arrowshafts', experience: 19, image: RedwoodLogs, secondaryImage: RedwoodArrowshafts, material: RedwoodLogs, materialName: 'Redwood Log', materialNumber: 1},
    {level: 90, item: 'Baobab Arrowshafts', experience: 21, image: BaobabLogs, secondaryImage: BaobabArrowshafts, material: BaobabLogs, materialName: 'Baobab Log', materialNumber: 1},
];

const fletchingArrowsData = [
    {level: 7, item: 'Copper Arrows', experience: 3, image: CopperArrowheads, secondaryImage: DeadwoodArrowshafts, thirdImage: CopperArrows, material: CopperArrowheads, materialName: 'Copper Arrowheads', materialNumber: 1, secondaryMaterial: DeadwoodArrowshafts, secondaryMaterialName: 'Deadwood Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 17, item: 'Bronze Arrows', experience: 5, image: BronzeArrowheads, secondaryImage: PineArrowshafts, thirdImage: BronzeArrows, material: BronzeArrowheads, materialName: 'Bronze Arrowheads', materialNumber: 1, secondaryMaterial: PineArrowshafts, secondaryMaterialName: 'Pine Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 27, item: 'Iron Arrows', experience: 7, image: IronArrowheads, secondaryImage: OakArrowshafts, thirdImage: IronArrows, material: IronArrowheads, materialName: 'Iron Arrowheads', materialNumber: 1, secondaryMaterial: OakArrowshafts, secondaryMaterialName: 'Oak Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 37, item: 'Steel Arrows', experience: 9, image: SteelArrowheads, secondaryImage: WillowArrowshafts, thirdImage: SteelArrows, material: SteelArrowheads, materialName: 'Steel Arrowheads', materialNumber: 1, secondaryMaterial: WillowArrowshafts, secondaryMaterialName: 'Willow Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 47, item: 'Psywood Arrows', experience: 11, image: PsywoodArrowheads, secondaryImage: YellowwoodArrowshafts, thirdImage: PsywoodArrows, material: PsywoodArrowheads, materialName: 'Psywood Arrowheads', materialNumber: 1, secondaryMaterial: YellowwoodArrowshafts, secondaryMaterialName: 'Yellowwood Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 57, item: 'E. Copper Arrows', experience: 13, image: ECopperArrowheads, secondaryImage: PsywoodArrowshafts, thirdImage: ECopperArrows, material: ECopperArrowheads, materialName: 'E. Copper Arrowheads', materialNumber: 1, secondaryMaterial: PsywoodArrowshafts, secondaryMaterialName: 'Psywood Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 67, item: 'E. Gold Arrows', experience: 15, image: EGoldArrowheads, secondaryImage: AcaciaArrowshafts, thirdImage: EGoldArrows, material: EGoldArrowheads, materialName: 'E. Gold Arrowheads', materialNumber: 1, secondaryMaterial: AcaciaArrowshafts, secondaryMaterialName: 'Acacia Arrowshafts', secondaryMaterialNumber: 1, combined: true},
    {level: 84, item: 'Damascus Arrows', experience: 17, image: DamascusArrowheads, secondaryImage: WeerwoodArrowshafts, thirdImage: DamascusArrows, material: DamascusArrowheads, materialName: 'Damascus Arrowheads', materialNumber: 1, secondaryMaterial: WeerwoodArrowshafts, secondaryMaterialName: 'Weerwood Arrowshafts', secondaryMaterialNumber: 1, combined: true}
];

const fletchingBowsData = [
    {level: 1, item: 'Deadwood Shortbow', experience: 3, image: DeadwoodLogs, secondaryImage: Bowstring, thirdImage: DeadwoodShortbow, material: DeadwoodLogs, materialName: 'Deadwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 5, item: 'Deadwood Longbow', experience: 4, image: DeadwoodLogs, secondaryImage: Bowstring, thirdImage: DeadwoodLongbow,  material: DeadwoodLogs, materialName: 'Deadwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 12, item: 'Pine Shortbow', experience: 6, image: PineLogs, secondaryImage: Bowstring, thirdImage: PineShortbow, material: PineLogs, materialName: 'Ping Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 16, item: 'Pine Longbow', experience: 7, image: PineLogs, secondaryImage: Bowstring, thirdImage: PineLongbow, material: PineLogs, materialName: 'Pine Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 22, item: 'Oak Shortbow', experience: 8, image: OakLogs, secondaryImage: Bowstring, thirdImage: OakShortbow, material: OakLogs, materialName: 'Oak Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 26, item: 'Oak Longbow', experience: 10, image: OakLogs, secondaryImage: Bowstring, thirdImage: OakLongbow, material: OakLogs, materialName: 'Oak Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 32, item: 'Willow Shortbow', experience: 11, image: WillowLogs, secondaryImage: Bowstring, thirdImage: WillowShortbow, material: WillowLogs, materialName: 'Willow Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 36, item: 'Willow Longbow', experience: 13, image: WillowLogs, secondaryImage: Bowstring, thirdImage: WillowLongbow, material: WillowLogs, materialName: 'Willow Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 42, item: 'Yellowwood Shortbow', experience: 15, image: YellowWoodLogs, secondaryImage: Bowstring, thirdImage: YellowwoodShortbow, material: YellowWoodLogs, materialName: 'Yellowwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 46, item: 'Yellowwood Longbow', experience: 17, image: YellowWoodLogs, secondaryImage: Bowstring, thirdImage: YellowwoodLongbow, material: YellowWoodLogs, materialName: 'Yellowwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 52, item: 'Psywood Shortbow', experience: 18, image: PsywoodLogs, secondaryImage: Bowstring, thirdImage: PsywoodShortbow, material: PsywoodLogs, materialName: 'Psywood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 56, item: 'Psywood Longbow', experience: 20, image: PsywoodLogs, secondaryImage: Bowstring, thirdImage: PsywoodLongbow, material: PsywoodLogs, materialName: 'Psywood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 62, item: 'Acacia Shortbow', experience: 21, image: AcaciaLogs, secondaryImage: Bowstring, thirdImage: AcaciaShortbow, material: AcaciaLogs, materialName: 'Acacia Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 66, item: 'Acacia Longbow', experience: 23, image: AcaciaLogs, secondaryImage: Bowstring, thirdImage: AcaciaLongbow, material: AcaciaLogs, materialName: 'Acacia Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 72, item: 'Weerwood Shortbow', experience: 24, image: WeerwoodLogs, secondaryImage: Bowstring, thirdImage: WeerwoodShortbow, material: WeerwoodLogs, materialName: 'Weerwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 76, item: 'Weerwood Longbow', experience: 26, image: WeerwoodLogs, secondaryImage: Bowstring, thirdImage: WeerwoodLongbow, material: WeerwoodLogs, materialName: 'Weerwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 82, item: 'Redwood Shortbow', experience: 27, image: RedwoodLogs, secondaryImage: Bowstring, thirdImage: RedwoodShortbow, material: RedwoodLogs, materialName: 'Redwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 86, item: 'Redwood Longbow', experience: 28, image: RedwoodLogs, secondaryImage: Bowstring, thirdImage: RedwoodLongbow, material: RedwoodLogs, materialName: 'Redwood Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 92, item: 'Baobab Shortbow', experience: 29, image: BaobabLogs, secondaryImage: Bowstring, thirdImage: BaobabShortbow, material: BaobabLogs, materialName: 'Baobab Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true},
    {level: 96, item: 'Baobab Longbow', experience: 30, image: BaobabLogs, secondaryImage: Bowstring, thirdImage: BaobabLongbow, material: BaobabLogs, materialName: 'Baobab Log', materialNumber: 1, secondaryMaterial: Bowstring, secondaryMaterialName: 'Bowstring', secondaryMaterialNumber: 1, combined: true}
];

const fletchingBoltsData = [
    {level: 15, item: 'Copper Bolts', experience: 5, image: CopperBar, secondaryImage: DeadwoodLogs, thirdImage: Feather, fourthImage: CopperBolts, material: CopperBar, materialName: 'Copper Bar', materialNumber: 1, secondaryMaterial: DeadwoodLogs, secondaryMaterialName: 'Deadwood Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 25, item: 'Bronze Bolts', experience: 8, image: BronzeBar, secondaryImage: PineLogs, thirdImage: Feather, fourthImage: BronzeBolts, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: PineLogs, secondaryMaterialName: 'Pine Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 35, item: 'Iron Bolts', experience: 11, image: IronBar, secondaryImage: OakLogs, thirdImage: Feather, fourthImage: IronBolts, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: OakLogs, secondaryMaterialName: 'Oak Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 45, item: 'Steel Bolts', experience: 14, image: SteelBar, secondaryImage: WillowLogs, thirdImage: Feather, fourthImage: SteelBolts, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: WillowLogs, secondaryMaterialName: 'Willow Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 55, item: 'Psywood Bolts', experience: 17, image: PsywoodBar, secondaryImage: AcaciaLogs, thirdImage: Feather, fourthImage: PsywoodBolts, material: PsywoodBar, materialName: 'Psywood Bar', materialNumber: 1, secondaryMaterial: AcaciaLogs, secondaryMaterialName: 'Acacia Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 65, item: 'E. Copper Bolts', experience: 20, image: ECopperBar, secondaryImage: WeerwoodLogs, thirdImage: Feather, fourthImage: ECopperBolts, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 1, secondaryMaterial: WeerwoodLogs, secondaryMaterialName: 'Weerwood Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 75, item: 'E. Gold Bolts', experience: 23, image: EGoldBar, secondaryImage: RedwoodLogs, thirdImage: Feather, fourthImage: EGoldBolts, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 1, secondaryMaterial: RedwoodLogs, secondaryMaterialName: 'Redwood Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
    {level: 85, item: 'Damascus Bolts', experience: 26, image: DamascusBar, secondaryImage: BaobabLogs, thirdImage: Feather, fourthImage: DamascusBolts, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 1, secondaryMaterial: BaobabLogs, secondaryMaterialName: 'Baobab Logs', secondaryMaterialNumber: 1, thirdMaterial: Feather, thirdMaterialName: 'Feather', thirdMaterialNumber: 1, combined: true},
];

const fletchingThrowingKnivesData = [
    {level: 10, item: 'Copper Throwing Knife', experience: 3, image: CopperBar, secondaryImage: DeadwoodLogs, thirdImage: CopperThrowingKnife, material: CopperBar, materialName: 'Copper Bar', materialNumber: 1, secondaryMaterial: DeadwoodLogs, secondaryMaterialName: 'Deadwood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 20, item: 'Bronze Throwing Knife', experience: 6, image: BronzeBar, secondaryImage: PineLogs, thirdImage: BronzeThrowingKnife, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: PineLogs, secondaryMaterialName: 'Pine Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 30, item: 'Iron Throwing Knife', experience: 9, image: IronBar, secondaryImage: OakLogs, thirdImage: IronThrowingKnife, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: OakLogs, secondaryMaterialName: 'Oak Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 40, item: 'Steel Throwing Knife', experience: 12, image: SteelBar, secondaryImage: WillowLogs, thirdImage: SteelThrowingKnife, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: WillowLogs, secondaryMaterialName: 'Willow Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 50, item: 'Psywood Throwing Knife', experience: 15, image: PsywoodBar, secondaryImage: AcaciaLogs, thirdImage: PsywoodThrowingKnifes, material: PsywoodBar, materialName: 'Psywood Bar', materialNumber: 1, secondaryMaterial: AcaciaLogs, secondaryMaterialName: 'Acacia Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 60, item: 'E. Copper Throwing Knife', experience: 18, image: ECopperBar, secondaryImage: WeerwoodLogs, thirdImage: ECopperThrowingKnife, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 1, secondaryMaterial: WeerwoodLogs, secondaryMaterialName: 'Weerwood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 70, item: 'E. Gold Throwing Knife', experience: 21, image: EGoldBar, secondaryImage: RedwoodLogs, thirdImage: EGoldThrowingKnife, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 1, secondaryMaterial: RedwoodLogs, secondaryMaterialName: 'Redwood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 80, item: 'Damascus Throwing Knife', experience: 24, image: DamascusBar, secondaryImage: BaobabLogs, thirdImage: DamascusThrowingKnife, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 1, secondaryMaterial: BaobabLogs, secondaryMaterialName: 'Baobab Logs', secondaryMaterialNumber: 1, combined: true},
];

export default class Fletching extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            currentExperience: '',
            desiredLevel: 0,
            experienceNeeded: 0,
            fletchingBowsData: fletchingBowsData,
            fletchingArrowsData: fletchingArrowsData,
            fletchingArrowShaftsData: fletchingArrowShaftsData,
            fletchingBoltsData: fletchingBoltsData,
            fletchingThrowingKnivesData: fletchingThrowingKnivesData}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.fletchingBowsData, this.state.fletchingArrowsData, this.state.fletchingArrowShaftsData, this.state.fletchingBoltsData, this.state.fletchingThrowingKnivesData, level)
    }

    handleExperienceChange(event, fletchingBowsData, fletchingArrowsData, fletchingArrowShaftsData, fletchingBoltsData, fletchingThrowingKnivesData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        fletchingBowsData = calculateNumberNeeded(fletchingBowsData, experienceNeeded, currentLevel);
        this.setState({fletchingBowsData: fletchingBowsData});
        fletchingBowsData = calculateMaterialsNeeded(fletchingBowsData, currentLevel);
        this.setState({fletchingBowsData: fletchingBowsData});

        fletchingArrowsData = calculateNumberNeeded(fletchingArrowsData, experienceNeeded, currentLevel);
        this.setState({fletchingArrowsData: fletchingArrowsData});
        fletchingArrowsData = calculateMaterialsNeeded(fletchingArrowsData, currentLevel);
        this.setState({fletchingArrowsData: fletchingArrowsData});

        fletchingArrowShaftsData = calculateNumberNeeded(fletchingArrowShaftsData, experienceNeeded, currentLevel);
        this.setState({fletchingArrowShaftsData: fletchingArrowShaftsData});
        fletchingArrowShaftsData = calculateMaterialsNeeded(fletchingArrowShaftsData, currentLevel);
        this.setState({fletchingArrowShaftsData: fletchingArrowShaftsData});

        fletchingBoltsData = calculateNumberNeeded(fletchingBoltsData, experienceNeeded, currentLevel);
        this.setState({fletchingBoltsData: fletchingBoltsData});
        fletchingBoltsData = calculateMaterialsNeeded(fletchingBoltsData, currentLevel);
        this.setState({fletchingBoltsData: fletchingBoltsData});

        fletchingThrowingKnivesData = calculateNumberNeeded(fletchingThrowingKnivesData, experienceNeeded, currentLevel);
        this.setState({fletchingThrowingKnivesData: fletchingThrowingKnivesData});
        fletchingThrowingKnivesData = calculateMaterialsNeeded(fletchingThrowingKnivesData, currentLevel);
        this.setState({fletchingThrowingKnivesData: fletchingThrowingKnivesData});
    }

    handleDesiredLevelChange(event, fletchingBowsData, fletchingArrowsData, fletchingArrowShaftsData, fletchingBoltsData, fletchingThrowingKnivesData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        let experienceNeeded = experienceGoal - this.state.currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel = findCurrentLevel(this.state.currentExperience);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        fletchingBowsData = calculateNumberNeeded(fletchingBowsData, experienceNeeded, currentLevel);
        this.setState({fletchingBowsData: fletchingBowsData});
        fletchingBowsData = calculateMaterialsNeeded(fletchingBowsData, currentLevel);
        this.setState({fletchingBowsData: fletchingBowsData});

        fletchingArrowsData = calculateNumberNeeded(fletchingArrowsData, experienceNeeded, currentLevel);
        this.setState({fletchingArrowsData: fletchingArrowsData});
        fletchingArrowsData = calculateMaterialsNeeded(fletchingArrowsData, currentLevel);
        this.setState({fletchingArrowsData: fletchingArrowsData});

        fletchingArrowShaftsData = calculateNumberNeeded(fletchingArrowShaftsData, experienceNeeded, currentLevel);
        this.setState({fletchingArrowShaftsData: fletchingArrowShaftsData});
        fletchingArrowShaftsData = calculateMaterialsNeeded(fletchingArrowShaftsData, currentLevel);
        this.setState({fletchingArrowShaftsData: fletchingArrowShaftsData});

        fletchingBoltsData = calculateNumberNeeded(fletchingBoltsData, experienceNeeded, currentLevel);
        this.setState({fletchingBoltsData: fletchingBoltsData});
        fletchingBoltsData = calculateMaterialsNeeded(fletchingBoltsData, currentLevel);
        this.setState({fletchingBoltsData: fletchingBoltsData});

        fletchingThrowingKnivesData = calculateNumberNeeded(fletchingThrowingKnivesData, experienceNeeded, currentLevel);
        this.setState({fletchingThrowingKnivesData: fletchingThrowingKnivesData});
        fletchingThrowingKnivesData = calculateMaterialsNeeded(fletchingThrowingKnivesData, currentLevel);
        this.setState({fletchingThrowingKnivesData: fletchingThrowingKnivesData});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={FletchingIcon} className="header-left"></Image>
                        <Header.Content>Fletching
                            <Header.Subheader>Artisan</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.fletchingBowsData, this.state.fletchingArrowsData, this.state.fletchingArrowShaftsData, this.state.fletchingBoltsData, this.state.fletchingThrowingKnivesData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.fletchingBowsData, this.state.fletchingArrowsData, this.state.fletchingArrowShaftsData, this.state.fletchingBoltsData, this.state.fletchingThrowingKnivesData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                    <Divider horizontal>
                        <Header as='h2'>
                            <Image src={BowIcon} size="large"/>
                            <span className="divider-spacer">Bows</span>
                        </Header>
                    </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.fletchingBowsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={ArrowIcon} size="large"/>
                                <span className="divider-spacer">Arrowshafts</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.fletchingArrowShaftsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={ArrowIcon} size="large"/>
                                <span className="divider-spacer">Arrows</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.fletchingArrowsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={BoltIcon} size="large"/>
                                <span className="divider-spacer">Bolts</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.fletchingBoltsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={ThrowingKnifeIcon} size="large"/>
                                <span className="divider-spacer">Throwing Knives</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.fletchingThrowingKnivesData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
        )
    }
}