import React from 'react';
import { Icon, Image, Popup, Table } from "semantic-ui-react";
import { levels } from '../config/Levels';

export function calculateMaterialsNeeded(data, currentLevel)
{
    for(let item in data)
    {
        let notHighEnough = data[item].level > currentLevel;
        let materialNeeded = data[item].materialNumber * data[item].numberNeeded;
        materialNeeded = notHighEnough ? 0 : materialNeeded;
        data[item].materialNeeded = materialNeeded;

        if(data[item].secondaryMaterial)
        {
            let secondaryMaterialNeeded = data[item].secondaryMaterialNumber * data[item].numberNeeded;
            secondaryMaterialNeeded = notHighEnough ? 0 : secondaryMaterialNeeded;
            data[item].secondaryMaterialNeeded = secondaryMaterialNeeded;
        }

        if(data[item].thirdMaterial)
        {
            let thirdMaterialNeeded = data[item].thirdMaterialNumber * data[item].numberNeeded;
            thirdMaterialNeeded = notHighEnough ? 0 : thirdMaterialNeeded;
            data[item].thirdMaterialNeeded = thirdMaterialNeeded;
        }

        if(data[item].fourthMaterial)
        {
            let fourthMaterialNeeded = data[item].fourthMaterialNumber * data[item].numberNeeded;
            fourthMaterialNeeded = notHighEnough ? 0 : fourthMaterialNeeded;
            data[item].fourthMaterialNeeded = fourthMaterialNeeded;
        }
    }
    return data;
}

export function findCurrentLevel(currentExperience)
{
    let currentLevel = 0;
    for (let [key, value] of Object.entries(levels))
    {
        if(value > currentExperience)
        {
            currentLevel = key - 1;
            break;
        }
    }

    currentLevel = currentLevel === '' ? 1 : currentLevel;
    return currentLevel;
}

export function calculateNumberNeeded(data, experienceNeeded, currentLevel)
{
    for(let item in data)
    {
        let notHighEnough = data[item].level > currentLevel;
        let numberNeeded = experienceNeeded / data[item].experience;
        numberNeeded = Math.sign(numberNeeded) === -1 ? 0 : numberNeeded;
        numberNeeded = notHighEnough ? 0 : numberNeeded;
        data[item].numberNeeded = Math.ceil(numberNeeded);
    }
    return data;
}

export function buildRows(data, currentLevel)
{
    let rows = [];

    for(let item in data)
    {
        let notHighEnough = data[item].level > currentLevel;
        let combined = data[item].combined === true ? <Icon name='plus'/> : <Icon name='arrow right'/>;
        let firstImage = <Image src={data[item].image} alt="Item Image" avatar/>;
        let secondaryImage = data[item].secondaryImage ? <span> {combined} <Image src={data[item].secondaryImage} alt="Secondary Image" avatar/> {data[item].fourthImage ? <Icon name='plus'/> : data[item].thirdImage ? <span> <Icon name='arrow right'/> </span>: null}</span> : null;
        let thirdImage = data[item].thirdImage ? <span> <Image src={data[item].thirdImage} alt="Third Image" avatar/> {data[item].fourthMaterial ? <Icon name='plus'/> : data[item].fourthImage ? <span> <Icon name='arrow right'/> </span>: null}</span> : null;
        let fourthImage = data[item].fourthImage ? <span><Image src={data[item].fourthImage} alt="Fourth Image" avatar/> {data[item].fifthImage ? <span><Icon name='arrow right'/> </span>: null}</span> : null;
        let fifthImage = data[item].fifthImage ? <span><Image src={data[item].fifthImage} alt="Fifth Image" avatar/></span> : null;
        let materialNeeded = data[item].materialNeeded ? data[item].materialNeeded.toLocaleString() : 0;
        let secondaryMaterialNeeded = data[item].secondaryMaterialNeeded ? data[item].secondaryMaterialNeeded.toLocaleString() : 0;
        let thirdMaterialNeeded = data[item].thirdMaterialNeeded ? data[item].thirdMaterialNeeded.toLocaleString() : 0;
        let fourthMaterialNeeded = data[item].fourthMaterialNeeded ? data[item].fourthMaterialNeeded.toLocaleString() : 0;
        let firstMaterial = <span className="item" style={{marginTop: '10px', marginRight: '40px', paddingTop: '0px', paddingRight: '0px', paddingBottom: '0px', width: '150px'}}><figure style={{marginTop: '0px', marginBottom: '0px'}}><Image src={data[item].material} style={{marginLeft: 'auto', marginRight: 'auto'}}  alt="Material" avatar/><figcaption className="caption">{data[item].materialName}</figcaption></figure><div className="floating ui yellow label">{materialNeeded}</div></span>;
        let secondaryMaterial = data[item].secondaryMaterial ? <a className="item" style={{marginTop: '10px', marginRight: '40px', paddingTop: '0px', paddingRight: '0px', width: '150px'}}><figure style={{marginTop: '0px', marginBottom: '0px'}}><Image src={data[item].secondaryMaterial} style={{marginLeft: 'auto', marginRight: 'auto'}}  alt="Secondary Material" avatar/><figcaption className="caption">{data[item].secondaryMaterialName}</figcaption></figure><div className="floating ui yellow label">{secondaryMaterialNeeded}</div></a> : null;
        let thirdMaterial = data[item].thirdMaterial ? <a className="item" style={{marginTop: '10px', marginRight: '40px', paddingTop: '0px', paddingRight: '0px', width: '150px'}}><figure style={{marginTop: '0px', marginBottom: '0px'}}><Image src={data[item].thirdMaterial} style={{marginLeft: 'auto', marginRight: 'auto'}}  alt="Third Material" avatar/><figcaption className="caption">{data[item].thirdMaterialName}</figcaption></figure><div className="floating ui yellow label">{thirdMaterialNeeded}</div></a> : null;
        let fourthMaterial = data[item].fourthMaterial ? <a className="item" style={{marginTop: '10px', marginRight: '40px', paddingTop: '0px', paddingRight: '0px', width: '150px'}}><figure style={{marginTop: '0px', marginBottom: '0px'}}><Image src={data[item].fourthMaterial} style={{marginLeft: 'auto', marginRight: 'auto'}}  alt="Fourth Material" avatar/><figcaption className="caption">{data[item].fourthMaterialName}</figcaption></figure><div className="floating ui yellow label">{fourthMaterialNeeded}</div></a> : null;
        let materials = <span><div className="ui tabular menu" style={{borderBottom: 'none'}}>{firstMaterial}{secondaryMaterial}{thirdMaterial}{fourthMaterial}</div></span>;
        let noMaterials = data.flatMap(it => it.material).every(it => it === undefined);

        if(notHighEnough)
        {
            rows.push(
                <Table.Row key={data[item].item}>
                    <Table.Cell negative>{data[item].level}</Table.Cell>
                    <Table.Cell negative>{firstImage} {secondaryImage} {thirdImage} {fourthImage} {fifthImage} {data[item].item}</Table.Cell>
                    <Table.Cell negative>{data[item].experience}</Table.Cell>
                    <Table.Cell negative>{data[item].numberNeeded}</Table.Cell>
                    {data[item].material ? <Table.Cell negative><span>{materials}</span></Table.Cell> : noMaterials ? null : <Table.Cell negative></Table.Cell>}
                </Table.Row>
            )
        }
        else
        {
            rows.push(
                <Table.Row key={data[item].item}>
                    <Table.Cell positive>{data[item].level}</Table.Cell>
                    <Table.Cell positive>{firstImage} {secondaryImage} {thirdImage} {fourthImage} {fifthImage} {data[item].item}</Table.Cell>
                    <Table.Cell positive>{data[item].experience}</Table.Cell>
                    <Table.Cell positive>{data[item].numberNeeded ? data[item].numberNeeded.toLocaleString() : null}</Table.Cell>
                    {data[item].material ? <Table.Cell positive><span>{materials}</span></Table.Cell> : noMaterials ? null : <Table.Cell positive></Table.Cell>}
                </Table.Row>
            )
        }
    }
    return rows;
}

export function buildExperienceTableRows(data) {
    let rows = [];

    for(let item of data) {
        rows.push(
            <Table.Row>
            <Table.Cell><Image centered src={require(`../images/Items - Large/${item.name}.png`).default} /></Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.level}</Table.Cell>
            <Table.Cell>
                {
                    <Popup wide='very' position='top center' trigger={<Icon name='compass outline' size='large'></Icon>}>
                        <Popup.Content>
                            <Image centered src={require(`../images/guides/${item.location}.png`).default} />
                        </Popup.Content>
                    </Popup>
                }<span>{item.location}</span>
            </Table.Cell>
        </Table.Row>
        );
    }
    return rows;
}