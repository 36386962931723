import { default as React } from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import PersonIcon from "../../images/icons/Person Icon.svg";
import StallIcon from "../../images/icons/Stall Icon.svg";
import StealingIcon from "../../images/icons/Stealing Icon.png";
import BreadStall from '../../images/stealing/breadstall.png';
import Cactuson from '../../images/stealing/cactuson.png';
import Catson from '../../images/stealing/catson.png';
import ChemistStall from '../../images/stealing/chemiststall.png';
import Frogman from '../../images/stealing/frogman.png';
import GroceryStall from '../../images/stealing/grocerystall.png';
import Guard from '../../images/stealing/guard.png';
import Man from '../../images/stealing/man.png';
import MeatStall from '../../images/stealing/meatstall.png';
import OwlMan from '../../images/stealing/owlman.png';
import RatMan from '../../images/stealing/ratman.png';
import SturgeonStall from '../../images/stealing/sturgeonstall.png';
import TigerMan from '../../images/stealing/tigerman.png';
import WeaponsStall from '../../images/stealing/weaponsstall.png';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';


const stealingPickpocketData = [
    {level: 1, item: 'Man / Woman', experience: 3, image: Man},
    {level: 10, item: 'Guard', experience: 8, image: Guard},
    {level: 20, item: 'Cactuson', experience: 10, image: Cactuson},
    {level: 30, item: 'Frogman', experience: 14, image: Frogman},
    {level: 50, item: 'Ratman', experience: 18, image: RatMan},
    {level: 65, item: 'Owlman', experience: 22, image: OwlMan},
    {level: 70, item: 'Catson', experience: 24, image: Catson},
    {level: 80, item: 'Tigerman', experience: 30, image: TigerMan}

];

const stealingStallData = [
    {level: 5, item: 'Bread Stall', experience: 5, image: BreadStall},
    {level: 25, item: 'Grocery Stall', experience: 12, image: GroceryStall},
    {level: 40, item: 'Meat Stall', experience: 16, image: MeatStall},
    {level: 60, item: 'Chemist Stall', experience: 20, image: ChemistStall},
    {level: 75, item: 'Weapons Stall', experience: 25, image: WeaponsStall},
    {level: 90, item: 'Sturgeon Stall', experience: 40, image: SturgeonStall}
];

export default class Stealing extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {stealingPickpocketData: stealingPickpocketData, stealingStallData: stealingStallData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.stealingPickpocketData, this.state.stealingStallData, level)
    }

    handleExperienceChange(event, stealingPickpocketData, stealingStallData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        stealingPickpocketData = calculateNumberNeeded(stealingPickpocketData, experienceNeeded, currentLevel);
        this.setState({stealingPickpocketData: stealingPickpocketData});
        stealingPickpocketData = calculateMaterialsNeeded(stealingPickpocketData, currentLevel);
        this.setState({stealingPickpocketData: stealingPickpocketData});

        stealingStallData = calculateNumberNeeded(stealingStallData, experienceNeeded, currentLevel);
        this.setState({stealingStallData: stealingStallData});
        stealingStallData = calculateMaterialsNeeded(stealingStallData, currentLevel);
        this.setState({stealingStallData: stealingStallData});
    }

    handleDesiredLevelChange(event, stealingPickpocketData, stealingStallData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience, levels);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        stealingPickpocketData = calculateNumberNeeded(stealingPickpocketData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({stealingPickpocketData: stealingPickpocketData});
        stealingPickpocketData = calculateMaterialsNeeded(stealingPickpocketData, currentLevel);
        this.setState({stealingPickpocketData: stealingPickpocketData});

        stealingStallData = calculateNumberNeeded(stealingStallData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({stealingStallData: stealingStallData});
        stealingStallData = calculateMaterialsNeeded(stealingStallData, currentLevel);
        this.setState({stealingStallData: stealingStallData});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={StealingIcon} className="header-left"></Image>
                        <Header.Content>Stealing
                            <Header.Subheader>Gathering</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.stealingPickpocketData, this.state.stealingStallData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.stealingPickpocketData, this.state.stealingStallData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                    <Grid.Row>
                        <Grid.Column stretched>


                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={StallIcon} size="large"/>
                                <span className="divider-spacer">Stalls</span>
                            </Header>
                        </Divider>

                            <Segment raised>
                                <Table celled color='yellow' compact='very'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.stealingStallData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>

                            <Divider horizontal>
                                <Header as='h2'>
                                    <Image src={PersonIcon} size="large"/>
                                    <span className="divider-spacer">Pickpocket</span>
                                </Header>
                            </Divider>

                            <Segment raised>
                                <Table celled color='yellow' compact='very'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.stealingPickpocketData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>

                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}