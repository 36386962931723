import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import DestructionIcon from '../../images/icons/Destruction Icon.png';
import FireIcon from '../../images/icons/Fire Icon.svg';
import DamascusCrossbow from '../../images/mechanics/damascuscrossbow.PNG';
import ECopperCrossBow from '../../images/mechanics/ecoppercrossbow.PNG';
import EGoldCrossbow from '../../images/mechanics/egoldcrossbow.PNG';
import PsychicCrossbow from '../../images/mechanics/psychiccrossbow.PNG';
import SteelCrossbow from '../../images/mechanics/steelcrossbow.PNG';
import BronzeShortSword from '../../images/weapons/Bronze/Bronze Short Sword.png';
import CopperShortSword from '../../images/weapons/Copper/Copper Short Sword.png';
import DamascusShortSword from '../../images/weapons/Damascus/Damascus Short Sword.png';
import ECopperShortSword from '../../images/weapons/E. Copper/E. Copper Short Sword.png';
import EGoldShortSword from '../../images/weapons/E. Gold/E. Gold Short Sword.PNG';
import IronShortSword from '../../images/weapons/Iron/Iron Short Sword.png';
import PsywoodShortSword from '../../images/weapons/Psywood/Psywood Short Sword.png';
import SteelShortSword from '../../images/weapons/Steel/Steel Short Sword.png';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';

const destructionData = [
    {level: 1, item: 'Copper Short Sword', experience: 1, image: CopperShortSword},
    {level: 1, item: 'Bronze Short Sword', experience: 2, image: BronzeShortSword},
    {level: 1, item: 'Iron Short Sword', experience: 3, image: IronShortSword},
    {level: 1, item: 'Steel Short Sword', experience: 7, image: SteelShortSword},
    {level: 1, item: 'Psywood Short Sword', experience: 17, image: PsywoodShortSword},
    {level: 1, item: 'E. Copper Short Sword', experience: 49, image: ECopperShortSword},
    {level: 1, item: 'E. Gold Short Sword', experience: 301, image: EGoldShortSword},
    {level: 1, item: 'Damascus Short Sword', experience: 2701, image: DamascusShortSword},
    {level: 1, item: 'Steel Crossbow', experience: 8, image: SteelCrossbow},
    {level: 1, item: 'Psywood Crossbow', experience: 16, image: PsychicCrossbow},
    {level: 1, item: 'E. Copper Crossbow', experience: 251, image: ECopperCrossBow},
    {level: 1, item: 'E. Gold Crossbow', experience: 651, image: EGoldCrossbow},
    {level: 1, item: 'Damascus Crossbow', experience: 2001, image: DamascusCrossbow}
];

export default class Destruction extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {destructionData: destructionData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.destructionData, level)
    }

    handleExperienceChange(event, destructionData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        destructionData = calculateNumberNeeded(destructionData, experienceNeeded, currentLevel);
        this.setState({destructionData: destructionData});
        destructionData = calculateMaterialsNeeded(destructionData, currentLevel);
        this.setState({destructionData: destructionData});
    }

    handleDesiredLevelChange(event, destructionData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience, levels);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        destructionData = calculateNumberNeeded(destructionData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({destructionData: destructionData});
        destructionData = calculateMaterialsNeeded(destructionData, currentLevel);
        this.setState({destructionData: destructionData});
    }

    handleBaseValueChange(event)
    {
        let baseValue = event.target.value;
        let numberNeeded = this.state.experienceNeeded / (baseValue / 10);
        numberNeeded = Math.sign(numberNeeded) === -1 ? 0 : numberNeeded;
        let manualCalcNumberNeeded = isFinite(numberNeeded) ? Math.ceil(numberNeeded) : 0;
        this.setState({manualCalcNumberNeeded: manualCalcNumberNeeded});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>

                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={DestructionIcon} className="header-left"></Image>
                        <Header.Content>Destruction
                            <Header.Subheader>Miscellaneous</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.destructionData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.destructionData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>

                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={FireIcon} size="large"/>
                                <span className="divider-spacer">Commonly Destroyed Items</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.destructionData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={FireIcon} size="large"/>
                                <span className="divider-spacer">Manual Calculation</span>
                            </Header>
                        </Divider>

                        <Segment raised color='yellow'>
                            <Form.Group>
                                <Form.Field>
                                    <label>Base / Alch Value</label>
                                    <Input maxLength="6" style={{width: 170}} fluid placeholder='Base / Alch Value' onChange={(e) => this.handleBaseValueChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Number Needed</label>{this.state.manualCalcNumberNeeded ? this.state.manualCalcNumberNeeded.toLocaleString(): 0}
                                </Form.Field>
                            </Form.Group>
                        </Segment>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
            </div>
        )
    }
}