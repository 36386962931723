import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import CrossbowIcon from "../../images/icons/Crossbow Icon.svg";
import MechanicsIcon from "../../images/icons/Mechanics Icon.png";
import PistolIcon from "../../images/icons/Pistol Icon.svg";
import RifleIcon from "../../images/icons/Rifle Icon.svg";
import RuneIcon from "../../images/icons/Rune Icon.svg";
import StaffIcon from "../../images/icons/Staff Icon.svg";
import TeleportIcon from "../../images/icons/Teleport Icon.svg";
import BindingActuator from '../../images/mechanics/bindingactuator.PNG';
import BlackDragonPlasma from '../../images/mechanics/blackdragonplasma.PNG';
import BlueDragonPlasma from '../../images/mechanics/bluedragonplasma.PNG';
import BronzeStaff from '../../images/mechanics/Bronze Staff.png';
import BurningBurdenTeleport from '../../images/mechanics/Burning Burden Teleport.PNG';
import CombatActuator from '../../images/mechanics/combatactuator.PNG';
import CopperStaff from '../../images/mechanics/Copper Staff.png';
import CopperDragonPlasma from '../../images/mechanics/copperdragonplasma.PNG';
import DamascusPistol from '../../images/mechanics/Damascus Pistol.png';
import DamascusRifle from '../../images/mechanics/Damascus Rifle.png';
import DamascusCrossbow from '../../images/mechanics/damascuscrossbow.PNG';
import DamascusDragonPlasma from '../../images/mechanics/damascusdragonplasma.PNG';
import DeadStaff from '../../images/mechanics/Dead Staff.png';
import DominiaTeleport from '../../images/mechanics/Dominia Teleport.PNG';
import ECopperPistol from '../../images/mechanics/E Copper Pistol.png';
import ECopperRile from '../../images/mechanics/E Copper Rifle.png';
import EGoldPistol from '../../images/mechanics/E Gold Pistol.png';
import EGoldRifle from '../../images/mechanics/E Gold Rifle.png';
import ECopperCrossBow from '../../images/mechanics/ecoppercrossbow.PNG';
import EGoldCrossbow from '../../images/mechanics/egoldcrossbow.PNG';
import GoldDragonPlasma from '../../images/mechanics/golddragonplasma.PNG';
import GreaterActuator from '../../images/mechanics/greateractuator.PNG';
import GreenDragonPlasma from '../../images/mechanics/greendragonplasma.PNG';
import HealingActuator from '../../images/mechanics/healingactuator.PNG';
import IcewyntaleTeleport from '../../images/mechanics/Icewyntale Teleport.PNG';
import IronStaff from '../../images/mechanics/Iron Staff.png';
import LesserActuator from '../../images/mechanics/lesseractuator.png';
import MtGreeffTeleport from '../../images/mechanics/Mt Greeff Teleport.PNG';
import OakStaff from '../../images/mechanics/Oak Staff.png';
import PineStaff from '../../images/mechanics/Pine Staff.png';
import PsychicPistol from '../../images/mechanics/Psychic Pistol.png';
import PsychicRifle from '../../images/mechanics/Psychic Rifle.png';
import PsychicCrossbow from '../../images/mechanics/psychiccrossbow.PNG';
import PsywoodStaff from '../../images/mechanics/Psywood Staff.png';
import RoguesRestTeleport from '../../images/mechanics/Rogue\'s Rest Teleport.PNG';
import SmugglersTeleport from '../../images/mechanics/Smuggler\'s Den Teleport.PNG';
import SplitCityTeleport from '../../images/mechanics/Split City Teleport.PNG';
import SteelPistol from '../../images/mechanics/Steel Pistol.png';
import SteelRifle from '../../images/mechanics/Steel Rifle.png';
import SteelStaff from '../../images/mechanics/Steel Staff.png';
import SteelCrossbow from '../../images/mechanics/steelcrossbow.PNG';
import TeleportingActuator from '../../images/mechanics/teleportingactuator.PNG';
import ThreeRiverFortTeleport from '../../images/mechanics/TRF Teleport.PNG';
import WillowStaff from '../../images/mechanics/Willow Staff.png';
import YellowStaff from '../../images/mechanics/Yellow Staff.png';
import BronzeBar from '../../images/metalworking/bronzebar.png';
import CopperBar from '../../images/metalworking/copperbar.png';
import DamascusBar from '../../images/metalworking/damascusbar.png';
import ECopperBar from '../../images/metalworking/ecopperbar.png';
import EGoldBar from '../../images/metalworking/egoldbar.png';
import IronBar from '../../images/metalworking/ironbar.png';
import PsychicBar from '../../images/metalworking/psychicbar.png';
import SteelBar from '../../images/metalworking/steelbar.png';
import EnchantedIce from '../../images/mining/elementalice.png';
import AcaciaLogs from '../../images/survival/acaciawood.PNG';
import BaobabLogs from '../../images/survival/baobabwood.PNG';
import DeadwoodLogs from '../../images/survival/deadwood.PNG';
import OakLogs from '../../images/survival/oakwood.PNG';
import PineLogs from '../../images/survival/pinewood.PNG';
import PsywoodLogs from '../../images/survival/psywoodlogs.png';
import RedwoodLogs from '../../images/survival/redwood.PNG';
import WeerwoodLogs from '../../images/survival/weerwood.PNG';
import WillowLogs from '../../images/survival/willowwood.PNG';
import YellowWoodLogs from '../../images/survival/yellowwood.PNG';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';

const mechanicsStavesData = [
    {level: 1, item: 'Deadwood Staff', experience: 3, image: DeadwoodLogs, secondaryImage: DeadStaff, material: DeadwoodLogs, materialName: 'Deadwood Log', materialNumber: 1},
    {level: 5, item: 'Pine Staff', experience: 4, image: PineLogs, secondaryImage: PineStaff, material: PineLogs, materialName: 'Pine Log', materialNumber: 1},
    {level: 9, item: 'Oak Staff', experience: 5, image: OakLogs, secondaryImage: OakStaff, material: OakLogs, materialName: 'Oak Log', materialNumber: 1},
    {level: 13, item: 'Willow Staff', experience: 6, image: WillowLogs, secondaryImage: WillowStaff, material: WillowLogs, materialName: 'Willow Log', materialNumber: 1},
    {level: 17, item: 'Yellow Staff', experience: 8, image: YellowWoodLogs, secondaryImage: YellowStaff, material: YellowWoodLogs, materialName: 'Yellowwood Log', materialNumber: 1},
    {level: 21, item: 'Copper Staff', experience: 9, image: CopperBar, secondaryImage: CopperStaff, material: CopperBar, materialName: 'Copper Bar', materialNumber: 1},
    {level: 25, item: 'Bronze Staff', experience: 10, image: BronzeBar, secondaryImage: BronzeStaff, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1},
    {level: 29, item: 'Iron Staff', experience: 11, image: IronBar, secondaryImage: IronStaff, material: IronBar, materialName: 'Iron Bar', materialNumber: 1},
    {level: 33, item: 'Steel Staff', experience: 13, image: SteelBar, secondaryImage: SteelStaff, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1},
    {level: 37, item: 'Psywood Staff', experience: 14, image: PsychicBar, secondaryImage: PsywoodStaff, material: PsychicBar, materialName: 'Psychic Bar', materialNumber: 1},
];

const mechanicsActuatorsData = [
    {level: 15, item: 'Lesser Actuator', experience: 7, image: BronzeBar, secondaryImage: GreenDragonPlasma, thirdImage: LesserActuator, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1, secondaryMaterial: GreenDragonPlasma, secondaryMaterialName: 'Green Dragon Plasma', secondaryMaterialNumber: 1, combined: true},
    {level: 30, item: 'Healing Actuator', experience: 12, image: IronBar, secondaryImage: BlueDragonPlasma, thirdImage: HealingActuator, material: IronBar, materialName: 'Iron Bar', materialNumber: 1, secondaryMaterial: BlueDragonPlasma, secondaryMaterialName: 'Blue Dragon Plasma', secondaryMaterialNumber: 1, combined: true},
    {level: 45, item: 'Binding Actuator', experience: 17, image: SteelBar, secondaryImage: BlackDragonPlasma, thirdImage: BindingActuator, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1, secondaryMaterial: BlackDragonPlasma, secondaryMaterialName: 'Black Dragon Plasma', secondaryMaterialNumber: 1, combined: true},
    {level: 60, item: 'Greater Actuator', experience: 21, image: PsychicBar, secondaryImage: CopperDragonPlasma, thirdImage: GreaterActuator, material: PsychicBar, materialName: 'Psywood Bar', materialNumber: 1, secondaryMaterial: CopperDragonPlasma, secondaryMaterialName: 'Copper Dragon Plasma', secondaryMaterialNumber: 1, combined: true},
    {level: 75, item: 'Teleporting Actuator', experience: 26, image: ECopperBar, secondaryImage: GoldDragonPlasma, thirdImage: TeleportingActuator, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 1, secondaryMaterial: GoldDragonPlasma, secondaryMaterialName: 'Gold Dragon Plasma', secondaryMaterialNumber: 1, combined: true},
    {level: 95, item: 'Combat Actuator', experience: 35, image: EGoldBar, secondaryImage: DamascusDragonPlasma, thirdImage: CombatActuator, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 1, secondaryMaterial: DamascusDragonPlasma, secondaryMaterialName: 'Damascus Dragon Plasma', secondaryMaterialNumber: 1, combined: true},
];

const mechanicsCrossbowsData = [
    {level: 41, item: 'Steel Crossbow', experience: 15, image: SteelBar, secondaryImage: PineLogs, thirdImage: SteelCrossbow, material: SteelBar, materialName: 'Steel Bar', materialNumber: 2, secondaryMaterial: PineLogs, secondaryMaterialName: 'Pine Log', secondaryMaterialNumber: 1, combined: true},
    {level: 44, item: 'Psychic Crossbow', experience: 16, image: PsychicBar, secondaryImage: WillowLogs, thirdImage: PsychicCrossbow, material: PsychicBar, materialName: 'Psywood Bar', materialNumber: 2, secondaryMaterial: WillowLogs, secondaryMaterialName: 'Willow Log', secondaryMaterialNumber: 1, combined: true},
    {level: 49, item: 'E. Copper Crossbow', experience: 18, image: ECopperBar, secondaryImage: PsywoodLogs, thirdImage: ECopperCrossBow, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 2, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Log', secondaryMaterialNumber: 1, combined: true},
    {level: 53, item: 'E. Gold Crossbow', experience: 19, image: EGoldBar, secondaryImage: WeerwoodLogs, thirdImage: EGoldCrossbow, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 2, secondaryMaterial: WeerwoodLogs, secondaryMaterialName: 'Weerwood Log', secondaryMaterialNumber: 1, combined: true},
    {level: 57, item: 'Damascus Crossbow', experience: 20, image: DamascusBar, secondaryImage: BaobabLogs, thirdImage: DamascusCrossbow, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 2, secondaryMaterial: BaobabLogs, secondaryMaterialName: 'Baobab Log', secondaryMaterialNumber: 1, combined: true},

];

const mechanicsPistolsData = [
    {level: 61, item: 'Steel Pistol', experience: 22, image: SteelBar, secondaryImage: PsywoodLogs, thirdImage: SteelPistol, material: SteelBar, materialName: 'Steel Bar', materialNumber: 4, secondaryMaterial: PineLogs, secondaryMaterialName: 'Pine Log', secondaryMaterialNumber: 2, combined: true},
    {level: 65, item: 'Psychic Pistol', experience: 23, image: PsychicBar, secondaryImage: AcaciaLogs, thirdImage: PsychicPistol, material: PsychicBar, materialName: 'Psywood Bar', materialNumber: 4, secondaryMaterial: AcaciaLogs, secondaryMaterialName: 'Acacia Log', secondaryMaterialNumber: 2, combined: true},
    {level: 69, item: 'E. Copper Pistol', experience: 24, image: ECopperBar, secondaryImage: WeerwoodLogs, thirdImage: ECopperPistol, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 4, secondaryMaterial: WeerwoodLogs, secondaryMaterialName: 'Weerwood Log', secondaryMaterialNumber: 2, combined: true},
    {level: 73, item: 'E. Gold Pistol', experience: 25, image: EGoldBar, secondaryImage: RedwoodLogs, thirdImage: EGoldPistol, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 4, secondaryMaterial: RedwoodLogs, secondaryMaterialName: 'Redwood Log', secondaryMaterialNumber: 2, combined: true},
    {level: 77, item: 'Damascus Pistol', experience: 27, image: DamascusBar, secondaryImage: BaobabLogs, thirdImage: DamascusPistol, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 4, secondaryMaterial: BaobabLogs, secondaryMaterialName: 'Baobab Log', secondaryMaterialNumber: 2, combined: true},

];

const mechanicsRiflesData = [
    {level: 81, item: 'Steel Rifle', experience: 28, image: SteelBar, secondaryImage: PsywoodLogs, thirdImage: SteelRifle, material: SteelBar, materialName: 'Steel Bar', materialNumber: 8, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Log', secondaryMaterialNumber: 4, combined: true},
    {level: 85, item: 'Psychic Rifle', experience: 29, image: PsychicBar, secondaryImage: AcaciaLogs, thirdImage: PsychicRifle, material: PsychicBar, materialName: 'Psywood Bar', materialNumber: 8, secondaryMaterial: AcaciaLogs, secondaryMaterialName: 'Acacia Log', secondaryMaterialNumber: 4, combined: true},
    {level: 89, item: 'E. Copper Rifle', experience: 30, image: ECopperBar, secondaryImage: WeerwoodLogs, thirdImage: ECopperRile, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 8, secondaryMaterial: WeerwoodLogs, secondaryMaterialName: 'Weerwood Log', secondaryMaterialNumber: 4, combined: true},
    {level: 95, item: 'E. Gold Rifle', experience: 60, image: EGoldBar, secondaryImage: RedwoodLogs, thirdImage: EGoldRifle, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 8, secondaryMaterial: RedwoodLogs, secondaryMaterialName: 'Redwood Log', secondaryMaterialNumber: 4, combined: true},
    {level: 99, item: 'Damascus Rifle', experience: 75, image: DamascusBar, secondaryImage: BaobabLogs, thirdImage: DamascusRifle, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 8, secondaryMaterial: BaobabLogs, secondaryMaterialName: 'Baobab Log', secondaryMaterialNumber: 4, combined: true},
];

const mechanicsTeleportsData = [
    {level: 15, item: 'Three River Fort Teleport Stone', experience: 10, image: LesserActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: ThreeRiverFortTeleport, material: LesserActuator, materialName: 'Lesser Actuator', materialNumber: 1, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 1, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 25, item: 'Smuggler\'s Den Teleport Stone', experience: 14, image: LesserActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: SmugglersTeleport, material: LesserActuator, materialName: 'Lesser Actuator', materialNumber: 2, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 1, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 35, item: 'Split City Teleport Stone', experience: 16, image: LesserActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: SplitCityTeleport, material: LesserActuator, materialName: 'Lesser Actuator', materialNumber: 2, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 2, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 45, item: 'Mt. Greeff Teleport Stone', experience: 18, image: LesserActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: MtGreeffTeleport, material: LesserActuator, materialName: 'Lesser Actuator', materialNumber: 2, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 2, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 55, item: 'Dominia Teleport Stone', experience: 20, image: GreaterActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: DominiaTeleport, material: GreaterActuator, materialName: 'Greater Actuator', materialNumber: 1, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 3, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 65, item: 'Rogue\'s Rest Teleport Stone', experience: 25, image: GreaterActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: RoguesRestTeleport, material: GreaterActuator, materialName: 'Greater Actuator', materialNumber: 2, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 4, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 75, item: 'Burning Burden Teleport Stone', experience: 28, image: GreaterActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: BurningBurdenTeleport, material: GreaterActuator, materialName: 'Greater Actuator', materialNumber: 3, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 4, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
    {level: 85, item: 'Icewyntale Teleport Stone', experience: 30, image: GreaterActuator, secondaryImage: TeleportingActuator, thirdImage: EnchantedIce, fourthImage: WeerwoodLogs, fifthImage: IcewyntaleTeleport, material: GreaterActuator, materialName: 'Greater Actuator', materialNumber: 5, secondaryMaterial: TeleportingActuator, secondaryMaterialName: 'Teleport Actuator', secondaryMaterialNumber: 5, thirdMaterial: EnchantedIce, thirdMaterialName: 'Enchanted Ice', thirdMaterialNumber: 1, fourthMaterial: WeerwoodLogs, fourthMaterialName: 'Weerwood Log', fourthMaterialNumber: 1, combined: true},
];

export default class Mechanics extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {currentExperience: '',
            desiredLevel: 0,
            experienceNeeded: 0,
            mechanicsStavesData: mechanicsStavesData,
            mechanicsActuatorsData: mechanicsActuatorsData,
            mechanicsCrossbowsData: mechanicsCrossbowsData,
            mechanicsPistolsData: mechanicsPistolsData,
            mechanicsRiflesData: mechanicsRiflesData,
            mechanicsTeleportsData: mechanicsTeleportsData
        }
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.mechanicsStavesData, this.state.mechanicsActuatorsData, this.state.mechanicsTeleportsData, this.state.mechanicsCrossbowsData, this.state.mechanicsPistolsData, this.state.mechanicsRiflesData, level)
    }

    handleExperienceChange(event, mechanicsStavesData, mechanicsActuatorsData, mechanicsTeleportsData, mechanicsCrossbowsData, mechanicsPistolsData, mechanicsRiflesData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        mechanicsStavesData = calculateNumberNeeded(mechanicsStavesData, experienceNeeded, currentLevel);
        this.setState({mechanicsStavesData: mechanicsStavesData});
        mechanicsStavesData = calculateMaterialsNeeded(mechanicsStavesData, currentLevel);
        this.setState({mechanicsStavesData: mechanicsStavesData});

        mechanicsActuatorsData = calculateNumberNeeded(mechanicsActuatorsData, experienceNeeded, currentLevel);
        this.setState({mechanicsActuatorsData: mechanicsActuatorsData});
        mechanicsActuatorsData = calculateMaterialsNeeded(mechanicsActuatorsData, currentLevel);
        this.setState({mechanicsActuatorsData: mechanicsActuatorsData});

        mechanicsCrossbowsData = calculateNumberNeeded(mechanicsCrossbowsData, experienceNeeded, currentLevel);
        this.setState({mechanicsCrossbowsData: mechanicsCrossbowsData});
        mechanicsCrossbowsData = calculateMaterialsNeeded(mechanicsCrossbowsData, currentLevel);
        this.setState({mechanicsCrossbowsData: mechanicsCrossbowsData});

        mechanicsPistolsData = calculateNumberNeeded(mechanicsPistolsData, experienceNeeded, currentLevel);
        this.setState({mechanicsPistolsData: mechanicsPistolsData});
        mechanicsPistolsData = calculateMaterialsNeeded(mechanicsPistolsData, currentLevel);
        this.setState({mechanicsPistolsData: mechanicsPistolsData});

        mechanicsRiflesData = calculateNumberNeeded(mechanicsRiflesData, experienceNeeded, currentLevel);
        this.setState({mechanicsRiflesData: mechanicsRiflesData});
        mechanicsRiflesData = calculateMaterialsNeeded(mechanicsRiflesData, currentLevel);
        this.setState({mechanicsRiflesData: mechanicsRiflesData});

        mechanicsTeleportsData = calculateNumberNeeded(mechanicsTeleportsData, experienceNeeded, currentLevel);
        this.setState({mechanicsTeleportsData: mechanicsTeleportsData});
        mechanicsTeleportsData = calculateMaterialsNeeded(mechanicsTeleportsData, currentLevel);
        this.setState({mechanicsTeleportsData: mechanicsTeleportsData});
    }

    handleDesiredLevelChange(event, mechanicsStavesData, mechanicsActuatorsData, mechanicsTeleportsData, mechanicsCrossbowsData, mechanicsPistolsData, mechanicsRiflesData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        let experienceNeeded = experienceGoal - this.state.currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel = findCurrentLevel(this.state.currentExperience, levels);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        mechanicsStavesData = calculateNumberNeeded(mechanicsStavesData, experienceNeeded, currentLevel);
        this.setState({mechanicsStavesData: mechanicsStavesData});
        mechanicsStavesData = calculateMaterialsNeeded(mechanicsStavesData, currentLevel);
        this.setState({mechanicsStavesData: mechanicsStavesData});

        mechanicsActuatorsData = calculateNumberNeeded(mechanicsActuatorsData, experienceNeeded, currentLevel);
        this.setState({mechanicsActuatorsData: mechanicsActuatorsData});
        mechanicsActuatorsData = calculateMaterialsNeeded(mechanicsActuatorsData, currentLevel);
        this.setState({mechanicsActuatorsData: mechanicsActuatorsData});

        mechanicsCrossbowsData = calculateNumberNeeded(mechanicsCrossbowsData, experienceNeeded, currentLevel);
        this.setState({mechanicsCrossbowsData: mechanicsCrossbowsData});
        mechanicsCrossbowsData = calculateMaterialsNeeded(mechanicsCrossbowsData, currentLevel);
        this.setState({mechanicsCrossbowsData: mechanicsCrossbowsData});

        mechanicsPistolsData = calculateNumberNeeded(mechanicsPistolsData, experienceNeeded, currentLevel);
        this.setState({mechanicsPistolsData: mechanicsPistolsData});
        mechanicsPistolsData = calculateMaterialsNeeded(mechanicsPistolsData, currentLevel);
        this.setState({mechanicsPistolsData: mechanicsPistolsData});

        mechanicsRiflesData = calculateNumberNeeded(mechanicsRiflesData, experienceNeeded, currentLevel);
        this.setState({mechanicsRiflesData: mechanicsRiflesData});
        mechanicsRiflesData = calculateMaterialsNeeded(mechanicsRiflesData, currentLevel);
        this.setState({mechanicsRiflesData: mechanicsRiflesData});

        mechanicsTeleportsData = calculateNumberNeeded(mechanicsTeleportsData, experienceNeeded, currentLevel);
        this.setState({mechanicsTeleportsData: mechanicsTeleportsData});
        mechanicsTeleportsData = calculateMaterialsNeeded(mechanicsTeleportsData, currentLevel);
        this.setState({mechanicsTeleportsData: mechanicsTeleportsData});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={MechanicsIcon} className="header-left"></Image>
                        <Header.Content>Mechanics
                            <Header.Subheader>Artisan</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.mechanicsStavesData, this.state.mechanicsActuatorsData, this.state.mechanicsTeleportsData, this.state.mechanicsCrossbowsData, this.state.mechanicsPistolsData, this.state.mechanicsRiflesData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.mechanicsStavesData, this.state.mechanicsActuatorsData, this.state.mechanicsTeleportsData, this.state.mechanicsCrossbowsData, this.state.mechanicsPistolsData, this.state.mechanicsRiflesData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={StaffIcon} size="large"/>
                                <span className="divider-spacer">Staves</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow'compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.mechanicsStavesData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={RuneIcon} size="large"/>
                                <span className="divider-spacer">Actuators</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.mechanicsActuatorsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={TeleportIcon} size="large"/>
                                <span className="divider-spacer">Teleports</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.mechanicsTeleportsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={CrossbowIcon} size="large"/>
                                <span className="divider-spacer">Crossbows</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.mechanicsCrossbowsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={PistolIcon} size="large"/>
                                <span className="divider-spacer">Pistols</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.mechanicsPistolsData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={RifleIcon} size="large"/>
                                <span className="divider-spacer">Rifles</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.mechanicsRiflesData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
        )
    }
}