import React from 'react';
import {
  Dropdown,
  Form,
  Header,
  Image,
  Segment,
  Grid,
  Divider,
  Icon,
} from 'semantic-ui-react';
import BagIcon from '../../images/icons/Bag Icon.svg';
import ScrollIcon from '../../images/icons/Scroll Icon.svg';
import GearIcon from '../../images/icons/Gear Icon.svg';
import TopNav from './TopNav';

const ItemData = require('../../data/Items.json');

export default class Items extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedItem: undefined,
      shouldShowCombatAttributes: true,
    };
  }

  handleItemChange = (event, { value }) => {
    const item = ItemData.filter((it) => it.itemName === value)[0];

    // Attributes
    const alcValue = item.alcValue;
    const desValue = item.desValue;
    const stackable = item.stackable;
    const charges = item.charges;
    const levelReq = item.levelReq;
    const skillLevelReq = item.skillLevelReq;
    const extraLevelReq = item.extraLevelReq;
    const burnRate = item.burnRate;
    const exp = item.exp;

    // Combat Attributes
    const range = item.range;
    const rDefMod = item.rDefMod;
    const mDefMod = item.mDefMod;
    const pDefMod = item.pDefMod;
    const rAccMod = item.rAccMod;
    const mAccMod = item.mAccMod;
    const pAccMod = item.pAccMod;
    const lowDam = item.lowDam;
    const highDam = item.highDam;
    const atkSpeed = item.atkSpeed;
    const healAmount = item.healAmount;

    const shouldShowCombatAttributes = ![
      range,
      rDefMod,
      mDefMod,
      pDefMod,
      rAccMod,
      mAccMod,
      pAccMod,
      lowDam,
      highDam,
      atkSpeed,
    ].every((it) => it === 0);
    const shouldShowBurnRate = burnRate !== 0;
    const shouldShowLevelReq = levelReq !== 0;
    const shouldShowSkillLevelReq = skillLevelReq !== 0;
    const shouldShowExtraLevelReq = extraLevelReq !== 0;
    const shouldShowExperience = exp !== 0;
    const shouldShowHealAmount = healAmount !== 0;

    this.setState({ selectedItem: value });
    this.setState({ shouldShowCombatAttributes: shouldShowCombatAttributes });
    this.setState({ shouldShowBurnRate: shouldShowBurnRate });
    this.setState({ shouldShowLevelReq: shouldShowLevelReq });
    this.setState({ shouldShowSkillLevelReq: shouldShowSkillLevelReq });
    this.setState({ shouldShowExtraLevelReq: shouldShowExtraLevelReq });
    this.setState({ shouldShowExperience: shouldShowExperience });
    this.setState({ shouldShowHealAmount: shouldShowHealAmount });
  };

  render() {
    return (
      <div>
        <TopNav></TopNav>

        <Segment clearing>
          <Header as='h1' dividing floated='left'>
            <Image src={BagIcon} className='header-left'></Image>
            <Header.Content>
              Items
              <Header.Subheader>Items</Header.Subheader>
            </Header.Content>
          </Header>

          <Header as='h1' dividing floated='right'>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Items</label>
                  <Dropdown
                    className='item-dropdown'
                    placeholder='Items'
                    clearable
                    fluid
                    search
                    openOnFocus
                    selection
                    scrolling
                    options={ItemData.map((it) => ({
                      key: it.itemName,
                      text: it.itemName,
                      value: it.itemName,
                      image:
                        require(`../../images/Items - Large/${it.itemName}.png`)
                          .default,
                    }))}
                    onChange={this.handleItemChange}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Header>
        </Segment>

        {this.state.selectedItem && (
          <div>
            <Segment raised color='yellow' className='table-margin'>
              <Header as='h1'>
                <Image
                  src={
                    require(`../../images/Items - Large/${this.state.selectedItem}.png`)
                      .default
                  }
                ></Image>
                <Header.Content>
                  {this.state.selectedItem}

                  <Header.Subheader>
                    <a
                      href={`https://damascus.fandom.com/wiki/${this.state.selectedItem}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Icon name='external alternate' />
                      Wiki
                    </a>
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            <Segment raised color='yellow' className='table-margin'>
              {this.state.shouldShowCombatAttributes ? (
                <Grid columns={2} divided>
                  <Grid.Row>
                    <Grid.Column>
                      <Divider horizontal>
                        <Header as='h2'>
                          <Image src={ScrollIcon} size='large'></Image>
                          <span className='divider-spacer'>Attributes</span>
                        </Header>
                      </Divider>

                      <Form>
                        {this.state.shouldShowExperience && (
                          <Form.Field>
                            <label>Experience</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['exp']
                            }
                          </Form.Field>
                        )}

                        {this.state.shouldShowLevelReq && (
                          <Form.Field>
                            <label>Level Requirement</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['levelReq']
                            }
                          </Form.Field>
                        )}

                        {this.state.shouldShowSkillLevelReq && (
                          <Form.Field>
                            <label>Skill Level Requirement</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['skillLevelReq']
                            }
                          </Form.Field>
                        )}

                        {this.state.shouldShowExtraLevelReq && (
                          <Form.Field>
                            <label>Extra Level Requirement</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['extraLevelReq']
                            }
                          </Form.Field>
                        )}

                        <Form.Field>
                          <label>Alch Value</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['alcValue']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Destruction Value</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['desValue']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Stackable</label>
                          {ItemData.filter(
                            (it) => it.itemName === this.state.selectedItem
                          )[0]['stackable'] === true ? (
                            <Icon name='check' color='green' />
                          ) : (
                            <Icon name='times' color='red' />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>Charges</label>
                          {ItemData.filter(
                            (it) => it.itemName === this.state.selectedItem
                          )[0]['charges'] === true ? (
                            <Icon name='check' color='green' />
                          ) : (
                            <Icon name='times' color='red' />
                          )}
                        </Form.Field>

                        {this.state.shouldShowBurnRate && (
                          <Form.Field>
                            <label>Burn Rate</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['burnRate']
                            }{' '}
                            ticks
                          </Form.Field>
                        )}

                        {this.state.shouldShowHealAmount && (
                          <Form.Field>
                            <label>Heal Amount</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['healAmount']
                            }
                          </Form.Field>
                        )}
                      </Form>
                    </Grid.Column>

                    <Grid.Column>
                      <Divider horizontal>
                        <Header as='h2'>
                          <Image src={GearIcon} size='large'></Image>
                          <span className='divider-spacer'>
                            Combat Attributes
                          </span>
                        </Header>
                      </Divider>

                      <Form>
                        <Form.Field>
                          <label>Damage</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0][', ']
                          }{' '}
                          -{' '}
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['highDam']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Attack Speed</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['atkSpeed']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Melee Accuracy</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['mAccMod']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Ranging Accuracy</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['rAccMod']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Psychic Accuracy</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['pAccMod']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Melee Defence</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['mDefMod']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Ranging Defence</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['rDefMod']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Psychic Defence</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['pDefMod']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Range</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['range']
                          }
                        </Form.Field>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <Grid columns={1} centered>
                  <Grid.Row>
                    <Grid.Column>
                      <Divider horizontal>
                        <Header as='h2'>
                          <Image src={ScrollIcon} size='large'></Image>
                          <span className='divider-spacer'>Attributes</span>
                        </Header>
                      </Divider>

                      <Form>
                        {this.state.shouldShowExperience && (
                          <Form.Field>
                            <label>Experience</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['exp']
                            }
                          </Form.Field>
                        )}

                        {this.state.shouldShowLevelReq && (
                          <Form.Field>
                            <label>Level Requirement</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['levelReq']
                            }
                          </Form.Field>
                        )}

                        {this.state.shouldShowSkillLevelReq && (
                          <Form.Field>
                            <label>Skill Level Requirement</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['skillLevelReq']
                            }
                          </Form.Field>
                        )}

                        {this.state.shouldShowExtraLevelReq && (
                          <Form.Field>
                            <label>Extra Level Requirement</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['extraLevelReq']
                            }
                          </Form.Field>
                        )}

                        <Form.Field>
                          <label>Alch Value</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['alcValue']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Destruction Value</label>
                          {
                            ItemData.filter(
                              (it) => it.itemName === this.state.selectedItem
                            )[0]['desValue']
                          }
                        </Form.Field>
                        <Form.Field>
                          <label>Stackable</label>
                          {ItemData.filter(
                            (it) => it.itemName === this.state.selectedItem
                          )[0]['stackable'] === true ? (
                            <Icon name='check' color='green' />
                          ) : (
                            <Icon name='times' color='red' />
                          )}
                        </Form.Field>
                        <Form.Field>
                          <label>Charges</label>
                          {ItemData.filter(
                            (it) => it.itemName === this.state.selectedItem
                          )[0]['charges'] === true ? (
                            <Icon name='check' color='green' />
                          ) : (
                            <Icon name='times' color='red' />
                          )}
                        </Form.Field>

                        {this.state.shouldShowBurnRate && (
                          <Form.Field>
                            <label>Burn Rate</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['burnRate']
                            }{' '}
                            ticks
                          </Form.Field>
                        )}

                        {this.state.shouldShowHealAmount && (
                          <Form.Field>
                            <label>Heal Amount</label>
                            {
                              ItemData.filter(
                                (it) => it.itemName === this.state.selectedItem
                              )[0]['healAmount']
                            }
                          </Form.Field>
                        )}
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Segment>
          </div>
        )}
      </div>
    );
  }
}
