import L, { CRS } from 'leaflet';
import React from 'react';
import {
  ImageOverlay,
  LayerGroup,
  LayersControl,
  Map,
  Marker,
  Popup,
  Tooltip,
} from 'react-leaflet';
import Search from 'react-leaflet-search';
import TopNav from './TopNav';

const { Overlay } = LayersControl;

const catson = L.icon({
  iconUrl: require('../../images/Enemy/Catson.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const bandit = L.icon({
  iconUrl: require('../../images/Enemy/Bandit.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const blueDragon = L.icon({
  iconUrl: require('../../images/Enemy/Blue Dragon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const owlmanWarrior = L.icon({
  iconUrl: require('../../images/Enemy/Owlman Warrior.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const dwarfGuard = L.icon({
  iconUrl: require('../../images/Enemy/Dwarf Guard.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const windSlime = L.icon({
  iconUrl: require('../../images/Enemy/Wind Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserFireSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Fire Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const waterElemental = L.icon({
  iconUrl: require('../../images/Enemy/Water Elemental.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greenDragon = L.icon({
  iconUrl: require('../../images/Enemy/Green Dragon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const tigermanFighter = L.icon({
  iconUrl: require('../../images/Enemy/Tigerman Fighter.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const dwarf = L.icon({
  iconUrl: require('../../images/Enemy/Dwarf.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const cactuson = L.icon({
  iconUrl: require('../../images/Enemy/Cactuson.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const cactusonKing = L.icon({
  iconUrl: require('../../images/Enemy/Cactuson King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const blackDragon = L.icon({
  iconUrl: require('../../images/Enemy/Black Dragon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const catsonKing = L.icon({
  iconUrl: require('../../images/Enemy/Catson King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const giantSkeletonWarrior = L.icon({
  iconUrl: require('../../images/Enemy/Giant Skeleton.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const earthElemental = L.icon({
  iconUrl: require('../../images/Enemy/Earth Elemental.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const wolfman = L.icon({
  iconUrl: require('../../images/Enemy/Wolfman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const catsonArcher = L.icon({
  iconUrl: require('../../images/Enemy/Catson Archer.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const catsonGuard = L.icon({
  iconUrl: require('../../images/Enemy/Catson Guard.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orcWarrior = L.icon({
  iconUrl: require('../../images/Enemy/Orc Warrior.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orcShaman = L.icon({
  iconUrl: require('../../images/Enemy/Orc Shaman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orcKing = L.icon({
  iconUrl: require('../../images/Enemy/Orc King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orc = L.icon({
  iconUrl: require('../../images/Enemy/Orc.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const windElemental = L.icon({
  iconUrl: require('../../images/Enemy/Wind Elemental.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const psychicOrc = L.icon({
  iconUrl: require('../../images/Enemy/Psychic Orc.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orcArcher = L.icon({
  iconUrl: require('../../images/Enemy/Orc Archer.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const yellowDragon = L.icon({
  iconUrl: require('../../images/Enemy/Yellow Dragon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const earthSlime = L.icon({
  iconUrl: require('../../images/Enemy/Earth Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const boneSnake = L.icon({
  iconUrl: require('../../images/Enemy/Bone Snake.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const highwayman = L.icon({
  iconUrl: require('../../images/Enemy/Highwayman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const chucken = L.icon({
  iconUrl: require('../../images/Enemy/Chucken.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const giantBear = L.icon({
  iconUrl: require('../../images/Enemy/Giant Bear.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const caveGoblinTrapper = L.icon({
  iconUrl: require('../../images/Enemy/Cave Goblin Trapper.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fairyKnight = L.icon({
  iconUrl: require('../../images/Enemy/Fairy Knight.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const tigermanArcher = L.icon({
  iconUrl: require('../../images/Enemy/Tigerman Archer.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frostGiant = L.icon({
  iconUrl: require('../../images/Enemy/Frost Giant.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const tigermanKing = L.icon({
  iconUrl: require('../../images/Enemy/Tigerman King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const tigerman = L.icon({
  iconUrl: require('../../images/Enemy/Tigerman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const redDragon = L.icon({
  iconUrl: require('../../images/Enemy/Red Dragon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fairyKing = L.icon({
  iconUrl: require('../../images/Enemy/Fairy King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fairyRider = L.icon({
  iconUrl: require('../../images/Enemy/Fairy Rider.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const owlmanKing = L.icon({
  iconUrl: require('../../images/Enemy/Owlman King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const owlmanSage = L.icon({
  iconUrl: require('../../images/Enemy/Owlman Sage.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const owlmanArcher = L.icon({
  iconUrl: require('../../images/Enemy/Owlman Archer.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const owlman = L.icon({
  iconUrl: require('../../images/Enemy/Owlman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserWindSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Wind Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const caveGoblin = L.icon({
  iconUrl: require('../../images/Enemy/Cave Goblin.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserLightningSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Lightning Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const damascusDragon = L.icon({
  iconUrl: require('../../images/Enemy/Damascus Dragon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goblinKing = L.icon({
  iconUrl: require('../../images/Enemy/Goblin King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goblinChampion = L.icon({
  iconUrl: require('../../images/Enemy/Goblin Champion.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greaterLightningSlime = L.icon({
  iconUrl: require('../../images/Enemy/Greater Lightning Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fireSnake = L.icon({
  iconUrl: require('../../images/Enemy/Fire Snake.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fireElemental = L.icon({
  iconUrl: require('../../images/Enemy/Fire Elemental.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fireGiant = L.icon({
  iconUrl: require('../../images/Enemy/Fire Giant.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fireDemon = L.icon({
  iconUrl: require('../../images/Enemy/Fire Demon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const tortoise = L.icon({
  iconUrl: require('../../images/Enemy/Tortoise.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const floweringCactuson = L.icon({
  iconUrl: require('../../images/Enemy/Flowering Cactuson.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const dehydratedCactuson = L.icon({
  iconUrl: require('../../images/Enemy/Dehydrated Cactuson.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const ratmanBerserker = L.icon({
  iconUrl: require('../../images/Enemy/Ratman Berserker.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const clayGolem = L.icon({
  iconUrl: require('../../images/Enemy/Clay Golem.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greaterWindSliome = L.icon({
  iconUrl: require('../../images/Enemy/Greater Wind Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fairyQueen = L.icon({
  iconUrl: require('../../images/Enemy/Fairy Queen.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goatman = L.icon({
  iconUrl: require('../../images/Enemy/Goatman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const smallBoneSnake = L.icon({
  iconUrl: require('../../images/Enemy/Small Bone Snake.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserDemon = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Demon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fairy = L.icon({
  iconUrl: require('../../images/Enemy/Fairy.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fairyWarrior = L.icon({
  iconUrl: require('../../images/Enemy/Fairy Warrior.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greaterEarthSlime = L.icon({
  iconUrl: require('../../images/Enemy/Greater Earth Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orcBerserker = L.icon({
  iconUrl: require('../../images/Enemy/Orc Berserker.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const orcWarChief = L.icon({
  iconUrl: require('../../images/Enemy/Orc War Chief.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const sandDemon = L.icon({
  iconUrl: require('../../images/Enemy/Sand Demon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const enragedCactuson = L.icon({
  iconUrl: require('../../images/Enemy/Enraged Cactuson.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greaterFireSlime = L.icon({
  iconUrl: require('../../images/Enemy/Greater Fire Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const rat = L.icon({
  iconUrl: require('../../images/Enemy/Rat.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frogmanAmbassador = L.icon({
  iconUrl: require('../../images/Enemy/Frogman Ambassador.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greaterWaterSlime = L.icon({
  iconUrl: require('../../images/Enemy/Greater Water Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goblinBerserker = L.icon({
  iconUrl: require('../../images/Enemy/Goblin Berserker.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goblinWarrior = L.icon({
  iconUrl: require('../../images/Enemy/Goblin Warrior.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const guard = L.icon({
  iconUrl: require('../../images/Enemy/Guard.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const man = L.icon({
  iconUrl: require('../../images/Enemy/Man.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const woman = L.icon({
  iconUrl: require('../../images/Enemy/Woman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const greaterSlime = L.icon({
  iconUrl: require('../../images/Enemy/Greater Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const giantSpider = L.icon({
  iconUrl: require('../../images/Enemy/Giant Spider.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goblin = L.icon({
  iconUrl: require('../../images/Enemy/Goblin.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const slime = L.icon({
  iconUrl: require('../../images/Enemy/Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserWaterSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Water Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const swampDemon = L.icon({
  iconUrl: require('../../images/Enemy/Swamp Demon.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const waterSlime = L.icon({
  iconUrl: require('../../images/Enemy/Water Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const chicken = L.icon({
  iconUrl: require('../../images/Enemy/Chicken.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const ratman = L.icon({
  iconUrl: require('../../images/Enemy/Ratman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const ratmanGuard = L.icon({
  iconUrl: require('../../images/Enemy/Ratman Guard.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const ratmanKing = L.icon({
  iconUrl: require('../../images/Enemy/Ratman King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lightningSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lightning Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const zombie = L.icon({
  iconUrl: require('../../images/Enemy/Zombie.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frogman = L.icon({
  iconUrl: require('../../images/Enemy/Frogman.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frogmanKing = L.icon({
  iconUrl: require('../../images/Enemy/Frogman King.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frogmanWarrior = L.icon({
  iconUrl: require('../../images/Enemy/Frogman Warrior.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const stormGiant = L.icon({
  iconUrl: require('../../images/Enemy/Storm Giant.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const fireSlime = L.icon({
  iconUrl: require('../../images/Enemy/Fire Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const lesserEarthSlime = L.icon({
  iconUrl: require('../../images/Enemy/Lesser Earth Slime.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const goblinSwashbuckler = L.icon({
  iconUrl: require('../../images/Enemy/Goblin Swashbuckler.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frogmanMage = L.icon({
  iconUrl: require('../../images/Enemy/Frogman Mage.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const frogmanArcher = L.icon({
  iconUrl: require('../../images/Enemy/Frogman Archer.png').default,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  tooltipAnchor: [0, -40],
  popupAnchor: [0, -40],
});

const bounds = [
  [2394, 0],
  [0, 3848],
];

const enemies = [
  {
    latitude: 595,
    longitude: 2435,
    name: 'Blue Dragon',
    icon: blueDragon,
    info: 'Level: 105, HP: 80',
  },
  {
    latitude: 816,
    longitude: 2405,
    name: 'Owlman Warrior',
    icon: owlmanWarrior,
    info: 'Level: 115, HP: 115',
  },
  {
    latitude: 927,
    longitude: 2600,
    name: 'Dwarf Guard',
    icon: dwarfGuard,
    info: 'Level: 27, HP: 25',
  },
  {
    latitude: 962,
    longitude: 2423,
    name: 'Wind Slime',
    icon: windSlime,
    info: 'Level: 18, HP: 25',
  },
  {
    latitude: 913,
    longitude: 2250,
    name: 'Lesser Fire Slime',
    icon: lesserFireSlime,
    info: 'Level: 9, HP: 15',
  },
  {
    latitude: 671,
    longitude: 2283,
    name: 'Water Elemental',
    icon: waterElemental,
    info: 'Level: 75, HP: 65',
  },
  {
    latitude: 777,
    longitude: 2238,
    name: 'Green Dragon',
    icon: greenDragon,
    info: 'Level: 86, HP: 80',
  },
  {
    latitude: 809,
    longitude: 2364,
    name: 'Tigerman Fighter',
    icon: tigermanFighter,
    info: 'Level: 91, HP: 80',
  },
  {
    latitude: 2179,
    longitude: 1688,
    name: 'Dwarf',
    icon: dwarf,
    info: 'Level: 21, HP: 20',
  },
  {
    latitude: 2166,
    longitude: 1650,
    name: 'Cactuson',
    icon: cactuson,
    info: 'Level: 42, HP: 45',
  },
  {
    latitude: 2141,
    longitude: 1604,
    name: 'Cactuson King',
    icon: cactusonKing,
    info: 'Level: 65, HP: 65',
  },
  {
    latitude: 2006,
    longitude: 1365,
    name: 'Black Dragon',
    icon: blackDragon,
    info: 'Level: 115, HP: 80',
  },
  {
    latitude: 2071,
    longitude: 1480,
    name: 'Fire Giant',
    icon: fireGiant,
    info: 'Level: 135, HP: 210',
  },
  {
    latitude: 1102,
    longitude: 3074,
    name: 'Catson',
    icon: catson,
    info: 'Level: 73, HP: 60',
  },
  {
    latitude: 1197,
    longitude: 3213,
    name: 'Catson King',
    icon: catsonKing,
    info: 'Level: 80, HP: 80',
  },
  {
    latitude: 1217,
    longitude: 2906,
    name: 'Giant Skeleton Warrior',
    icon: giantSkeletonWarrior,
    info: 'Level: 183, HP: 180',
  },
  {
    latitude: 1231,
    longitude: 2732,
    name: 'Earth Elemental',
    icon: earthElemental,
    info: 'Level: 73, HP: 65',
  },
  {
    latitude: 1013,
    longitude: 3081,
    name: 'Wolfman',
    icon: wolfman,
    info: 'Level: 57, HP: 75',
  },
  {
    latitude: 1146,
    longitude: 3046,
    name: 'Catson Archer',
    icon: catsonArcher,
    info: 'Level: 76, HP: 70',
  },
  {
    latitude: 1128,
    longitude: 3137,
    name: 'Catson Guard',
    icon: catsonGuard,
    info: 'Level: 77, HP: 70',
  },
  {
    latitude: 1809,
    longitude: 1426,
    name: 'Orc Warrior',
    icon: orcWarrior,
    info: 'Level: 131, HP: 262',
  },
  {
    latitude: 1742,
    longitude: 1439,
    name: 'Orc Shaman',
    icon: orcShaman,
    info: 'Level: 125, HP: 90',
  },
  {
    latitude: 1788,
    longitude: 1451,
    name: 'Orc King',
    icon: orcKing,
    info: 'Level: 146, HP: 150',
  },
  {
    latitude: 1705,
    longitude: 1379,
    name: 'Orc',
    icon: orc,
    info: 'Level: 125, HP: 125',
  },
  {
    latitude: 1803,
    longitude: 829,
    name: 'Wind Elemental',
    icon: windElemental,
    info: 'Level: 72, HP: 55',
  },
  {
    latitude: 1754,
    longitude: 575,
    name: 'Psychic Orc',
    icon: psychicOrc,
    info: 'Level: 128, HP: 90',
  },
  {
    latitude: 1684,
    longitude: 1424,
    name: 'Orc Archer',
    icon: orcArcher,
    info: 'Level: 128, HP: 135',
  },
  {
    latitude: 1638,
    longitude: 774,
    name: 'Yellow Dragon',
    icon: yellowDragon,
    info: 'Level: 122, HP: 80',
  },
  {
    latitude: 1718,
    longitude: 989,
    name: 'Earth Slime',
    icon: earthSlime,
    info: 'Level: 17, HP: 25',
  },
  {
    latitude: 1315,
    longitude: 761,
    name: 'Bone Snake',
    icon: boneSnake,
    info: 'Level: 115, HP: ??',
  },
  {
    latitude: 1423,
    longitude: 1180,
    name: 'Highwayman',
    icon: highwayman,
    info: 'Level: 31, HP: 22',
  },
  {
    latitude: 1497,
    longitude: 1163,
    name: 'Chucken',
    icon: chucken,
    info: 'Level: 1, HP: 2',
  },
  {
    latitude: 1540,
    longitude: 890,
    name: 'Giant Bear',
    icon: giantBear,
    info: 'Level: 53, HP: 110',
  },
  {
    latitude: 1138,
    longitude: 529,
    name: 'Cave Goblin Trapper',
    icon: caveGoblinTrapper,
    info: 'Level: 14, HP: 14',
  },
  {
    latitude: 986,
    longitude: 525,
    name: 'Fairy Knight',
    icon: fairyKnight,
    info: 'Level: 76, HP: 70',
  },
  {
    latitude: 1029,
    longitude: 474,
    name: 'Tigerman Archer',
    icon: tigermanArcher,
    info: 'Level: 92, HP: 80',
  },
  {
    latitude: 185,
    longitude: 3431,
    name: 'Frost Giant',
    icon: frostGiant,
    info: 'Level: 135, HP: 210',
  },
  {
    latitude: 162,
    longitude: 3201,
    name: 'Tigerman King',
    icon: tigermanKing,
    info: 'Level: 98, HP: 80',
  },
  {
    latitude: 189,
    longitude: 3188,
    name: 'Tigerman',
    icon: tigerman,
    info: 'Level: 62, HP: 55',
  },
  {
    latitude: 366,
    longitude: 3493,
    name: 'Red Dragon',
    icon: redDragon,
    info: 'Level: 95, HP: 80',
  },
  {
    latitude: 250,
    longitude: 3182,
    name: 'Fairy King',
    icon: fairyKing,
    info: 'Level: 65, HP: 60',
  },
  {
    latitude: 198,
    longitude: 3278,
    name: 'Tigerman Fighter',
    icon: tigermanFighter,
    info: 'Level: 91, HP: 80',
  },
  {
    latitude: 203,
    longitude: 3373,
    name: 'Tigerman Archer',
    icon: tigermanArcher,
    info: 'Level: 92, HP: 80',
  },
  {
    latitude: 268,
    longitude: 3197,
    name: 'Fairy Rider',
    icon: fairyRider,
    info: 'Level: 82, HP: 75',
  },
  {
    latitude: 481,
    longitude: 3565,
    name: 'Yellow Dragon',
    icon: yellowDragon,
    info: 'Level: 122, HP: 80',
  },
  {
    latitude: 1597,
    longitude: 1837,
    name: 'Owlman King',
    icon: owlmanKing,
    info: 'Level: 125, HP: 120',
  },
  {
    latitude: 1692,
    longitude: 1830,
    name: 'Owlman Sage',
    icon: owlmanSage,
    info: 'Level: 106, HP: 110',
  },
  {
    latitude: 1719,
    longitude: 1841,
    name: 'Owlman Archer',
    icon: owlmanArcher,
    info: 'Level: 111, HP: 111',
  },
  {
    latitude: 1724,
    longitude: 1819,
    name: 'Owlman',
    icon: owlman,
    info: 'Level: 110, HP: 110',
  },
  {
    latitude: 1505,
    longitude: 1744,
    name: 'Lesser Wind Slime',
    icon: lesserWindSlime,
    info: 'Level: 11, HP: 20',
  },
  {
    latitude: 1475,
    longitude: 1820,
    name: 'Cave Goblin',
    icon: caveGoblin,
    info: 'Level: 12, HP: 12',
  },
  {
    latitude: 1565,
    longitude: 1563,
    name: 'Lesser Lightning Slime',
    icon: lesserLightningSlime,
    info: 'Level: 12, HP: 20',
  },
  {
    latitude: 672,
    longitude: 2197,
    name: 'Damascus Dragon',
    icon: damascusDragon,
    info: 'Level: 287, HP: 300',
  },
  {
    latitude: 729,
    longitude: 2062,
    name: 'Goblin King',
    icon: goblinKing,
    info: 'Level: 9, HP: 15',
  },
  {
    latitude: 783,
    longitude: 2036,
    name: 'Goblin Champion',
    icon: goblinChampion,
    info: 'Level: 8, HP: 8',
  },
  {
    latitude: 985,
    longitude: 984,
    name: 'Greater Lightning Slime',
    icon: greaterLightningSlime,
    info: 'Level: 30, HP: 39',
  },
  {
    latitude: 780,
    longitude: 735,
    name: 'Fire Snake',
    icon: fireSnake,
    info: 'Level: 126, HP: 210',
  },
  {
    latitude: 880,
    longitude: 840,
    name: 'Fire Elemental',
    icon: fireElemental,
    info: 'Level: 76, HP: 65',
  },
  {
    latitude: 814,
    longitude: 811,
    name: 'Fire Demon',
    icon: fireDemon,
    info: 'Level: 188, HP: 376',
  },
  {
    latitude: 865,
    longitude: 1330,
    name: 'Catson Archer',
    icon: catsonArcher,
    info: 'Level: 76, HP: 70',
  },
  {
    latitude: 873,
    longitude: 846,
    name: 'Tortoise',
    icon: tortoise,
    info: 'Level: 5, HP: 5',
  },
  {
    latitude: 847,
    longitude: 738,
    name: 'Flowering Cactuson',
    icon: floweringCactuson,
    info: 'Level: 45, HP: 45',
  },
  {
    latitude: 845,
    longitude: 764,
    name: 'Dehydrated Cactuson',
    icon: dehydratedCactuson,
    info: 'Level: 38, HP: 40',
  },
  {
    latitude: 1053,
    longitude: 1363,
    name: 'Ratman Berserker',
    icon: ratmanBerserker,
    info: 'Level: 83, HP: 110',
  },
  {
    latitude: 2062,
    longitude: 1017,
    name: 'Clay Golem',
    icon: clayGolem,
    info: 'Level: 135, HP: 150',
  },
  {
    latitude: 2056,
    longitude: 1173,
    name: 'Giant Bear',
    icon: giantBear,
    info: 'Level: 53, HP: 110',
  },
  {
    latitude: 2085,
    longitude: 1247,
    name: 'Greater Wind Slime',
    icon: greaterWindSliome,
    info: 'Level: 27, HP: 38',
  },
  {
    latitude: 731,
    longitude: 3183,
    name: 'Fairy Queen',
    icon: fairyQueen,
    info: 'Level: 65, HP: 60',
  },
  {
    latitude: 564,
    longitude: 3414,
    name: 'Goatman',
    icon: goatman,
    info: 'Level: 135, HP: 110',
  },
  {
    latitude: 809,
    longitude: 2831,
    name: 'Orc Archer',
    icon: orcArcher,
    info: 'Level: 128, HP: 135',
  },
  {
    latitude: 874,
    longitude: 2840,
    name: 'Small Bone Snake',
    icon: smallBoneSnake,
    info: 'Level: 97, HP: 125',
  },
  {
    latitude: 551,
    longitude: 3059,
    name: 'Lesser Demon',
    icon: lesserDemon,
    info: 'Level: 61, HP: 85',
  },
  {
    latitude: 684,
    longitude: 2906,
    name: 'Fairy',
    icon: fairy,
    info: 'Level: 64, HP: 64',
  },
  {
    latitude: 765,
    longitude: 3191,
    name: 'Fairy Warrior',
    icon: fairyWarrior,
    info: 'Level: 72, HP: 65',
  },
  {
    latitude: 779,
    longitude: 3221,
    name: 'Greater Earth Slime',
    icon: greaterEarthSlime,
    info: 'Level: 28, HP: 38',
  },
  {
    latitude: 553,
    longitude: 3088,
    name: 'Fairy Rider',
    icon: fairyRider,
    info: 'Level: 82, HP: 75',
  },
  {
    latitude: 758,
    longitude: 2815,
    name: 'Orc Berserker',
    icon: orcBerserker,
    info: 'Level: 148, HP: 150',
  },
  {
    latitude: 610,
    longitude: 2792,
    name: 'Orc War Chief',
    icon: orcWarChief,
    info: 'Level: 131, HP: 262',
  },
  {
    latitude: 733,
    longitude: 3055,
    name: 'Catson Archer',
    icon: catsonArcher,
    info: 'Level: 76, HP: 70',
  },
  {
    latitude: 669,
    longitude: 3081,
    name: 'Catson Guard',
    icon: catsonGuard,
    info: 'Level: 77, HP: 70',
  },
  {
    latitude: 2055,
    longitude: 2431,
    name: 'Sand Demon',
    icon: sandDemon,
    info: 'Level: 193, HP: 386',
  },
  {
    latitude: 2026,
    longitude: 2157,
    name: 'Enraged Cactuson',
    icon: enragedCactuson,
    info: 'Level: 51, HP: 55',
  },
  {
    latitude: 2080,
    longitude: 2107,
    name: 'Tortoise',
    icon: tortoise,
    info: 'Level: 5, HP: 5',
  },
  {
    latitude: 1985,
    longitude: 1816,
    name: 'Greater Fire Slime',
    icon: greaterFireSlime,
    info: 'Level: 26, HP: 38',
  },
  {
    latitude: 2166,
    longitude: 2115,
    name: 'Flowering Cactuson',
    icon: floweringCactuson,
    info: 'Level: 45, HP: 45',
  },
  {
    latitude: 1974,
    longitude: 1947,
    name: 'Dehydrated Cactuson',
    icon: dehydratedCactuson,
    info: 'Level: 38, HP: 40',
  },
  {
    latitude: 2167,
    longitude: 2140,
    name: 'Rat',
    icon: rat,
    info: 'Level: 1, HP: 3',
  },
  {
    latitude: 2146,
    longitude: 2101,
    name: 'Frogman Ambassador',
    icon: frogmanAmbassador,
    info: 'Level: 18, HP: 30',
  },
  {
    latitude: 1240,
    longitude: 2301,
    name: 'Greater Water Slime',
    icon: greaterWaterSlime,
    info: 'Level: 27, HP: 37',
  },
  {
    latitude: 1317,
    longitude: 2055,
    name: 'Goblin Berserker',
    icon: goblinBerserker,
    info: 'Level: 7, HP: 10',
  },
  {
    latitude: 1149,
    longitude: 2054,
    name: 'Goblin Warrior',
    icon: goblinWarrior,
    info: 'Level: 5, HP: 5',
  },
  {
    latitude: 1292,
    longitude: 1732,
    name: 'Guard',
    icon: guard,
    info: 'Level: 15, HP: 15',
  },
  {
    latitude: 1305,
    longitude: 1769,
    name: 'Man',
    icon: man,
    info: 'Level: 3, HP: 10',
  },
  {
    latitude: 1308,
    longitude: 1748,
    name: 'Woman',
    icon: woman,
    info: 'Level: 3, HP: 10',
  },
  {
    latitude: 1238,
    longitude: 1345,
    name: 'Greater Slime',
    icon: greaterSlime,
    info: 'Level: 11, HP: 12',
  },
  {
    latitude: 1340,
    longitude: 1365,
    name: 'Giant Spider',
    icon: giantSpider,
    info: 'Level: 14, HP: 22',
  },
  {
    latitude: 1340,
    longitude: 1861,
    name: 'Goblin',
    icon: goblin,
    info: 'Level: 2, HP: 5',
  },
  {
    latitude: 1393,
    longitude: 1806,
    name: 'Lesser Slime',
    icon: lesserSlime,
    info: 'Level: 1, HP: 2',
  },
  {
    latitude: 1393,
    longitude: 1837,
    name: 'Slime',
    icon: slime,
    info: 'Level: 4, HP: 4',
  },
  {
    latitude: 1191,
    longitude: 2136,
    name: 'Lesser Water Slime',
    icon: lesserWaterSlime,
    info: 'Level: 8, HP: 15',
  },
  {
    latitude: 1386,
    longitude: 2340,
    name: 'Swamp Demon',
    icon: swampDemon,
    info: 'Level: 188, HP: 213',
  },
  {
    latitude: 1207,
    longitude: 2258,
    name: 'Water Slime',
    icon: waterSlime,
    info: 'Level: 15, HP: 25',
  },
  {
    latitude: 1296,
    longitude: 1892,
    name: 'Chicken',
    icon: chicken,
    info: 'Level: 1, HP: 1',
  },
  {
    latitude: 1203,
    longitude: 1178,
    name: 'Bandit',
    icon: bandit,
    info: 'Level: 27, HP: 35',
  },
  {
    latitude: 1305,
    longitude: 1637,
    name: 'Ratman',
    icon: ratman,
    info: 'Level: 5, HP: 12',
  },
  {
    latitude: 1296,
    longitude: 1590,
    name: 'Ratman Guard',
    icon: ratmanGuard,
    info: 'Level: 15, HP: 15',
  },
  {
    latitude: 1280,
    longitude: 1657,
    name: 'Ratman King',
    icon: ratmanKing,
    info: 'Level: 25, HP: 30',
  },
  {
    latitude: 1320,
    longitude: 1921,
    name: 'Rat',
    icon: rat,
    info: 'Level: 1, HP: 3',
  },
  {
    latitude: 1696,
    longitude: 2628,
    name: 'Lightning Slime',
    icon: lightningSlime,
    info: 'Level: 20, HP: 35',
  },
  {
    latitude: 1623,
    longitude: 2421,
    name: 'Zombie',
    icon: zombie,
    info: 'Level: 21, HP: 27',
  },
  {
    latitude: 1694,
    longitude: 2307,
    name: 'Frogman',
    icon: frogman,
    info: 'Level: 25, HP: 25',
  },
  {
    latitude: 1663,
    longitude: 2347,
    name: 'Frogman King',
    icon: frogmanKing,
    info: 'Level: 28, HP: 45',
  },
  {
    latitude: 1619,
    longitude: 2313,
    name: 'Frogman Warrior',
    icon: frogmanWarrior,
    info: 'Level: 26, HP: 25',
  },
  {
    latitude: 1675,
    longitude: 2340,
    name: 'Frogman Ambassador',
    icon: frogmanAmbassador,
    info: 'Level: 18, HP: 30',
  },
  {
    latitude: 1785,
    longitude: 2588,
    name: 'Storm Giant',
    icon: stormGiant,
    info: 'Level: 135, HP: 210',
  },
  {
    latitude: 1810,
    longitude: 2082,
    name: 'Fire Slime',
    icon: fireSlime,
    info: 'Level: 16, HP: 25',
  },
  {
    latitude: 1765,
    longitude: 2086,
    name: 'Lesser Earth Slime',
    icon: lesserEarthSlime,
    info: 'Level: 10, HP: 15',
  },
  {
    latitude: 1777,
    longitude: 2281,
    name: 'Goblin Swashbuckler',
    icon: goblinSwashbuckler,
    info: 'Level: 13, HP: 7',
  },
  {
    latitude: 1641,
    longitude: 2165,
    name: 'Frogman Mage',
    icon: frogmanMage,
    info: 'Level: 28, HP: 25',
  },
  {
    latitude: 1573,
    longitude: 2161,
    name: 'Frogman Archer',
    icon: frogmanArcher,
    info: 'Level: 32, HP: 25',
  },
];

const customProvider = {
  search: async (inputValue) => {
    let info = [];
    for (let item in enemies) {
      if (enemies[item].name.toLowerCase().includes(inputValue.toLowerCase())) {
        info.push(enemies[item]);
      }
    }

    return {
      info: info,
    };
  },
};

export default class WorldMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    console.log(e.latlng);
  }

  render() {
    const image = require('../../images/World Map.png').default;

    return (
      <div>
        <TopNav />
        <Map
          className='map'
          minZoom={-1}
          maxZoom={1.5}
          center={[1300, 1700]}
          zoom={0}
          crs={CRS.Simple}
          attributionControl={false}
          maxBoundsViscosity={0.5}
          maxBounds={[
            [2394, 0],
            [0, 3848],
          ]}
          onClick={this.handleClick}
        >
          <ImageOverlay bounds={bounds} url={image} />
          <LayersControl position='topright'>
            <Overlay checked name='Enemies'>
              <LayerGroup>
                {enemies.map(function (item) {
                  return (
                    <Marker
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                        zoom: 13,
                      }}
                      icon={item.icon}
                      key={item.name + item.longitude + item.latitude}
                    >
                      <Tooltip>{item.name}</Tooltip>
                      <Popup>{item.info}</Popup>
                    </Marker>
                  );
                })}
                ;
              </LayerGroup>
            </Overlay>
          </LayersControl>

          <Search
            position='topright'
            inputPlaceholder='Search'
            customProvider={customProvider}
            zoom={1}
            showMarker={false}
            showPopup={false}
          />
        </Map>
      </div>
    );
  }
}
