import React from 'react';
import { Divider, Grid, Header, Image, Segment, Tab, Table, Card, Icon, CardGroup } from "semantic-ui-react";
import HelmIcon from "../../../images/icons/Helm Icon.svg";
import LightBulbIcon from "../../../images/icons/Light Bulb.svg";
import MiningIcon from "../../../images/icons/Mining Icon.png";
import { buildExperienceTableRows } from "../../../utility/Utility";
import TopNav from '../../other/TopNav';

const tableData = [
    { name: 'Copper Pickaxe', level: '1', location: 'Tony\'s General Store - Split City' },
    // {  name: 'Demonic Pickaxe', level: '1', location: 'Fire Demon' },
    // {  name: 'Bronze Pickaxe', level: '10', location: 'Axe Shop - Split City' },
    // {  name: 'Iron Pickaxe', level: '20', location: 'Axe Shop - Split City' },
    // {  name: 'Steel Pickaxe', level: '30', location: 'Axe Shop - Split City' },
    // {  name: 'Psywood Pickaxe', level: '40', location: 'Axe Shop - Split City' },
    // {  name: 'E. Copper Pickaxe', level: '50', location: 'Fire Snake / Orc War Chief' },
    // {  name: 'E. Gold Pickaxe', level: '60', location: 'Orc War Chief' },
    // {  name: 'Damascus Pickaxe', level: '70', location: 'Orc War Chief' },
  ];


  const panes = [
    { menuItem: 'Free to Play', render: () => <Tab.Pane>Tab 1 Content</Tab.Pane> },
    { menuItem: 'Member', render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
  ];

  const Tabs = () => (
    <Tab
      menu={{ secondary: true, pointing: true, className: 'reactive-tabs' }}
      panes={panes}
      renderActiveOnly={false}
    />
  );

export default class MiningGuide extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {}
    }
   
    
    render()
    {
        return (
            <div>
                <TopNav></TopNav>

                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={MiningIcon} className="header-left"></Image>
                        <Header.Content>Mining
                            <Header.Subheader>Gathering</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Segment>
                
                <Divider/>

                <Tabs/>

                <Grid columns={2} divided>
                    <Grid.Row className="table-margin" centered>
                        <Grid.Column>
                            <Divider horizontal>
                                <Header as='h2'>
                                    <Image src={HelmIcon} size="large"/>
                                    <span className="divider-spacer">Equipment</span>
                                </Header>
                            </Divider>

                            <span>The only equipment required to begin mining is a pickaxe. A mining level is required to wield it. Pickaxes cannot be crafted with Metalwork. They are bought from shops around the world or gained from enemy drops. You should use the best pickaxe available for the fastest mining speed. </span>
                            

                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Level Required</Table.HeaderCell>
                                    <Table.HeaderCell>Location</Table.HeaderCell>
                                </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildExperienceTableRows(tableData)}
                                </Table.Body>
                            </Table>

                        </Grid.Column>
                        <Grid.Column>
                            <Divider horizontal>
                            <Header as='h2'>
                            <Image src={LightBulbIcon} size="large"/>

                                <span className="divider-spacer">Tips and Tricks</span>
                            </Header>
                            </Divider>
                            <ul>
                                <li>Equipping your pickaxe gives you an extra slot for mining but can be within your inventory.</li>
                                <li>Stuff and things</li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid columns={1}>
                    <Grid.Row className="table-margin" centered>
                        <Grid.Column>
                            <Divider horizontal>
                            <Header as='h2'>
                                <span className="divider-spacer">Fastest Experience</span>
                            </Header>
                            </Divider>

                            <Card raised color='yellow' link fluid>
                                <Card.Content>
                                <Card.Header>Levels: 1-10</Card.Header>
                                <Card.Description>
                                    From level 1 to 10 you'll want to mine Copper Ore.
                                    Experience Needed: 123
                                    Experience Per Hours: 24K
                                </Card.Description>



                                <Table celled color='yellow' compact='very'>
                                    <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Level Required</Table.HeaderCell>
                                        <Table.HeaderCell>Location</Table.HeaderCell>
                                    </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildExperienceTableRows(tableData)}
                                    </Table.Body>
                                </Table>


                                </Card.Content>
                            </Card>





                        </Grid.Column>

                        <Grid.Column>
                            <Divider horizontal>
                            <Header as='h2'>
                                <span className="divider-spacer">Other Methods</span>
                            </Header>
                            </Divider>
                            <span>The only equipment required to begin mining is a pickaxe. A mining level is required to wield it. Pickaxes cannot be crafted with Metalwork. They are bought from shops around the world or gained from enemy drops. You should use the best pickaxe available for the fastest mining speed. </span>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}