import React from 'react';
import { Divider, Dropdown, Form, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import StatsIcon from '../../images/icons/Attribute Icon.svg';
import ChickenIcon from '../../images/icons/Chicken Icon.svg';
import TreasureIcon from '../../images/icons/Treasure Icon.svg';
import TrollIcon from '../../images/icons/Troll Icon.svg';
import TopNav from './TopNav';

const EnemyData = require('../../data/Enemy.json');
const ItemData = require('../../data/Items.json');

export default class Enemies extends React.Component
{
    constructor()
    {
        super();
        this.state = {}
    }

    handleEnemyChange = (event, { value }) =>
    {
        const enemy = EnemyData.filter(it => it.name === value)[0];

        // Attributes
        const melee = enemy.melee;
        const magic = enemy.magic;
        const range = enemy.range;
        const level = enemy.level;
        const exp = enemy.exp;
        const health = enemy.health;
        const defence = enemy.defence;
        const strength = enemy.strength;
        const ranging = enemy.ranging;
        const psychic = enemy.psychic;
        const accuracy = enemy.accuracy;
        const atkSpeed = enemy.atkSpeed;
        const atkRange = enemy.atkRange;
        const aggroRange = enemy.aggroRange;

        const type = melee ? 'Melee' : range ? 'Range' : magic ? 'Psychic' : null;

        this.setState({selectedEnemy: value});
        this.setState({type: type});


    }

    buildDrops() {
        const enemy = EnemyData.filter(it => it.name === this.state.selectedEnemy)[0];
        const drops = enemy.dropTable.dropItems;
        drops.sort(function(a, b){return b.dropChance - a.dropChance});
        let rows = [];

        for(const drop of drops) {
            const item = ItemData.filter(it => it.itemID === drop.itemId)[0];
            const quantity = drop.qtyMin === drop.qtyMax ? drop.qtyMin : drop.qtyMin + ' - ' + drop.qtyMax

            if(item !== undefined && item.itemName !== undefined) {
                rows.push(
                    <Header as='h3' dividing>
                        <Image src={require(`../../images/Items - Large/${item.itemName}.png`).default} className="header-left"></Image>
                        <Header.Content>{item.itemName} : {quantity}
                            <Header.Subheader>{drop.dropChance}%</Header.Subheader>
                        </Header.Content>
                    </Header>
                );
            } else if(drop.itemId === -1) {
                rows.push(
                    <Header as='h3' dividing >
                        <Image src={ChickenIcon} className="header-left"></Image>
                        <Header.Content>Aboslutely Nothing
                            <Header.Subheader>{drop.dropChance}%</Header.Subheader>
                        </Header.Content>
                    </Header>
                );
            }
        }

        rows.push(
            <Header as='h3' floated='right'>
                <Header.Content>Total Chance
                    <Header.Subheader>{enemy.dropTable.totalChance}%</Header.Subheader>
                </Header.Content>
            </Header>
        );

        return rows;

    }

    render()
    {
        return (
            <div> 
                <TopNav></TopNav>

                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={TrollIcon} className="header-left"></Image>
                        <Header.Content>Enemies
                            <Header.Subheader>Enemies</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                            <Form.Field>
                                <label>Enemies</label>
                                <Dropdown
                                    className='enemy-dropdown'
                                    placeholder='Enemies'
                                    clearable
                                    fluid
                                    search
                                    openOnFocus
                                    selection
                                    scrolling
                                    options={EnemyData.map(it => ({key: it.enemyID, text: it.name, value: it.name, image: require(`../../images/Enemy/${it.name}.png`).default}))}
                                    onChange={this.handleEnemyChange}
                                />
                            </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>

                {this.state.selectedEnemy && 

                <div>
                    <Segment raised color='yellow' className="table-margin">
                                        <Header as='h1'>
                                                <Image src={require(`../../images/Enemy - Large/${this.state.selectedEnemy}.png`).default}></Image>
                                                <Header.Content>{this.state.selectedEnemy}
                                                
                                                <Header.Subheader>{this.state.type} | 
                                                    <a href={`https://damascus.fandom.com/wiki/${this.state.selectedEnemy}`} target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>
                                                    </Header.Subheader>
                                                </Header.Content>
                                        </Header>
                                    </Segment>

                                    <Segment raised color="yellow" className="table-margin">
                                        <Grid columns={2} divided>
                                            <Grid.Row>
                                            <Grid.Column>
                                            <Divider horizontal>
                                                <Header as='h2'>
                                                    <Image src={StatsIcon} size="large"></Image>
                                                    <span className="divider-spacer">Stats</span>
                                                </Header>
                                                </Divider>


                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Level
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['level']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Experience
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['exp']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Health
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['health']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Defence
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['defence']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Strength
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['strength']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Ranging
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['ranging']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Psychic
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['psychic']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Accuracy
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['accuracy']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Attack Speed
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['atkSpeed']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' dividing >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Attack Range
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['atkRange']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as='h3' >
                                                <Image className="header-left"></Image>
                                                <Header.Content>Aggro Range
                                                    <Header.Subheader>{EnemyData.filter(it => it.name === this.state.selectedEnemy)[0]['aggroRange']}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            </Grid.Column>
                                            
                                                <Grid.Column>
                                                <Divider horizontal>
                                                            <Header as='h2'>
                                                                    <Image src={TreasureIcon} size="large"></Image>
                                                                <span className="divider-spacer">Drops</span>
                                                            </Header>
                                                        </Divider>
                                        
                                                        {this.buildDrops()}
                                                </Grid.Column>
                                            
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                    
                </div>
                }

                

            </div>
        )
    }
}