import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Image, Tab } from 'semantic-ui-react';
import Calculators from '../calculators/Calculators';
import Guard from '../../images/Enemy/Guard.png';
import Coins from '../../images/Items/Coins.png';
import CopperScimitar from '../../images/Items/Copper Scimitar.png';
import GuildworkContract from '../../images/Items/Guildwork Contract.png';
import Dude from '../../images/random/dude.png';
import Items from './Items';
import Map from './Map';

const panes = [
  {
    menuItem: {
      key: 'DamascusSkull',
      disabled: true,
      content: (
        <Image src={Dude} alt='Damascus Logo' width='75' wrapped ui={false} />
      ),
    },
    render: () => <Tab.Pane attached={false} />,
  },
  {
    menuItem: {
      key: 'Calculators',
      content: (
        <div>
          <Image src={Coins} alt='Calculators' avatar />
          <span>Calculators</span>
        </div>
      ),
      color: 'yellow',
      as: NavLink,
      to: '/calculators',
      exact: true,
    },
    render: () => (
      <Tab.Pane attached={false}>
        <Calculators />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      key: 'Items',
      content: (
        <div>
          <Image src={CopperScimitar} alt='Items' avatar />
          <span>Items</span>
        </div>
      ),
      color: 'yellow',
      as: NavLink,
      to: '/items',
      exact: true,
    },
    render: () => (
      <Tab.Pane attached={false}>
        <Items />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      key: 'Enemies',
      content: (
        <div>
          <Image src={Guard} alt='Enemies' avatar />
          <span>Enemies</span>
        </div>
      ),
      color: 'yellow',
      as: NavLink,
      to: '/enemies',
      exact: true,
    },
    render: () => (
      <Tab.Pane attached={false}>
        <Map />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      key: 'Map',
      content: (
        <div>
          <Image src={GuildworkContract} alt='Map' avatar />
          <span>Map</span>
        </div>
      ),
      color: 'yellow',
      as: NavLink,
      to: '/map',
      exact: true,
    },
    render: () => (
      <Tab.Pane attached={false}>
        <Map />
      </Tab.Pane>
    ),
  },
  // {
  //     menuItem: {
  //         key: 'Guides',
  //         content: <div><Image src={GuildworkContract} alt="Guides" avatar/><span>Guides</span></div>,
  //         color: 'yellow',
  //         as: NavLink,
  //         to: "/guides",
  //         exact: true
  //       },
  //       render: () => <Tab.Pane attached={false}><Guides/></Tab.Pane>
  //   },
  {
    menuItem: { key: 'logo', className: 'banner', disabled: true, content: '' },
    render: () => <Tab.Pane attached={false} />,
  },
  {
    menuItem: {
      key: 'Wiki',
      disabled: true,
      content: (
        <a
          href='https://damascus.fandom.com/wiki/Damascus_Wiki'
          target='_blank'
          rel='noopener noreferrer'
        >
          {' '}
          <Icon name='book' size='large' href='' />
          Wiki{' '}
        </a>
      ),
    },
    render: () => (
      <Tab.Pane attached={false}>
        <Map />
      </Tab.Pane>
    ),
  },
];

const Tabs = () => (
  <Tab
    menu={{ secondary: true, pointing: true, className: 'reactive-tabs' }}
    panes={panes}
    renderActiveOnly={false}
  />
);

class TopNav extends React.Component {
  render() {
    return (
      <div>
        <Tabs />
      </div>
    );
  }
}

export default TopNav;
