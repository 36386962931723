import React from 'react';
import { Divider, Dropdown, Form, Grid, Header, Image, Input, Segment } from "semantic-ui-react";
import BronzeBoots from '../../images/armor/Bronze/Bronze Boots.png';
import BronzeBuckler from '../../images/armor/Bronze/Bronze Buckler.png';
import BronzeChainbody from '../../images/armor/Bronze/Bronze Chainbody.png';
import BronzeGloves from '../../images/armor/Bronze/Bronze Gloves.png';
import BronzeHelmet from '../../images/armor/Bronze/Bronze Helmet.png';
import BronzeKiteshield from '../../images/armor/Bronze/Bronze Kiteshield.png';
import BronzePlatebody from '../../images/armor/Bronze/Bronze Platebody.png';
import BronzePlatelegs from '../../images/armor/Bronze/Bronze Platelegs.png';
import BronzePlateskirt from '../../images/armor/Bronze/Bronze Plateskirt.png';
import BootsOfTheBull from '../../images/armor/Bull/Boots of the Bull.png';
import HelmOfTheBull from '../../images/armor/Bull/Helm of the Bull.png';
import ShieldOfTheBull from '../../images/armor/Bull/Shield of the Bull.PNG';
import CopperBoots from '../../images/armor/Copper/Copper Boots.png';
import CopperBuckler from '../../images/armor/Copper/Copper Buckler.png';
import CopperChainbody from '../../images/armor/Copper/Copper Chainbody.png';
import CopperGloves from '../../images/armor/Copper/Copper Gloves.png';
import CopperHelmet from '../../images/armor/Copper/Copper Helmet.png';
import CopperKiteshield from '../../images/armor/Copper/Copper Kiteshield.png';
import CopperPlatebody from '../../images/armor/Copper/Copper Platebody.png';
import CopperPlatelegs from '../../images/armor/Copper/Copper Platelegs.png';
import CopperPlateskirt from '../../images/armor/Copper/Copper Plateskirt.png';
import DamascusBoots from '../../images/armor/Damascus/Damascus Boots.png';
import DamascusBuckler from '../../images/armor/Damascus/Damascus Buckler.png';
import DamascusChainbody from '../../images/armor/Damascus/Damascus Chainbody.png';
import DamascusGloves from '../../images/armor/Damascus/Damascus Gloves.png';
import DamascusHelmet from '../../images/armor/Damascus/Damascus Helmet.png';
import DamascusKiteshield from '../../images/armor/Damascus/Damascus Kiteshield.png';
import DamascusPlatebody from '../../images/armor/Damascus/Damascus Platebody.png';
import DamascusPlatelegs from '../../images/armor/Damascus/Damascus Platelegs.png';
import DamascusPlateskirt from '../../images/armor/Damascus/Damascus Plateskirt.png';
import ECopperBoots from '../../images/armor/E. Copper/E. Copper Boots.png';
import ECopperBuckler from '../../images/armor/E. Copper/E. Copper Buckler.png';
import ECopperChainbody from '../../images/armor/E. Copper/E. Copper Chainbody.png';
import ECopperGloves from '../../images/armor/E. Copper/E. Copper Gloves.png';
import ECopperHelmet from '../../images/armor/E. Copper/E. Copper Helmet.png';
import ECopperKiteshield from '../../images/armor/E. Copper/E. Copper Kiteshield.png';
import ECopperPlatebody from '../../images/armor/E. Copper/E. Copper Platebody.png';
import ECopperPlatelegs from '../../images/armor/E. Copper/E. Copper Platelegs.png';
import ECopperPlateskirt from '../../images/armor/E. Copper/E. Copper Plateskirt.png';
import EGoldBoots from '../../images/armor/E. Gold/E. Gold Boots.png';
import EGoldBuckler from '../../images/armor/E. Gold/E. Gold Buckler.png';
import EGoldChainbody from '../../images/armor/E. Gold/E. Gold Chainbody.png';
import EGoldGloves from '../../images/armor/E. Gold/E. Gold Gloves.png';
import EGoldHelmet from '../../images/armor/E. Gold/E. Gold Helmet.png';
import EGoldKiteshield from '../../images/armor/E. Gold/E. Gold Kiteshield.png';
import EGoldPlatebody from '../../images/armor/E. Gold/E. Gold Platebody.png';
import EGoldPlatelegs from '../../images/armor/E. Gold/E. Gold Platelegs.png';
import EGoldPlateskirt from '../../images/armor/E. Gold/E. Gold Plateskirt.png';
import IronBoots from '../../images/armor/Iron/Iron Boots.png';
import IronBuckler from '../../images/armor/Iron/Iron Buckler.png';
import IronChainbody from '../../images/armor/Iron/Iron Chainbody.png';
import IronGloves from '../../images/armor/Iron/Iron Gloves.png';
import IronHelmet from '../../images/armor/Iron/Iron Helmet.png';
import IronKiteshield from '../../images/armor/Iron/Iron Kiteshield.png';
import IronPlatebody from '../../images/armor/Iron/Iron Platebody.png';
import IronPlatelegs from '../../images/armor/Iron/Iron Platelegs.png';
import IronPlateskirt from '../../images/armor/Iron/Iron Plateskirt.png';
import PsywoodBoots from '../../images/armor/Psywood/Psywood Boots.png';
import PsywoodBuckler from '../../images/armor/Psywood/Psywood Buckler.png';
import PsywoodChainbody from '../../images/armor/Psywood/Psywood Chainbody.png';
import PsywoodGloves from '../../images/armor/Psywood/Psywood Gloves.png';
import PsywoodHelmet from '../../images/armor/Psywood/Psywood Helmet.png';
import PsywoodKiteshield from '../../images/armor/Psywood/Psywood Kiteshield.png';
import PsywoodPlatebody from '../../images/armor/Psywood/Psywood Platebody.png';
import PsywoodPlatelegs from '../../images/armor/Psywood/Psywood Platelegs.png';
import PsywoodPlateskirt from '../../images/armor/Psywood/Psywood Plateskirt.png';
import SteelBoots from '../../images/armor/Steel/Steel Boots.png';
import SteelBuckler from '../../images/armor/Steel/Steel Buckler.png';
import SteelChainbody from '../../images/armor/Steel/Steel Chainbody.png';
import SteelGloves from '../../images/armor/Steel/Steel Gloves.png';
import SteelHelmet from '../../images/armor/Steel/Steel Helmet.png';
import SteelKiteshield from '../../images/armor/Steel/Steel Kiteshield.png';
import SteelPlatebody from '../../images/armor/Steel/Steel Platebody.png';
import SteelPlatelegs from '../../images/armor/Steel/Steel Platelegs.png';
import SteelPlateskirt from '../../images/armor/Steel/Steel Plateskirt.png';
import CombatIcon from "../../images/icons/Combat Icon.png";
import MuscleIcon from "../../images/icons/Muscle Icon.svg";
import TentIcon from "../../images/icons/Tent Icon.svg";
import Bronze2hHammer from '../../images/weapons/Bronze/Bronze 2h Hammer.png';
import Bronze2hSword from '../../images/weapons/Bronze/Bronze 2h Sword.png';
import BronzeBattleAxe from '../../images/weapons/Bronze/Bronze Battle Axe.png';
import BronzeDagger from '../../images/weapons/Bronze/Bronze Dagger.png';
import BronzeGladius from '../../images/weapons/Bronze/Bronze Gladius.png';
import BronzeHalberd from '../../images/weapons/Bronze/Bronze Halbred.png';
import BronzeKatana from '../../images/weapons/Bronze/Bronze Katana.png';
import BronzeLongSword from '../../images/weapons/Bronze/Bronze Long Sword.png';
import BronzeMace from '../../images/weapons/Bronze/Bronze Mace.png';
import BronzeScimitar from '../../images/weapons/Bronze/Bronze Scimitar.png';
import BronzeSpear from '../../images/weapons/Bronze/Bronze Spear.png';
import BronzeWarhammer from '../../images/weapons/Bronze/Bronze Warhammer.png';
import Copper2hHammer from '../../images/weapons/Copper/Copper 2h Hammer.png';
import Copper2hSword from '../../images/weapons/Copper/Copper 2h Sword.png';
import CopperBattleAxe from '../../images/weapons/Copper/Copper Battle Axe.png';
import CopperDagger from '../../images/weapons/Copper/Copper Dagger.png';
import CopperGladius from '../../images/weapons/Copper/Copper Gladius.png';
import CopperHalberd from '../../images/weapons/Copper/Copper Halberd.png';
import CopperKatana from '../../images/weapons/Copper/Copper Katana.png';
import CopperLongSword from '../../images/weapons/Copper/Copper Long Sword.png';
import CopperMace from '../../images/weapons/Copper/Copper Mace.png';
import CopperScimitar from '../../images/weapons/Copper/Copper Scimitar.png';
import CopperShortSword from '../../images/weapons/Copper/Copper Short Sword.png';
import CopperSpear from '../../images/weapons/Copper/Copper Spear.png';
import CopperWarhammer from '../../images/weapons/Copper/Copper Warhammer.png';
import Damascus2hHammer from '../../images/weapons/Damascus/Damascus 2h Hammer.png';
import Damascus2hSword from '../../images/weapons/Damascus/Damascus 2h Sword.png';
import DamascusBattleAxe from '../../images/weapons/Damascus/Damascus Battle Axe.png';
import DamascusDagger from '../../images/weapons/Damascus/Damascus Dagger.png';
import DamascusGladius from '../../images/weapons/Damascus/Damascus Gladius.png';
import DamascusHalberd from '../../images/weapons/Damascus/Damascus Halbred.png';
import DamascusKatana from '../../images/weapons/Damascus/Damascus Katana.png';
import DamascusLongSword from '../../images/weapons/Damascus/Damascus Long Sword.png';
import DamascusMace from '../../images/weapons/Damascus/Damascus Mace.png';
import DamascusScimitar from '../../images/weapons/Damascus/Damascus Scimitar.png';
import DamascusSpear from '../../images/weapons/Damascus/Damascus Spear.png';
import DamascusWarhammer from '../../images/weapons/Damascus/Damascus Warhammer.png';
import ECopper2hHammer from '../../images/weapons/E. Copper/E. Copper 2h Hammer.png';
import ECopper2hSword from '../../images/weapons/E. Copper/E. Copper 2h Sword.png';
import ECopperBattleAxe from '../../images/weapons/E. Copper/E. Copper Battle Axe.png';
import ECopperDagger from '../../images/weapons/E. Copper/E. Copper Dagger.png';
import ECopperGladius from '../../images/weapons/E. Copper/E. Copper Gladius.png';
import ECopperHalberd from '../../images/weapons/E. Copper/E. Copper Halbred.png';
import ECopperKatana from '../../images/weapons/E. Copper/E. Copper Katana.png';
import ECopperLongSword from '../../images/weapons/E. Copper/E. Copper Long Sword.png';
import ECopperMace from '../../images/weapons/E. Copper/E. Copper Mace.png';
import ECopperScimitar from '../../images/weapons/E. Copper/E. Copper Scimitar.png';
import ECopperSpear from '../../images/weapons/E. Copper/E. Copper Spear.png';
import ECopperWarhammer from '../../images/weapons/E. Copper/E. Copper Warhammer.png';
import EGold2hHammer from '../../images/weapons/E. Gold/E. Gold 2h Hammer.png';
import EGold2hSword from '../../images/weapons/E. Gold/E. Gold 2h Sword.png';
import EGoldBattleAxe from '../../images/weapons/E. Gold/E. Gold Battle Axe.png';
import EGoldDagger from '../../images/weapons/E. Gold/E. Gold Dagger.png';
import EGoldGladius from '../../images/weapons/E. Gold/E. Gold Gladius.png';
import EGoldHalberd from '../../images/weapons/E. Gold/E. Gold Halbred.png';
import EGoldKatana from '../../images/weapons/E. Gold/E. Gold Katana.png';
import EGoldLongSword from '../../images/weapons/E. Gold/E. Gold Long Sword.png';
import EGoldMace from '../../images/weapons/E. Gold/E. Gold Mace.png';
import EGoldScimitar from '../../images/weapons/E. Gold/E. Gold Scimitar.png';
import EGoldSpear from '../../images/weapons/E. Gold/E. Gold Spear.png';
import EGoldWarhammer from '../../images/weapons/E. Gold/E. Gold Warhammer.png';
import Iron2hHammer from '../../images/weapons/Iron/Iron 2h Hammer.png';
import Iron2hSword from '../../images/weapons/Iron/Iron 2h Sword.png';
import IronBattleAxe from '../../images/weapons/Iron/Iron Battle Axe.png';
import IronDagger from '../../images/weapons/Iron/Iron Dagger.png';
import IronGladius from '../../images/weapons/Iron/Iron Gladius.png';
import IronHalberd from '../../images/weapons/Iron/Iron Halbred.png';
import IronKatana from '../../images/weapons/Iron/Iron Katana.png';
import IronLongSword from '../../images/weapons/Iron/Iron Long Sword.png';
import IronMace from '../../images/weapons/Iron/Iron Mace.png';
import IronScimitar from '../../images/weapons/Iron/Iron Scimitar.png';
import IronSpear from '../../images/weapons/Iron/Iron Spear.png';
import IronWarhammer from '../../images/weapons/Iron/Iron Warhammer.png';
import DemonSlayerKatana from '../../images/weapons/Misc/Demon Slayer Katana.PNG';
import Psywood2hHammer from '../../images/weapons/Psywood/Psywood 2h Hammer.png';
import Psywood2hSword from '../../images/weapons/Psywood/Psywood 2h Sword.png';
import PsywoodBattleAxe from '../../images/weapons/Psywood/Psywood Battle Axe.png';
import PsywoodDagger from '../../images/weapons/Psywood/Psywood Dagger.png';
import PsywoodGladius from '../../images/weapons/Psywood/Psywood Gladius.png';
import PsywoodHalberd from '../../images/weapons/Psywood/Psywood Halbred.png';
import PsywoodKatana from '../../images/weapons/Psywood/Psywood Katana.png';
import PsywoodLongSword from '../../images/weapons/Psywood/Psywood Long Sword.png';
import PsywoodMace from '../../images/weapons/Psywood/Psywood Mace.png';
import PsywoodScimitar from '../../images/weapons/Psywood/Psywood Scimitar.png';
import PsywoodSpear from '../../images/weapons/Psywood/Psywood Spear.png';
import PsywoodWarhammer from '../../images/weapons/Psywood/Psywood Warhammer.png';
import Steel2hHammer from '../../images/weapons/Steel/Steel 2h Hammer.png';
import Steel2hSword from '../../images/weapons/Steel/Steel 2h Sword.png';
import SteelBattleAxe from '../../images/weapons/Steel/Steel Battle Axe.png';
import SteelDagger from '../../images/weapons/Steel/Steel Dagger.png';
import SteelGladius from '../../images/weapons/Steel/Steel Gladius.png';
import SteelHalberd from '../../images/weapons/Steel/Steel Halbred.png';
import SteelKatana from '../../images/weapons/Steel/Steel Katana.png';
import SteelLongSword from '../../images/weapons/Steel/Steel Long Sword.png';
import SteelMace from '../../images/weapons/Steel/Steel Mace.png';
import SteelScimitar from '../../images/weapons/Steel/Steel Scimitar.png';
import SteelSpear from '../../images/weapons/Steel/Steel Spear.png';
import SteelWarhammer from '../../images/weapons/Steel/Steel Warhammer.png';
import TopNav from '../other/TopNav';


const weaponsData = [

    {item: 'Copper Dagger', image: CopperDagger, baseMinDamage: 0, baseMaxDamage: 1, attackSpeed: 2},
    {item: 'Bronze Dagger', image: BronzeDagger, baseMinDamage: 0, baseMaxDamage: 1, attackSpeed: 1.5},
    {item: 'Iron Dagger', image: IronDagger, baseMinDamage: 0, baseMaxDamage: 2, attackSpeed: 1.5},
    {item: 'Steel Dagger', image: SteelDagger, baseMinDamage: 0, baseMaxDamage: 3, attackSpeed: 1.5},
    {item: 'Psywood Dagger', image: PsywoodDagger, baseMinDamage: 0, baseMaxDamage: 5, attackSpeed: 1},
    {item: 'E. Copper Dagger', image: ECopperDagger, baseMinDamage: 0, baseMaxDamage: 6, attackSpeed: 1},
    {item: 'E. Gold Dagger', image: EGoldDagger, baseMinDamage: 0, baseMaxDamage: 7, attackSpeed: 1},
    {item: 'Damascus Dagger', image: DamascusDagger, baseMinDamage: 0, baseMaxDamage: 8, attackSpeed: 0.5},

    {item: 'Copper Short Sword', image: CopperScimitar, baseMinDamage: 2, baseMaxDamage: 3, attackSpeed: 3},
    {item: 'Bronze Short Sword', image: BronzeScimitar, baseMinDamage: 3, baseMaxDamage: 5, attackSpeed: 2.5},
    {item: 'Iron Short Sword', image: IronScimitar, baseMinDamage: 4, baseMaxDamage: 9, attackSpeed: 2.5},
    {item: 'Steel Short Sword', image: SteelScimitar, baseMinDamage: 5, baseMaxDamage: 11, attackSpeed: 2.5},
    {item: 'Psywood Short Sword', image: PsywoodScimitar, baseMinDamage: 10, baseMaxDamage: 16, attackSpeed: 2},
    {item: 'E. Copper Short Sword', image: ECopperScimitar, baseMinDamage: 17, baseMaxDamage: 21, attackSpeed: 2},
    {item: 'E. Gold Short Sword', image: EGoldScimitar, baseMinDamage: 20, baseMaxDamage: 24, attackSpeed: 2},
    {item: 'Damascus Short Sword', image: DamascusScimitar, baseMinDamage: 17, baseMaxDamage: 34, attackSpeed: 1.5},

    {item: 'Copper Long Sword', image: CopperLongSword, baseMinDamage: 0, baseMaxDamage: 4, attackSpeed: 3},
    {item: 'Bronze Long Sword', image: BronzeLongSword, baseMinDamage: 1, baseMaxDamage: 6, attackSpeed: 2.5},
    {item: 'Iron Long Sword', image: IronLongSword, baseMinDamage: 2, baseMaxDamage: 10, attackSpeed: 2.5},
    {item: 'Steel Long Sword', image: SteelLongSword, baseMinDamage: 3, baseMaxDamage: 12, attackSpeed: 2.5},
    {item: 'Psywood Long Sword', image: PsywoodLongSword, baseMinDamage: 8, baseMaxDamage: 17, attackSpeed: 2},
    {item: 'E. Copper Long Sword', image: ECopperLongSword, baseMinDamage: 15, baseMaxDamage: 22, attackSpeed: 2},
    {item: 'E. Gold Long Sword', image: EGoldLongSword, baseMinDamage: 18, baseMaxDamage: 25, attackSpeed: 2},
    {item: 'Damascus Long Sword', image: DamascusLongSword, baseMinDamage: 15, baseMaxDamage: 35, attackSpeed: 1.5},

    {item: 'Copper Katana', image: CopperKatana, baseMinDamage: 0, baseMaxDamage: 2, attackSpeed: 2.5},
    {item: 'Bronze Katana', image: BronzeKatana, baseMinDamage: 0, baseMaxDamage: 3, attackSpeed: 2},
    {item: 'Iron Katana', image: IronKatana, baseMinDamage: 1, baseMaxDamage: 5, attackSpeed: 2},
    {item: 'Steel Katana', image: SteelKatana, baseMinDamage: 2, baseMaxDamage: 8, attackSpeed: 2},
    {item: 'Psywood Katana', image: PsywoodKatana, baseMinDamage: 4, baseMaxDamage: 10, attackSpeed: 1.5},
    {item: 'E. Copper Katana', image: ECopperKatana, baseMinDamage: 8, baseMaxDamage: 13, attackSpeed: 1.5},
    {item: 'E. Gold Katana', image: EGoldKatana, baseMinDamage: 11, baseMaxDamage: 16, attackSpeed: 1.5},
    {item: 'Damascus Katana', image: DamascusKatana, baseMinDamage: 13, baseMaxDamage: 25, attackSpeed: 1},

    {item: 'Copper Battleaxe', image: CopperBattleAxe, baseMinDamage: 0, baseMaxDamage: 4, attackSpeed: 3.5},
    {item: 'Bronze Battleaxe', image: BronzeBattleAxe, baseMinDamage: 1, baseMaxDamage: 6, attackSpeed: 3},
    {item: 'Iron Battleaxe', image: IronBattleAxe, baseMinDamage: 2, baseMaxDamage: 10, attackSpeed: 3},
    {item: 'Steel Battleaxe', image: SteelBattleAxe, baseMinDamage: 3, baseMaxDamage: 12, attackSpeed: 3},
    {item: 'Psywood Battleaxe', image: PsywoodBattleAxe, baseMinDamage: 8, baseMaxDamage: 17, attackSpeed: 2.5},
    {item: 'E. Copper Battleaxe', image: ECopperBattleAxe, baseMinDamage: 15, baseMaxDamage: 22, attackSpeed: 2.5},
    {item: 'E. Gold Battleaxe', image: EGoldBattleAxe, baseMinDamage: 18, baseMaxDamage: 25, attackSpeed: 2.5},
    {item: 'Damascus Battleaxe', image: DamascusBattleAxe, baseMinDamage: 15, baseMaxDamage: 35, attackSpeed: 2},

    {item: 'Copper Warhammer', image: CopperWarhammer, baseMinDamage: 0, baseMaxDamage: 5, attackSpeed: 3.5},
    {item: 'Bronze Warhammer', image: BronzeWarhammer, baseMinDamage: 0, baseMaxDamage: 7, attackSpeed: 3},
    {item: 'Iron Warhammer', image: IronWarhammer, baseMinDamage: 1, baseMaxDamage: 11, attackSpeed: 3},
    {item: 'Steel Warhammer', image: SteelWarhammer, baseMinDamage: 2, baseMaxDamage: 13, attackSpeed: 3},
    {item: 'Psywood Warhammer', image: PsywoodWarhammer, baseMinDamage: 7, baseMaxDamage: 18, attackSpeed: 2.5},
    {item: 'E. Copper Warhammer', image: ECopperWarhammer, baseMinDamage: 14, baseMaxDamage: 23, attackSpeed: 2.5},
    {item: 'E. Gold Warhammer', image: EGoldWarhammer, baseMinDamage: 17, baseMaxDamage: 26, attackSpeed: 2.5},
    {item: 'Damascus Warhammer', image: DamascusWarhammer, baseMinDamage: 14, baseMaxDamage: 36, attackSpeed: 2},

    {item: 'Copper 2H Sword', image: Copper2hSword, baseMinDamage: 3, baseMaxDamage: 3, attackSpeed: 4},
    {item: 'Bronze 2H Sword', image: Bronze2hSword, baseMinDamage: 4, baseMaxDamage: 5, attackSpeed: 3.5},
    {item: 'Iron 2H Sword', image: Iron2hSword, baseMinDamage: 5, baseMaxDamage: 9, attackSpeed: 3.5},
    {item: 'Steel 2H Sword', image: Steel2hSword, baseMinDamage: 6, baseMaxDamage: 11, attackSpeed: 3.5},
    {item: 'Psywood 2H Sword', image: Psywood2hSword, baseMinDamage: 11, baseMaxDamage: 16, attackSpeed: 3},
    {item: 'E. Copper 2H Sword', image: ECopper2hSword, baseMinDamage: 18, baseMaxDamage: 21, attackSpeed: 3},
    {item: 'E. Gold 2H Sword', image: EGold2hSword, baseMinDamage: 21, baseMaxDamage: 24, attackSpeed: 3},
    {item: 'Damascus 2H Sword', image: Damascus2hSword, baseMinDamage: 18, baseMaxDamage: 34, attackSpeed: 2.5},

    {item: 'Copper Spear', image: CopperSpear, baseMinDamage: 2, baseMaxDamage: 5, attackSpeed: 4},
    {item: 'Bronze Spear', image: BronzeSpear, baseMinDamage: 3, baseMaxDamage: 7, attackSpeed: 3.5},
    {item: 'Iron Spear', image: IronSpear, baseMinDamage: 4, baseMaxDamage: 11, attackSpeed: 3.5},
    {item: 'Steel Spear', image: SteelSpear, baseMinDamage: 5, baseMaxDamage: 13, attackSpeed: 3.5},
    {item: 'Psywood Spear', image: PsywoodSpear, baseMinDamage: 22, baseMaxDamage: 32, attackSpeed: 3},
    {item: 'E. Copper Spear', image: ECopperSpear, baseMinDamage: 24, baseMaxDamage: 38, attackSpeed: 3},
    {item: 'E. Gold Spear', image: EGoldSpear, baseMinDamage: 26, baseMaxDamage: 48, attackSpeed: 3},
    {item: 'Damascus Spear', image: DamascusSpear, baseMinDamage: 48, baseMaxDamage: 72, attackSpeed: 2.5},

    {item: 'Copper Scimitar', image: CopperShortSword, baseMinDamage: 2, baseMaxDamage: 1, attackSpeed: 3},
    {item: 'Bronze Scimitar', image: BronzeScimitar, baseMinDamage: 3, baseMaxDamage: 3, attackSpeed: 2.5},
    {item: 'Iron Scimitar', image: IronScimitar, baseMinDamage: 4, baseMaxDamage: 7, attackSpeed: 2.5},
    {item: 'Steel Scimitar', image: SteelScimitar, baseMinDamage: 5, baseMaxDamage: 9, attackSpeed: 2.5},
    {item: 'Psywood Scimitar', image: PsywoodScimitar, baseMinDamage: 10, baseMaxDamage: 14, attackSpeed: 2},
    {item: 'E. Copper Scimitar', image: ECopperScimitar, baseMinDamage: 17, baseMaxDamage: 19, attackSpeed: 2},
    {item: 'E. Gold Scimitar', image: EGoldScimitar, baseMinDamage: 20, baseMaxDamage: 22, attackSpeed: 2},
    {item: 'Damascus Scimitar', image: DamascusScimitar, baseMinDamage: 17, baseMaxDamage: 32, attackSpeed: 1.5},

    {item: 'Copper Mace', image: CopperMace, baseMinDamage: 1, baseMaxDamage: 3, attackSpeed: 3.5},
    {item: 'Bronze Mace', image: BronzeMace, baseMinDamage: 2, baseMaxDamage: 5, attackSpeed: 3},
    {item: 'Iron Mace', image: IronMace, baseMinDamage: 3, baseMaxDamage: 9, attackSpeed: 3},
    {item: 'Steel Mace', image: SteelMace, baseMinDamage: 4, baseMaxDamage: 11, attackSpeed: 3},
    {item: 'Psywood Mace', image: PsywoodMace, baseMinDamage: 9, baseMaxDamage: 16, attackSpeed: 2.5},
    {item: 'E. Copper Mace', image: ECopperMace, baseMinDamage: 16, baseMaxDamage: 21, attackSpeed: 2.5},
    {item: 'E. Gold  Mace', image: EGoldMace, baseMinDamage: 19, baseMaxDamage: 24, attackSpeed: 2.5},
    {item: 'Damascus Mace', image: DamascusMace, baseMinDamage: 16, baseMaxDamage: 34, attackSpeed: 2},

    {item: 'Copper 2H Hammer', image: Copper2hHammer, baseMinDamage: 2, baseMaxDamage: 7, attackSpeed: 4},
    {item: 'Bronze 2H Hammer', image: Bronze2hHammer, baseMinDamage: 3, baseMaxDamage: 9, attackSpeed: 3.5},
    {item: 'Iron 2H Hammer', image: Iron2hHammer, baseMinDamage: 4, baseMaxDamage: 13, attackSpeed: 3.5},
    {item: 'Steel 2H Hammer', image: Steel2hHammer, baseMinDamage: 5, baseMaxDamage: 15, attackSpeed: 3.5},
    {item: 'Psywood 2H Hammer', image: Psywood2hHammer, baseMinDamage: 10, baseMaxDamage: 20, attackSpeed: 3},
    {item: 'E. Copper 2H Hammer', image: ECopper2hHammer, baseMinDamage: 17, baseMaxDamage: 25, attackSpeed: 3},
    {item: 'E. Gold 2H Hammer', image: EGold2hHammer, baseMinDamage: 20, baseMaxDamage: 28, attackSpeed: 3},
    {item: 'Damascus 2H Hammer', image: Damascus2hHammer, baseMinDamage: 17, baseMaxDamage: 38, attackSpeed: 2.5},

    {item: 'Copper Halberd', image: CopperHalberd, baseMinDamage: 0, baseMaxDamage: 6, attackSpeed: 4},
    {item: 'Bronze Halberd', image: BronzeHalberd, baseMinDamage: 1, baseMaxDamage: 8, attackSpeed: 3.5},
    {item: 'Iron Halberd', image: IronHalberd, baseMinDamage: 2, baseMaxDamage: 12, attackSpeed: 3.5},
    {item: 'Steel Halberd', image: SteelHalberd, baseMinDamage: 3, baseMaxDamage: 14, attackSpeed: 3.5},
    {item: 'Psywood Halberd', image: PsywoodHalberd, baseMinDamage: 8, baseMaxDamage: 19, attackSpeed: 3},
    {item: 'E. Copper Halberd', image: ECopperHalberd, baseMinDamage: 15, baseMaxDamage: 24, attackSpeed: 3},
    {item: 'E. Gold Halberd', image: EGoldHalberd, baseMinDamage: 18, baseMaxDamage: 27, attackSpeed: 3},
    {item: 'Damascus Halberd', image: DamascusHalberd, baseMinDamage: 15, baseMaxDamage: 37, attackSpeed: 2.5},

    {item: 'Copper Gladius', image: CopperGladius, baseMinDamage: 2, baseMaxDamage: 2, attackSpeed: 3},
    {item: 'Bronze Gladius', image: BronzeGladius, baseMinDamage: 3, baseMaxDamage: 4, attackSpeed: 2.5},
    {item: 'Iron Gladius', image: IronGladius, baseMinDamage: 4, baseMaxDamage: 8, attackSpeed: 2.5},
    {item: 'Steel Gladius', image: SteelGladius, baseMinDamage: 5, baseMaxDamage: 10, attackSpeed: 2.5},
    {item: 'Psywood Gladius', image: PsywoodGladius, baseMinDamage: 12, baseMaxDamage: 21, attackSpeed: 2},
    {item: 'E. Copper Gladius', image: ECopperGladius, baseMinDamage: 19, baseMaxDamage: 22, attackSpeed: 2},
    {item: 'E. Gold Gladius', image: EGoldGladius, baseMinDamage: 22, baseMaxDamage: 25, attackSpeed: 2},
    {item: 'Damascus Gladius', image: DamascusGladius, baseMinDamage: 28, baseMaxDamage: 48, attackSpeed: 1.5, meleeAccuracy: 2},

    {item: 'Demon Slayer Katana', image: DemonSlayerKatana, baseMinDamage: 22, baseMaxDamage: 34, attackSpeed: 1},

];

const offHandData = [
    {item: 'Copper Kiteshield', image: CopperKiteshield, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Copper Buckler', image: CopperBuckler, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: -1},
    {item: 'Bronze Kiteshield', image: BronzeKiteshield, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Bronze Buckler', image: BronzeBuckler, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: -1},
    {item: 'Iron Kiteshield', image: IronKiteshield, meleeDefence: 5, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Iron Buckler', image: IronBuckler, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: -2},
    {item: 'Steel Kiteshield', image: SteelKiteshield, meleeDefence: 6, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Steel Buckler', image: SteelBuckler, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: -2},
    {item: 'Psywood Kiteshield', image: PsywoodKiteshield, meleeDefence: 8, rangingDefence: 5, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -3, psychicAccuracy: -3},
    {item: 'Psywood Buckler', image: PsywoodBuckler, meleeDefence: 6, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: -3},
    {item: 'E. Copper Kiteshield', image: ECopperKiteshield, meleeDefence: 10, rangingDefence: 6, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Copper Buckler', image: ECopperBuckler, meleeDefence: 8, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: -4},
    {item: 'E. Gold Kiteshield', image: EGoldKiteshield, meleeDefence: 22, rangingDefence: 12, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Gold Buckler', image: EGoldBuckler, meleeDefence: 18, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: 1, psychicAccuracy: -4},
    {item: 'Damascus Kiteshield', image: DamascusKiteshield, meleeDefence: 38, rangingDefence: 20, psychicDefence: 0, meleeAccuracy: 4, rangingAccuracy: -5, psychicAccuracy: -5},
    {item: 'Damascus Buckler', image: DamascusBuckler, meleeDefence: 33, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: 2, psychicAccuracy: -5},
    {item: 'Shield of the Bull', image: ShieldOfTheBull, meleeDefence: 55, rangingDefence: 30, psychicDefence: 0, meleeAccuracy: 10, rangingAccuracy: -10, psychicAccuracy: -10},
];

const helmData = [
    {item: 'Copper Helmet', image: CopperHelmet, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Bronze Helmet', image: BronzeHelmet, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Iron Helmet', image: IronHelmet, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Steel Helmet', image: SteelHelmet, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Psywood Helmet', image: PsywoodHelmet, meleeDefence: 6, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'E. Copper Helmet', image: ECopperHelmet, meleeDefence: 8, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'E. Gold Helmet', image: EGoldHelmet, meleeDefence: 18, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Damascus Helmet', image: DamascusHelmet, meleeDefence: 33, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Helm of the Bull', image: HelmOfTheBull, meleeDefence: 45, rangingDefence: 18, psychicDefence: 35, meleeAccuracy: 8, rangingAccuracy: 0, psychicAccuracy: 8},
];

const chestData = [
    {item: 'Copper Chainbody', image: CopperPlatebody, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Copper Platebody', image: CopperChainbody, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Bronze Chainbody', image: BronzeChainbody, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Bronze Platebody', image: BronzePlatebody, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Iron Chainbody', image: IronChainbody, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Iron Platebody', image: IronPlatebody, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Steel Chainbody', image: SteelChainbody, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Steel Platebody', image: SteelPlatebody, meleeDefence: 6, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -3, psychicAccuracy: -3},
    {item: 'Psywood Chainbody', image: PsywoodChainbody, meleeDefence: 6, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -3, psychicAccuracy: -3},
    {item: 'Psywood Platebody', image: PsywoodPlatebody, meleeDefence: 7, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Copper Chainbody', image: ECopperChainbody, meleeDefence: 8, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Copper Platebody', image: ECopperPlatebody, meleeDefence: 9, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Gold Chainbody', image: EGoldChainbody, meleeDefence: 18, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Gold Platebody', image: EGoldPlatebody, meleeDefence: 26, rangingDefence: 14, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: -5, psychicAccuracy: -5},
    {item: 'Damascus Chainbody', image: DamascusChainbody, meleeDefence: 33, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: -5, psychicAccuracy: -5},
    {item: 'Damascus Platebody', image: DamascusPlatebody, meleeDefence: 45, rangingDefence: 26, psychicDefence: 0, meleeAccuracy: 4, rangingAccuracy: -6, psychicAccuracy: -6},
];

const legsData = [
    {item: 'Copper Platelegs', image: CopperPlatelegs, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Copper Plateskirt', image: CopperPlateskirt, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Bronze Platelegs', image: BronzePlatelegs, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Bronze Plateskirt', image: BronzePlateskirt, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -1, psychicAccuracy: -1},
    {item: 'Iron Platelegs', image: IronPlatelegs, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Iron Plateskirt', image: IronPlateskirt, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Steel Platelegs', image: SteelPlatelegs, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Steel Plateskirt', image: SteelPlateskirt, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -2, psychicAccuracy: -2},
    {item: 'Psywood Platelegs', image: PsywoodPlatelegs, meleeDefence: 6, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -3, psychicAccuracy: -3},
    {item: 'Psywood Plateskirt', image: PsywoodPlateskirt, meleeDefence: 6, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -3, psychicAccuracy: -3},
    {item: 'E. Copper Platelegs', image: ECopperPlatelegs, meleeDefence: 8, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Copper Plateskirt', image: ECopperPlateskirt, meleeDefence: 8, rangingDefence: 4, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Gold Platelegs', image: EGoldPlatelegs, meleeDefence: 18, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'E. Gold Plateskirt', image: EGoldPlateskirt, meleeDefence: 18, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: -4, psychicAccuracy: -4},
    {item: 'Damascus Platelegs', image: DamascusPlatelegs, meleeDefence: 33, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: -5, psychicAccuracy: -5},
    {item: 'Damascus Plateskirt', image: DamascusPlateskirt, meleeDefence: 33, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: -5, psychicAccuracy: -5},
];

const glovesData = [
    {item: 'Copper Gloves', image: CopperGloves, meleeDefence: 1, rangingDefence: 0, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Bronze Gloves', image: BronzeGloves, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Iron Gloves', image: IronGloves, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Steel Gloves', image: SteelGloves, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Psywood Gloves', image: PsywoodGloves, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'E. Copper Gloves', image: ECopperGloves, meleeDefence: 7, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'E. Gold Gloves', image: EGoldGloves, meleeDefence: 16, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Damascus Gloves', image: DamascusGloves, meleeDefence: 30, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: 0, psychicAccuracy: 0},
];

const bootsData = [
    {item: 'Copper Boots', image: CopperBoots, meleeDefence: 1, rangingDefence: 0, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Bronze Boots', image: BronzeBoots, meleeDefence: 2, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Iron Boots', image: IronBoots, meleeDefence: 3, rangingDefence: 1, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Steel Boots', image: SteelBoots, meleeDefence: 4, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Psywood Boots', image: PsywoodBoots, meleeDefence: 5, rangingDefence: 2, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'E. Copper Boots', image: ECopperBoots, meleeDefence: 7, rangingDefence: 3, psychicDefence: 0, meleeAccuracy: 0, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'E. Gold Boots', image: EGoldBoots, meleeDefence: 18, rangingDefence: 8, psychicDefence: 0, meleeAccuracy: 1, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Damascus Boots', image: DamascusBoots, meleeDefence: 30, rangingDefence: 15, psychicDefence: 0, meleeAccuracy: 2, rangingAccuracy: 0, psychicAccuracy: 0},
    {item: 'Boots of the Bull', image: BootsOfTheBull, meleeDefence: 45 , rangingDefence: 20, psychicDefence: 0, meleeAccuracy: 8, rangingAccuracy: 0, psychicAccuracy: 0},
];

export default class Combat extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
        weaponsData: weaponsData,
        offHandData: offHandData,
        helmData: helmData,
        chestData: chestData,
        legsData: legsData,
        glovesData: glovesData,
        bootsData: bootsData,
        currentExperience: '',
        desiredLevel: 0,
        experienceNeeded: 0,
        meleeDefence: 0,
        rangingDefence: 0,
        psychicDefence: 0,
        meleeAccuracy: 0,
        rangingAccuracy: 0,
        psychicAccuracy: 0,
        minDamage: 0,
        maxDamage: 0,
        attackSpeed: 0,
        dps: 0,
        selectedItems: {helm: null, chest: null, legs: null, gloves: null, boots: null, weapon: null, offhand: null}
        }
    }

    calculateTotalLevel(melee, ranging, psychic, destruction, accuracy, defence, health)
    {
        let highestCombat = Math.max(parseInt(melee), parseInt(ranging), parseInt(psychic));
        let destructionAddition = (parseInt(destruction)) / 2;
        let combatLevel = (parseInt(accuracy) + parseInt(defence) + parseInt(health) + highestCombat + destructionAddition) / 3;

        combatLevel = isNaN(combatLevel) ? 0 : Math.round(combatLevel * 100) / 100
        this.setState({combatLevel: combatLevel});
    }

    handleAccuracy(event)
    {
        let accuracy = event.target.value;
        this.setState({accuracy: accuracy});
        this.calculateTotalLevel(this.state.melee, this.state.ranging, this.state.psychic, this.state.destruction, accuracy, this.state.defence, this.state.health);
    }

    handleMelee(event)
    {
        let melee = event.target.value;
        this.setState({melee: melee});
        this.calculateTotalLevel(melee, this.state.ranging, this.state.psychic, this.state.destruction, this.state.accuracy, this.state.defence, this.state.health);
    }

    handleRanging(event)
    {
        let ranging = event.target.value;
        this.setState({ranging: ranging});
        this.calculateTotalLevel(this.state.melee, ranging, this.state.psychic, this.state.destruction, this.state.accuracy, this.state.defence, this.state.health);
    }

    handlePsychic(event)
    {
        let psychic = event.target.value;
        this.setState({psychic: psychic});
        this.calculateTotalLevel(this.state.melee, this.state.ranging, psychic, this.state.destruction, this.state.accuracy, this.state.defence, this.state.health);
    }

    handleDestruction(event)
    {
        let destruction = event.target.value;
        this.setState({destruction: destruction});
        this.calculateTotalLevel(this.state.melee, this.state.ranging, this.state.psychic, destruction, this.state.accuracy, this.state.defence, this.state.health);
    }

    handleDefence(event)
    {
        let defence = event.target.value;
        this.setState({defence: defence});
        this.calculateTotalLevel(this.state.melee, this.state.ranging, this.state.psychic, this.state.destruction, this.state.accuracy, defence, this.state.health);
    }

    handleHealth(event)
    {
        let health = event.target.value;
        this.setState({health: health});
        this.calculateTotalLevel(this.state.melee, this.state.ranging, this.state.psychic, this.state.destruction, this.state.accuracy, this.state.defence, this.state.health);
    }

    calculateArmoryStats(selectedItems)
    {
        let selectedHelm = selectedItems['helm'] ? this.state.helmData.find(helm => helm.item === selectedItems['helm']) : null;
        let selectedChest = selectedItems['chest'] ? this.state.chestData.find(chest => chest.item === selectedItems['chest']) : null;
        let selectedLegs = selectedItems['legs'] ? this.state.legsData.find(legs => legs.item === selectedItems['legs']) : null;
        let selectedBoots = selectedItems['boots'] ? this.state.bootsData.find(boots => boots.item === selectedItems['boots']) : null;
        let selectedWeapon = selectedItems['weapon'] ? this.state.weaponsData.find(weapon => weapon.item === selectedItems['weapon']) : null;
        let selectedOffhand = selectedItems['offhand'] ? this.state.offHandData.find(offhand => offhand.item === selectedItems['offhand']) : null;
        let selectedGloves = selectedItems['gloves'] ? this.state.glovesData.find(gloves => gloves.item === selectedItems['gloves']) : null;

        let minDamage = 0;
        let maxDamage = 0;
        let attackSpeed = 0;
        let dps = 0;

        let meleeDefence = 0;
        let rangingDefence = 0;
        let psychicDefence = 0;
        let meleeAccuracy = 0;
        let rangingAccuracy = 0;
        let psychicAccuracy = 0;

        if(selectedHelm)
        {
            meleeDefence = meleeDefence + selectedHelm.meleeDefence;
            rangingDefence = rangingDefence + selectedHelm.rangingDefence;
            psychicDefence = psychicDefence + selectedHelm.psychicDefence;
            meleeAccuracy = meleeAccuracy + selectedHelm.meleeAccuracy;
            rangingAccuracy = rangingAccuracy + selectedHelm.rangingAccuracy;
            psychicAccuracy = psychicAccuracy + selectedHelm.psychicAccuracy;
        }

        if(selectedChest)
        {
            meleeDefence = meleeDefence + selectedChest.meleeDefence;
            rangingDefence = rangingDefence + selectedChest.rangingDefence;
            psychicDefence = psychicDefence + selectedChest.psychicDefence;
            meleeAccuracy = meleeAccuracy + selectedChest.meleeAccuracy;
            rangingAccuracy = rangingAccuracy + selectedChest.rangingAccuracy;
            psychicAccuracy = psychicAccuracy + selectedChest.psychicAccuracy;
        }

        if(selectedLegs)
        {
            meleeDefence = meleeDefence + selectedLegs.meleeDefence;
            rangingDefence = rangingDefence + selectedLegs.rangingDefence;
            psychicDefence = psychicDefence + selectedLegs.psychicDefence;
            meleeAccuracy = meleeAccuracy + selectedLegs.meleeAccuracy;
            rangingAccuracy = rangingAccuracy + selectedLegs.rangingAccuracy;
            psychicAccuracy = psychicAccuracy + selectedLegs.psychicAccuracy;
        }

        if(selectedBoots)
        {
            meleeDefence = meleeDefence + selectedBoots.meleeDefence;
            rangingDefence = rangingDefence + selectedBoots.rangingDefence;
            psychicDefence = psychicDefence + selectedBoots.psychicDefence;
            meleeAccuracy = meleeAccuracy + selectedBoots.meleeAccuracy;
            rangingAccuracy = rangingAccuracy + selectedBoots.rangingAccuracy;
            psychicAccuracy = psychicAccuracy + selectedBoots.psychicAccuracy;
        }

        if(selectedOffhand)
        {
            meleeDefence = meleeDefence + selectedOffhand.meleeDefence;
            rangingDefence = rangingDefence + selectedOffhand.rangingDefence;
            psychicDefence = psychicDefence + selectedOffhand.psychicDefence;
            meleeAccuracy = meleeAccuracy + selectedOffhand.meleeAccuracy;
            rangingAccuracy = rangingAccuracy + selectedOffhand.rangingAccuracy;
            psychicAccuracy = psychicAccuracy + selectedOffhand.psychicAccuracy;
        }

        if(selectedGloves)
        {
            meleeDefence = meleeDefence + selectedGloves.meleeDefence;
            rangingDefence = rangingDefence + selectedGloves.rangingDefence;
            psychicDefence = psychicDefence + selectedGloves.psychicDefence;
            meleeAccuracy = meleeAccuracy + selectedGloves.meleeAccuracy;
            rangingAccuracy = rangingAccuracy + selectedGloves.rangingAccuracy;
            psychicAccuracy = psychicAccuracy + selectedGloves.psychicAccuracy;
        }

        if(selectedWeapon)
        {
            attackSpeed = selectedWeapon.attackSpeed;
            minDamage = selectedWeapon.baseMinDamage;
            let levelIncludedMax = this.state.melee ? Math.floor(this.state.melee / 8) : 0;
            maxDamage = selectedWeapon.baseMaxDamage + levelIncludedMax;
            dps = Math.round((((minDamage + maxDamage) / 2) / attackSpeed) * 100) / 100;

            if(selectedWeapon.meleeAccuracy)
            {
                meleeAccuracy = meleeAccuracy + selectedWeapon.meleeAccuracy;
            }
        }
        else 
        {
            attackSpeed = 2.5;
            minDamage = 0;
            let levelIncludedMax = this.state.melee ? Math.floor(this.state.melee / 8) : 0;
            maxDamage = levelIncludedMax;
            dps = Math.round((((minDamage + maxDamage) / 2) / attackSpeed) * 100) / 100;
        }

        this.setState({meleeDefence: meleeDefence});
        this.setState({rangingDefence: rangingDefence});
        this.setState({psychicDefence: psychicDefence});
        this.setState({meleeAccuracy: meleeAccuracy});
        this.setState({rangingAccuracy: rangingAccuracy});
        this.setState({psychicAccuracy: psychicAccuracy});
        this.setState({attackSpeed: attackSpeed});
        this.setState({minDamage: minDamage});
        this.setState({maxDamage: maxDamage});
        this.setState({dps: dps});
    }

    handleHelm = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['helm'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    handleChest = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['chest'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    handleLegs = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['legs'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    handleGloves = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['gloves'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    handleBoots = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['boots'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    handleWeapon = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['weapon'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    handleOffhand = (event, { value }) =>
    {
        let selectedItems = this.state.selectedItems;
        selectedItems['offhand'] = value;
        this.setState({selectedItems: selectedItems});
        this.calculateArmoryStats(selectedItems);
    }

    render()
    {
        let selectedHelm = this.state.selectedItems['helm'] ? this.state.helmData.find(helm => helm.item === this.state.selectedItems['helm']).image : null;
        let selectedChest = this.state.selectedItems['chest'] ? this.state.chestData.find(chest => chest.item === this.state.selectedItems['chest']).image : null;
        let selectedLegs = this.state.selectedItems['legs'] ? this.state.legsData.find(legs => legs.item === this.state.selectedItems['legs']).image : null;
        let selectedBoots = this.state.selectedItems['boots'] ? this.state.bootsData.find(boots => boots.item === this.state.selectedItems['boots']).image : null;
        let selectedWeapon = this.state.selectedItems['weapon'] ? this.state.weaponsData.find(weapon => weapon.item === this.state.selectedItems['weapon']).image : null;
        let selectedOffhand = this.state.selectedItems['offhand'] ? this.state.offHandData.find(offhand => offhand.item === this.state.selectedItems['offhand']).image : null;
        let selectedGloves = this.state.selectedItems['gloves'] ? this.state.glovesData.find(gloves => gloves.item === this.state.selectedItems['gloves']).image : null;

        return (

            <div>
                <TopNav/>

                <Segment clearing raised color='yellow'>
                    <Header as='h1' dividing floated='left'>
                        <Image src={CombatIcon} className="header-left"></Image>
                        <Header.Content>Combat
                            <Header.Subheader>Combat</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Segment>
                
                <Divider/>
                
                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={MuscleIcon} size="large"/>
                                <span className="divider-spacer">Combat Level</span>
                            </Header>
                        </Divider>

                        <Segment raised color='yellow'>
                            <Grid>
                                <Grid.Row>
                                    <Form>
                                        <Form.Group>
                                            <Form.Field className="accuracy-margin">
                                                <label>Accuracy</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Accuracy' onChange={(e) => this.handleAccuracy(e)}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Defence</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Defence' onChange={(e) => this.handleDefence(e)}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Health</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Health' onChange={(e) => this.handleHealth(e)}/>
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Melee</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Melee' onChange={(e) => this.handleMelee(e)}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Ranging</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Ranging' onChange={(e) => this.handleRanging(e)}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Psychic</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Psychic' onChange={(e) => this.handlePsychic(e)}/>
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Destruction</label>
                                                <Input maxLength="2" style={{width: 170}} fluid placeholder='Destruction' onChange={(e) => this.handleDestruction(e)}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Combat Level</label>{this.state.combatLevel}
                                            </Form.Field>
                                            </Form.Group>
                                    </Form>
                                </Grid.Row>
                            </Grid>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={TentIcon} size="large"/>
                                <span className="divider-spacer">Armory</span>
                            </Header>
                        </Divider>

                        <Segment raised color='yellow'>
                            <Grid columns='equal'>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            <label>Damage</label>{this.state.minDamage} - {this.state.maxDamage}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Attack Speed</label>{this.state.attackSpeed} seconds
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Melee Defence</label>{this.state.meleeDefence}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Ranging Defence</label>{this.state.rangingDefence}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Psychic Defence</label>{this.state.psychicDefence}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Melee Accuracy</label>{this.state.meleeAccuracy}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Ranging Accuracy</label>{this.state.rangingAccuracy}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Psychic Accuracy</label>{this.state.psychicAccuracy}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>DPS</label>{this.state.dps}
                                            </Form.Field>
                                        <Form.Field>
                                            Some fields are based on stats. For more accurate results, complete combat level calc first.
                                        </Form.Field>
                                    </Form>
                                
                                </Grid.Column>


                                <Grid.Column>
                                <Form>
                                <Form.Field>
                                <label>Helmet</label>
                                <Dropdown
                                    placeholder='Helmet'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleHelm}
                                    options={this.state.helmData.map(helm => ({key: helm.item, text: helm.item, value: helm.item, image: helm.image}))}
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Chest</label>
                                <Dropdown
                                    placeholder='Chest'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleChest}
                                    options={this.state.chestData.map(chest => ({key: chest.item, text: chest.item, value: chest.item, image: chest.image}))}
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Legs</label>
                                <Dropdown
                                    placeholder='Legs'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleLegs}
                                    options={this.state.legsData.map(legs => ({key: legs.item, text: legs.item, value: legs.item, image: legs.image}))}

                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Gloves</label>
                                <Dropdown
                                    placeholder='Gloves'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleGloves}
                                    options={this.state.glovesData.map(gloves => ({key: gloves.item, text: gloves.item, value: gloves.item, image: gloves.image}))}
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Boots</label>
                                <Dropdown
                                    placeholder='Boots'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleBoots}
                                    options={this.state.bootsData.map(boots => ({key: boots.item, text: boots.item, value: boots.item, image: boots.image}))}
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Weapon</label>
                                <Dropdown
                                    placeholder='Weapon'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleWeapon}
                                    options={this.state.weaponsData.map(weapon => ({key: weapon.item, text: weapon.item, value: weapon.item, image: weapon.image}))}
                                />
                                </Form.Field>
                                <Form.Field>
                                <label>Offhand</label>
                                <Dropdown
                                    placeholder='Offhand'
                                    clearable
                                    fluid
                                    search
                                    selection
                                    scrolling
                                    onChange={this.handleOffhand}
                                    options={this.state.offHandData.map(offhand => ({key: offhand.item, text: offhand.item, value: offhand.item, image: offhand.image}))}
                                />
                                </Form.Field>

                                </Form>

                                </Grid.Column>
                                <Grid.Column style={{marginLeft: 100}}>
                                <Grid centered columns={3}>
                                    <Grid.Column style={{marginTop: '170px'}}>
                                        <br />
                                        <Image src={selectedWeapon} style={{marginLeft: '20px'}} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image src={selectedHelm} style={{marginLeft: '11px', marginTop: '150px'}}/>
                                        <Image src={selectedChest} />
                                        <Image src={selectedLegs} style={{marginLeft: '11px'}}/>
                                        <Image src={selectedBoots} style={{marginLeft: '9px'}}/>
                                    </Grid.Column>
                                    <Grid.Column style={{marginTop: '170px'}}>
                                        <br />
                                        <Image src={selectedOffhand} />
                                        <Image src={selectedGloves} />
                                    </Grid.Column>
                                </Grid>
                                </Grid.Column>

                            </Grid>


                            </Segment>


                    </Grid.Column>  
                </Grid.Row>
                </Grid>
            </div>
        )
    }
}