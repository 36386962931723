import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import AxeIcon from "../../images/icons/Axe Icon.svg";
import BeerIcon from "../../images/icons/Beer Icon.svg";
import CampfireIcon from "../../images/icons/Campfire Icon.svg";
import CookingIcon from "../../images/icons/Cooking Icon.svg";
import CookingPotIcon from "../../images/icons/Cooking Pot Icon.svg";
import FishingIcon from "../../images/icons/Fishing Icon.svg";
import SurvivalIcon from "../../images/icons/Survival Icon.png";
import TreeIcon from "../../images/icons/Tree Icon 2.svg";
import AcaciaBark from '../../images/survival/Acacia Bark.PNG';
import AcaciaLogs from '../../images/survival/acaciawood.PNG';
import Avocado from '../../images/survival/avacadoes.PNG';
import BaobabBark from '../../images/survival/Baobab Bark.PNG';
import BaobabLogs from '../../images/survival/baobabwood.PNG';
import Baracuda from '../../images/survival/baracuda.PNG';
import Barley from '../../images/survival/barley.png';
import BowlOfWater from '../../images/survival/bowlofwater.PNG';
import Bread from '../../images/survival/bread.PNG';
import Crab from '../../images/survival/crab.PNG';
import Curry from '../../images/survival/curry.PNG';
import DeadwoodBark from '../../images/survival/Deadwood Bark.PNG';
import DeadwoodLogs from '../../images/survival/deadwood.PNG';
import DebarkingTool from '../../images/survival/Debarking Tool.PNG';
import DragonMeat from '../../images/survival/dragonmeat.PNG';
import DragonSushi from '../../images/survival/dragonsushi.PNG';
import Flour from '../../images/survival/flour.PNG';
import GiantSquid from '../../images/survival/giantsquid.PNG';
import RawGiantSquid from '../../images/survival/giantsquidraw.PNG';
import Guppie from '../../images/survival/guppie.PNG';
import Kebab from '../../images/survival/kebab.PNG';
import Lobster from '../../images/survival/lobster.PNG';
import MatchBox from '../../images/survival/matchbox.png';
import Mead from '../../images/survival/mead.PNG';
import Beef from '../../images/survival/meat.PNG';
import OakBark from '../../images/survival/Oak Bark.PNG';
import OakLogs from '../../images/survival/oakwood.PNG';
import Oil from '../../images/survival/oil.PNG';
import Oyster from '../../images/survival/oyster.PNG';
import PineBark from '../../images/survival/Pine Bark.PNG';
import PineLogs from '../../images/survival/pinewood.PNG';
import Pizza from '../../images/survival/pizza.PNG';
import PsywoodBark from '../../images/survival/Psywood Bark.PNG';
import PsywoodLogs from '../../images/survival/psywoodlogs.png';
import RawBaracuda from '../../images/survival/rawbaracuda.PNG';
import RawCrab from '../../images/survival/rawcrab.PNG';
import RawDragonMeat from '../../images/survival/rawdragonmeat.png';
import RawGuppie from '../../images/survival/rawguppie.PNG';
import RawLobster from '../../images/survival/rawlobster.PNG';
import RawBeef from '../../images/survival/rawmeat.png';
import RawOyster from '../../images/survival/rawoyster.PNG';
import RawSalmon from '../../images/survival/rawsalmon.PNG';
import RawSturgeon from '../../images/survival/rawsturgeon.PNG';
import RawTigerfish from '../../images/survival/rawtigerfish.PNG';
import RawTuna from '../../images/survival/rawtuna.PNG';
import RedwoodBark from '../../images/survival/Redwood Bark.PNG';
import RedwoodLogs from '../../images/survival/redwood.PNG';
import Rice from '../../images/survival/rice.PNG';
import Roast from '../../images/survival/roast.PNG';
import Salmon from '../../images/survival/salmon.PNG';
import SalmonSushi from '../../images/survival/salmonsushi.PNG';
import BeefSoup from '../../images/survival/soup.PNG';
import Spices from '../../images/survival/spices.PNG';
import Sturgeon from '../../images/survival/sturgeon.PNG';
import Tigerfish from '../../images/survival/tigerfish.PNG';
import Tomato from '../../images/survival/tomato.png';
import Tuna from '../../images/survival/tuna.PNG';
import TunaSushi from '../../images/survival/tunasushi.PNG';
import WasabiPaste from '../../images/survival/wasabipaste.PNG';
import WeerwoodBark from '../../images/survival/Weerwood Bark.PNG';
import WeerwoodLogs from '../../images/survival/weerwood.PNG';
import WillowBark from '../../images/survival/Willow Bark.PNG';
import WillowLogs from '../../images/survival/willowwood.PNG';
import YellowwoodBark from '../../images/survival/Yellowwood Bark.PNG';
import YellowWoodLogs from '../../images/survival/yellowwood.PNG';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav.js';



const survivalFishingData = [
    {level: 1, item: 'Guppie', experience: 3, image: RawGuppie},
    {level: 10, item: 'Salmon', experience: 8, image: RawSalmon},
    {level: 20, item: 'Tuna', experience: 10, image: RawTuna},
    {level: 30, item: 'Oyster', experience: 12, image: RawOyster},
    {level: 40, item: 'Lobster', experience: 16, image: RawLobster},
    {level: 50, item: 'Crab', experience: 18, image: RawCrab},
    {level: 60, item: 'Baracuda', experience: 22, image: RawBaracuda},
    {level: 70, item: 'Tigerfish', experience: 25, image: RawTigerfish},
    {level: 80, item: 'Sturgeon', experience: 35, image: RawSturgeon},
    {level: 90, item: 'Giant Squid', experience: 55, image: RawGiantSquid},

];

const survivalCookingData = [
    {level: 1, item: 'Guppie', experience: 3, image: RawGuppie, secondaryImage: Guppie, material: RawGuppie, materialName: 'Raw Guppie', materialNumber: 1},
    {level: 5, item: 'Salmon', experience: 7, image: RawSalmon, secondaryImage: Salmon, material: RawSalmon, materialName: 'Raw Salmon', materialNumber: 1},
    {level: 15, item: 'Tuna', experience: 10, image: RawTuna, secondaryImage: Tuna, material: RawTuna, materialName: 'Raw Tuna', materialNumber: 1},
    {level: 25, item: 'Oyster', experience: 15, image: RawOyster, secondaryImage: Oyster, material: RawOyster, materialName: 'Raw Oyster', materialNumber: 1},
    {level: 35, item: 'Lobster', experience: 20, image: RawLobster, secondaryImage: Lobster, material: RawLobster, materialName: 'Raw Lobster', materialNumber: 1},
    {level: 45, item: 'Crab', experience: 24, image: RawCrab, secondaryImage: Crab, material: RawCrab, materialName: 'Raw Crab', materialNumber: 1},
    {level: 55, item: 'Barracuda', experience: 30, image: RawBaracuda, secondaryImage: Baracuda, material: RawBaracuda, materialName: 'Raw Barracuda', materialNumber: 1},
    {level: 65, item: 'Tigerfish', experience: 38, image: RawTigerfish, secondaryImage: Tigerfish, material: RawTigerfish, materialName: 'Raw Tigerfish', materialNumber: 1},
    {level: 75, item: 'Sturgeon', experience: 45, image: RawSturgeon, secondaryImage: Sturgeon, material: RawSturgeon, materialName: 'Raw Sturgeon', materialNumber: 1},
    {level: 85, item: 'Giant Squid', experience: 60, image: RawGiantSquid, secondaryImage: GiantSquid, material: RawGiantSquid, materialName: 'Raw Giant Squid', materialNumber: 1},
];

const survivalFishingAndCookingData = [
    {level: 1, item: 'Guppie', experience: 6, image: RawGuppie, secondaryImage: Guppie, material: RawGuppie, materialName: 'Raw Guppie', materialNumber: 1},
    {level: 10, item: 'Salmon', experience: 15, image: RawSalmon, secondaryImage: Salmon, material: RawSalmon, materialName: 'Raw Salmon', materialNumber: 1},
    {level: 20, item: 'Tuna', experience: 20, image: RawTuna, secondaryImage: Tuna, material: RawTuna, materialName: 'Raw Tuna', materialNumber: 1},
    {level: 30, item: 'Oyster', experience: 27, image: RawOyster, secondaryImage: Oyster, material: RawOyster, materialName: 'Raw Oyster', materialNumber: 1},
    {level: 40, item: 'Lobster', experience: 36, image: RawLobster, secondaryImage: Lobster, material: RawLobster, materialName: 'Raw Lobster', materialNumber: 1},
    {level: 50, item: 'Crab', experience: 42, image: RawCrab, secondaryImage: Crab, material: RawCrab, materialName: 'Raw Crab', materialNumber: 1},
    {level: 60, item: 'Barracuda', experience: 52, image: RawBaracuda, secondaryImage: Baracuda, material: RawBaracuda, materialName: 'Raw Barracuda', materialNumber: 1},
    {level: 70, item: 'Tigerfish', experience: 63, image: RawTigerfish, secondaryImage: Tigerfish, material: RawTigerfish, materialName: 'Raw Tigerfish', materialNumber: 1},
    {level: 80, item: 'Sturgeon', experience: 80, image: RawSturgeon, secondaryImage: Sturgeon, material: RawSturgeon, materialName: 'Raw Sturgeon', materialNumber: 1},
    {level: 90, item: 'Giant Squid', experience: 115, image: RawGiantSquid, secondaryImage: GiantSquid, material: RawGiantSquid, materialName: 'Raw Giant Squid', materialNumber: 1},
];

const survivalWoodChoppingData = [
    {level: 1, item: 'Deadwood', experience: 2, image: DeadwoodLogs},
    {level: 10, item: 'Pinewood', experience: 4, image: PineLogs},
    {level: 20, item: 'Oakwood', experience: 6, image: OakLogs},
    {level: 30, item: 'Willowwood', experience: 12, image: WillowLogs},
    {level: 40, item: 'Yellowwood', experience: 16, image: YellowWoodLogs},
    {level: 50, item: 'Psywood', experience: 20, image: PsywoodLogs},
    {level: 60, item: 'Acaciawood', experience: 30, image: AcaciaLogs},
    {level: 70, item: 'Weerwood', experience: 45, image: WeerwoodLogs},
    {level: 80, item: 'Redwood', experience: 65, image: RedwoodLogs},
    {level: 90, item: 'Baobabwood', experience: 60, image: BaobabLogs},
];

const survivalWoodBurning = [
    {level: 1, item: 'Deadwood', experience: 3, image: DeadwoodLogs, secondaryImage: MatchBox, combined: true},
    {level: 10, item: 'Pinewood', experience: 5, image: PineLogs, secondaryImage: MatchBox, combined: true},
    {level: 20, item: 'Oakwood', experience: 8, image: OakLogs, secondaryImage: MatchBox, combined: true},
    {level: 30, item: 'Willowwood', experience: 12, image: WillowLogs, secondaryImage: MatchBox, combined: true},
    {level: 40, item: 'Yellowwood', experience: 16, image: YellowWoodLogs, secondaryImage: MatchBox, combined: true},
    {level: 50, item: 'Psywood', experience: 20, image: PsywoodLogs, secondaryImage: MatchBox, combined: true},
    {level: 60, item: 'Acaciawood', experience: 24, image: AcaciaLogs, secondaryImage: MatchBox, combined: true},
    {level: 70, item: 'Weerwood', experience: 28, image: WeerwoodLogs, secondaryImage: MatchBox, combined: true},
    {level: 80, item: 'Redwood', experience: 32, image: RedwoodLogs, secondaryImage: MatchBox, combined: true},
    {level: 90, item: 'Baobabwood', experience: 36, image: BaobabLogs, secondaryImage: MatchBox, combined: true},
];

const survivalBarkMaking = [
    {level: 1, item: 'Deadwood Bark', experience: 1, image: DeadwoodLogs, secondaryImage: DebarkingTool, thirdImage: DeadwoodBark, combined: true},
    {level: 10, item: 'Pine Bark', experience: 2, image: PineLogs, secondaryImage: DebarkingTool, thirdImage: PineBark, combined: true},
    {level: 20, item: 'Oak Bark', experience: 3, image: OakLogs, secondaryImage: DebarkingTool, thirdImage: OakBark, combined: true},
    {level: 30, item: 'Willow Bark', experience: 4, image: WillowLogs, secondaryImage: DebarkingTool, thirdImage: WillowBark, combined: true},
    {level: 40, item: 'Yellowwood Bark', experience: 5, image: YellowWoodLogs, secondaryImage: DebarkingTool, thirdImage: YellowwoodBark, combined: true},
    {level: 50, item: 'Psywood Bark', experience: 6, image: PsywoodLogs, secondaryImage: DebarkingTool, thirdImage: PsywoodBark, combined: true},
    {level: 60, item: 'Acacia Bark', experience: 7, image: AcaciaLogs, secondaryImage: DebarkingTool, thirdImage: AcaciaBark, combined: true},
    {level: 70, item: 'Weerwood Bark', experience: 8, image: WeerwoodLogs, secondaryImage: DebarkingTool, thirdImage: WeerwoodBark, combined: true},
    {level: 80, item: 'Redwood Bark', experience: 9, image: RedwoodLogs, secondaryImage: DebarkingTool, thirdImage: RedwoodBark, combined: true},
    {level: 90, item: 'Baobab Bark', experience: 10, image: BaobabLogs, secondaryImage: DebarkingTool, thirdImage: BaobabBark, combined: true},
];

const survivalFoodAndDrinksData = [
    {level: 4, item: 'Beef', experience: 3, image: RawBeef, secondaryImage: Beef, material: RawBeef, materialName: 'Raw Beef', materialNumber: 1},
    {level: 8, item: 'Bread', experience: 4, image: BowlOfWater, secondaryImage: Flour, thirdImage: Bread, material: BowlOfWater, materialName: 'Bowl of Water', materialNumber: 1, secondaryMaterial: Flour, secondaryMaterialName: 'Flour', secondaryMaterialNumber: 1, combined: true},
    // {level: 12, item: 'Cake', experience: 6, image: DeadwoodLogs}, SUGAR NOT IN GAME YET
    {level: 16, item: 'Mead', experience: 8, image: BowlOfWater, secondaryImage: Barley, thirdImage: Mead, material: BowlOfWater, materialName: 'Bowl of Water', materialNumber: 1, secondaryMaterial: Barley, secondaryMaterialName: 'Barley', secondaryMaterialNumber: 1, combined: true},
    {level: 24, item: 'Beef Soup', experience: 12, image: RawBeef, secondaryImage: BowlOfWater, thirdImage: Spices, fourthImage: BeefSoup, material: RawBeef, materialName: 'Raw Beef', materialNumber: 1, secondaryMaterial: BowlOfWater, secondaryMaterialName: 'Bowl of Water', secondaryMaterialNumber: 1, thirdMaterial: Spices, thirdMaterialName: 'Spices', thirdMaterialNumber: 1, combined: true},
    {level: 36, item: 'Kebab', experience: 18, image: RawBeef, secondaryImage: BowlOfWater, thirdImage: Flour, fourthImage: Avocado, fifthImage: Kebab, material: RawBeef, materialName: 'Raw Beef', materialNumber: 1, secondaryMaterial: BowlOfWater, secondaryMaterialName: 'Bowl of Water', secondaryMaterialNumber: 1, thirdMaterial: Flour, thirdMaterialName: 'Flour', thirdMaterialNumber: 1, fourthMaterial: Avocado, fourthMaterialName: 'Avocado', fourthMaterialNumber: 1, combined: true},
    {level: 41, item: 'Pizza', experience: 20, image: BowlOfWater, secondaryImage: Flour, thirdImage: Tomato, fourthImage: Pizza, material: BowlOfWater, materialName: 'Bowl of Water', materialNumber: 1, secondaryMaterial: Flour, secondaryMaterialName: 'Flour', secondaryMaterialNumber: 1, thirdMaterial: Tomato, thirdMaterialName: 'Tomato', thirdMaterialNumber: 1, combined: true},
    {level: 45, item: 'Curry', experience: 22, image: RawBeef, secondaryImage: BowlOfWater, thirdImage: Spices, fourthImage: Avocado, fifthImage: Curry, material: RawBeef, materialName: 'Raw Beef', materialNumber: 1, secondaryMaterial: BowlOfWater, secondaryMaterialName: 'Bowl of Water', secondaryMaterialNumber: 1, thirdMaterial: Spices, thirdMaterialName: 'Spices', thirdMaterialNumber: 1, fourthMaterial: Avocado, fourthMaterialName: 'Avocado', fourthMaterialNumber: 1, combined: true},
    {level: 49, item: 'Roast', experience: 24, image: RawBeef, secondaryImage: Avocado, thirdImage: Roast, material: RawBeef, materialName: 'Raw Beef', materialNumber: 1, secondaryMaterial: Avocado, secondaryMaterialName: 'Avocado', secondaryMaterialNumber: 1, combined: true},
    // {level: 53, item: 'Cherry Pie', experience: 26, image: DeadwoodLogs}, SUGAR NOT IN GAME YET
    {level: 57, item: 'Salmon Sushi', experience: 28, image: RawSalmon, secondaryImage: Rice, thirdImage: SalmonSushi, material: RawSalmon, materialName: 'Raw Salmon', materialNumber: 1, secondaryMaterial: Rice, secondaryMaterialName: 'Rice', secondaryMaterialNumber: 1, combined: true},
    {level: 61, item: 'Tuna Sushi', experience: 30, image: RawTuna, secondaryImage: Rice, thirdImage: TunaSushi, material: RawTuna, materialName: 'Raw Tuna', materialNumber: 1, secondaryMaterial: Rice, secondaryMaterialName: 'Rice', secondaryMaterialNumber: 1, combined: true},
    {level: 67, item: 'Dragon Meat', experience: 36, image: RawDragonMeat, secondaryImage: Oil, thirdImage: DragonMeat, material: RawDragonMeat, materialName: 'Raw Dragon Meat', materialNumber: 1, secondaryMaterial: Oil, secondaryMaterialName: 'Oil', secondaryMaterialNumber: 1, combined: true},
    // {level: 75, item: 'Elemental Ale', experience: 42, image: DeadwoodLogs}, NOT IN GAME YET
    {level: 84, item: 'Dragon Sushi', experience: 50, image: RawDragonMeat, secondaryImage: Rice, thirdImage: WasabiPaste, fourthImage: DragonSushi, material: RawDragonMeat, materialName: 'Raw Dragon Meat', materialNumber: 1, secondaryMaterial: Rice, secondaryMaterialName: 'Rice', secondaryMaterialNumber: 1, thirdMaterial: WasabiPaste, thirdMaterialName: 'Wasabi Paste', thirdMaterialNumber: 1, combined: true},
];


export default class Survival extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {survivalFishingData: survivalFishingData,
            survivalCookingData: survivalCookingData,
            survivalWoodChoppingData: survivalWoodChoppingData,
            survivalWoodBurning: survivalWoodBurning,
            survivalFoodAndDrinksData: survivalFoodAndDrinksData,
            survivalBarkMaking: survivalBarkMaking,
            survivalFishingAndCookingData: survivalFishingAndCookingData,
            currentExperience: '', desiredLevel: 0, experienceNeeded: 0}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.survivalFishingData, this.state.survivalCookingData, this.state.survivalWoodChoppingData, this.state.survivalWoodBurning, this.state.survivalFoodAndDrinksData, this.state.survivalBarkMaking, this.state.survivalFishingAndCookingData, level)
    }

    handleExperienceChange(event, survivalFishingData, survivalCookingData, survivalWoodChoppingData, survivalWoodBurning, survivalFoodAndDrinksData, survivalBarkMaking, survivalFishingAndCookingData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        survivalFishingData = calculateNumberNeeded(survivalFishingData, experienceNeeded, currentLevel);
        this.setState({survivalFishingData: survivalFishingData});
        survivalFishingData = calculateMaterialsNeeded(survivalFishingData, currentLevel);
        this.setState({survivalFishingData: survivalFishingData});

        survivalCookingData = calculateNumberNeeded(survivalCookingData, experienceNeeded, currentLevel);
        this.setState({survivalCookingData: survivalCookingData});
        survivalCookingData = calculateMaterialsNeeded(survivalCookingData, currentLevel);
        this.setState({survivalCookingData: survivalCookingData});

        survivalWoodChoppingData = calculateNumberNeeded(survivalWoodChoppingData, experienceNeeded, currentLevel);
        this.setState({survivalWoodChoppingData: survivalWoodChoppingData});
        survivalWoodChoppingData = calculateMaterialsNeeded(survivalWoodChoppingData, currentLevel);
        this.setState({survivalWoodChoppingData: survivalWoodChoppingData});

        survivalWoodBurning = calculateNumberNeeded(survivalWoodBurning, experienceNeeded, currentLevel);
        this.setState({survivalWoodBurning: survivalWoodBurning});
        survivalWoodBurning = calculateMaterialsNeeded(survivalWoodBurning, currentLevel);
        this.setState({survivalWoodBurning: survivalWoodBurning});

        survivalFoodAndDrinksData = calculateNumberNeeded(survivalFoodAndDrinksData, experienceNeeded, currentLevel);
        this.setState({survivalFoodAndDrinksData: survivalFoodAndDrinksData});
        survivalFoodAndDrinksData = calculateMaterialsNeeded(survivalFoodAndDrinksData, currentLevel);
        this.setState({survivalFoodAndDrinksData: survivalFoodAndDrinksData});

        survivalBarkMaking = calculateNumberNeeded(survivalBarkMaking, experienceNeeded, currentLevel);
        this.setState({survivalBarkMaking: survivalBarkMaking});
        survivalBarkMaking = calculateMaterialsNeeded(survivalBarkMaking, currentLevel);
        this.setState({survivalBarkMaking: survivalBarkMaking});

        survivalFishingAndCookingData = calculateNumberNeeded(survivalFishingAndCookingData, experienceNeeded, currentLevel);
        this.setState({survivalFishingAndCookingData: survivalFishingAndCookingData});
        survivalFishingAndCookingData = calculateMaterialsNeeded(survivalFishingAndCookingData, currentLevel);
        this.setState({survivalFishingAndCookingData: survivalFishingAndCookingData});

    }

    handleDesiredLevelChange(event, survivalFishingData, survivalCookingData, survivalWoodChoppingData, survivalWoodBurning, survivalFoodAndDrinksData, survivalBarkMaking, survivalFishingAndCookingData)
    {
        let desiredLevel = event.target.value;
        this.setState({desiredLevel: desiredLevel});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience, levels);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        survivalFishingData = calculateNumberNeeded(survivalFishingData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalFishingData: survivalFishingData});
        survivalFishingData = calculateMaterialsNeeded(survivalFishingData, currentLevel);
        this.setState({survivalFishingData: survivalFishingData});

        survivalCookingData = calculateNumberNeeded(survivalCookingData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalCookingData: survivalCookingData});
        survivalCookingData = calculateMaterialsNeeded(survivalCookingData, currentLevel);
        this.setState({survivalCookingData: survivalCookingData});

        // this.calculateFoodBurn(survivalCookingData, this.state.currentExperience, currentLevel, desiredLevel);

        survivalWoodChoppingData = calculateNumberNeeded(survivalWoodChoppingData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalWoodChoppingData: survivalWoodChoppingData});
        survivalWoodChoppingData = calculateMaterialsNeeded(survivalWoodChoppingData, currentLevel);
        this.setState({survivalWoodChoppingData: survivalWoodChoppingData});

        survivalWoodBurning = calculateNumberNeeded(survivalWoodBurning, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalWoodBurning: survivalWoodBurning});
        survivalWoodBurning = calculateMaterialsNeeded(survivalWoodBurning, currentLevel);
        this.setState({survivalWoodBurning: survivalWoodBurning});

        survivalFoodAndDrinksData = calculateNumberNeeded(survivalFoodAndDrinksData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalFoodAndDrinksData: survivalFoodAndDrinksData});
        survivalFoodAndDrinksData = calculateMaterialsNeeded(survivalFoodAndDrinksData, currentLevel);
        this.setState({survivalFoodAndDrinksData: survivalFoodAndDrinksData});

        survivalBarkMaking = calculateNumberNeeded(survivalBarkMaking, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalBarkMaking: survivalBarkMaking});
        survivalBarkMaking = calculateMaterialsNeeded(survivalBarkMaking, currentLevel);
        this.setState({survivalBarkMaking: survivalBarkMaking});

        survivalFishingAndCookingData = calculateNumberNeeded(survivalFishingAndCookingData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({survivalFishingAndCookingData: survivalFishingAndCookingData});
        survivalFishingAndCookingData = calculateMaterialsNeeded(survivalFishingAndCookingData, currentLevel);
        this.setState({survivalFishingAndCookingData: survivalFishingAndCookingData});

    }

    // calculateFoodBurn(data, currentExperience, currentLevel, desiredLevel)
    // {
    //     //how many will it take per level
    //     //How much exp till next level?
    //     let numberOfLevels = desiredLevel - currentLevel;
    //     let tempLevel = currentLevel;
    //
    //     for(let i = 0; i < numberOfLevels; i++)
    //     {
    //         let experienceToNextLevel = levels[tempLevel + 1];
    //
    //         //calculate burn rate for level per fish
    //         for(let item in data)
    //         {
    //             //how many will it take to cook to next level?
    //             let amountNeededToNextLevel = experienceToNextLevel / data[item].materialNeeded;
    //
    //             //how many will I burn?
    //             //maybe do this in a spreadsheet first
    //         }
    //
    //     }
    //
    //     console.log(data)
    // }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={SurvivalIcon} className="header-left"></Image>
                        <Header.Content>Survival
                            <Header.Subheader>Gathering</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.survivalFishingData, this.state.survivalCookingData, this.state.survivalWoodChoppingData, this.state.survivalWoodBurning, this.state.survivalFoodAndDrinksData, this.state.survivalBarkMaking, this.state.survivalFishingAndCookingData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.survivalFishingData, this.state.survivalCookingData, this.state.survivalWoodChoppingData, this.state.survivalWoodBurning, this.state.survivalFoodAndDrinksData, this.state.survivalBarkMaking, this.state.survivalFishingAndCookingData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={FishingIcon} size="large"/>
                                <span className="divider-spacer">Fishing</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalFishingData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={CookingIcon} size="large"/>
                                <span className="divider-spacer">Cooking</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalCookingData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={CookingPotIcon} size="large"/>
                                <span className="divider-spacer">Fishing and Cooking</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalFishingAndCookingData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={AxeIcon} size="large"/>
                                <span className="divider-spacer">Woodcutting</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalWoodChoppingData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={CampfireIcon} size="large"/>
                                <span className="divider-spacer">Firemaking</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalWoodBurning, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={TreeIcon} size="large"/>
                                <span className="divider-spacer">Barkmaking</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalBarkMaking, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>


                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={BeerIcon} size="large"/>
                                <span className="divider-spacer">Food and Drinks</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                        <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.survivalFoodAndDrinksData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
            </div>
        )
    }
}