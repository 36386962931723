import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import CoinIcon from "../../images/icons/Coin Icon.svg";
import TradingIcon from "../../images/icons/Trading Icon.png";
import DamascusCrossbow from '../../images/mechanics/damascuscrossbow.PNG';
import ECopperCrossBow from '../../images/mechanics/ecoppercrossbow.PNG';
import EGoldCrossbow from '../../images/mechanics/egoldcrossbow.PNG';
import PsychicCrossbow from '../../images/mechanics/psychiccrossbow.PNG';
import SteelCrossbow from '../../images/mechanics/steelcrossbow.PNG';
import BronzeShortSword from '../../images/weapons/Bronze/Bronze Short Sword.png';
import CopperShortSword from '../../images/weapons/Copper/Copper Short Sword.png';
import DamascusShortSword from '../../images/weapons/Damascus/Damascus Short Sword.png';
import ECopperShortSword from '../../images/weapons/E. Copper/E. Copper Short Sword.png';
import EGoldShortSword from '../../images/weapons/E. Gold/E. Gold Short Sword.PNG';
import IronShortSword from '../../images/weapons/Iron/Iron Short Sword.png';
import PsywoodShortSword from '../../images/weapons/Psywood/Psywood Short Sword.png';
import SteelShortSword from '../../images/weapons/Steel/Steel Short Sword.png';
import {
    buildRows, findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';

const tradingData = [
    {level: 1, item: 'Copper Short Sword', experience: 'N/A', image: CopperShortSword, alchValue: 2},
    {level: 1, item: 'Bronze Short Sword', experience: 'N/A', image: BronzeShortSword, alchValue: 7},
    {level: 1, item: 'Iron Short Sword', experience: 'N/A', image: IronShortSword, alchValue: 18},
    {level: 1, item: 'Steel Short Sword', experience: 'N/A', image: SteelShortSword, alchValue: 55},
    {level: 1, item: 'Psywood Short Sword', experience: 'N/A', image: PsywoodShortSword, alchValue: 160},
    {level: 1, item: 'E. Copper Short Sword', experience: 'N/A', image: ECopperShortSword, alchValue: 480},
    {level: 1, item: 'E. Gold Short Sword', experience: 'N/A', image: EGoldShortSword, alchValue: 3000},
    {level: 1, item: 'Damascus Short Sword', experience: 'N/A', image: DamascusShortSword, alchValue: 27000},
    {level: 1, item: 'Steel Crossbow', experience: 'N/A', image: SteelCrossbow, alchValue: 75},
    {level: 1, item: 'Psywood Crossbow', experience: 'N/A', image: PsychicCrossbow, alchValue: 150},
    {level: 1, item: 'E. Copper Crossbow', experience: 'N/A', image: ECopperCrossBow, alchValue: 2500},
    {level: 1, item: 'E. Gold Crossbow', experience: 'N/A', image: EGoldCrossbow, alchValue: 6500},
    {level: 1, item: 'Damascus Crossbow', experience: 'N/A', image: DamascusCrossbow, alchValue: 20000}
];


export default class Trading extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {tradingData: tradingData, currentExperience: '', desiredLevel: 0, experienceNeeded: 0}

    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.tradingData, level)
    }

    handleExperienceChange(event, tradingData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        tradingData = this.calculateNumberItemsNeeded(tradingData, currentLevel, currentExperience, this.state.desiredLevel);
        this.setState({tradingData: tradingData});
    }

    calculateNumberItemsNeeded(tradingData, currentLevel, currentExperience, desiredLevel)
    {
        if(this.state.currentLevel && desiredLevel && currentExperience !== '')
        {
            for(let item in tradingData)
            {
                let tempLevel = currentLevel;
                let numberNeeded = 0;
                let tempExperience = parseInt(currentExperience);

                while(tempLevel < parseInt(desiredLevel))
                {
                    let sellPrice = Math.floor((1 + tempLevel / 99) * tradingData[item].alchValue * 0.5);
                    let experienceToNextLevel = levels[tempLevel + 1] - tempExperience;
                    let experienceGainedPer = sellPrice / 20;
                    tempExperience = tempExperience + experienceGainedPer;
                    let newLevel = findCurrentLevel(tempExperience);

                    if(tempLevel !== newLevel)
                    {
                        numberNeeded = numberNeeded + 1;
                        tempLevel = newLevel;
                    }
                    else
                    {
                        let numberNeededForThisLevel = experienceToNextLevel / experienceGainedPer;
                        numberNeeded = numberNeeded + numberNeededForThisLevel;
                        tempExperience = tempExperience + ((numberNeededForThisLevel - 1) * experienceGainedPer)
                        tempLevel = tempLevel + 1;
                    }
                }
                tradingData[item].numberNeeded = Math.ceil(numberNeeded);
            }
        }
        return tradingData;
    }

    handleDesiredLevelChange(event, tradingData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        this.setState({tradingData: tradingData});
        tradingData = this.calculateNumberItemsNeeded(tradingData, currentLevel, this.state.currentExperience, event.target.value);
        this.setState({tradingData: tradingData});
    }

    handleSellPriceChange(event, tradingData)
    {
        let sellPrice = event.target.value;

        if(this.state.currentLevel && this.state.desiredLevel && sellPrice > 0)
        {
            let tempLevel = this.state.currentLevel;
            let numberNeeded = 0;
            let tempExperience = this.state.currentExperience;
            let startingPrice = true;
            let alchValue = Math.ceil((sellPrice / (1 + tempLevel / 99))) * 2;

            while(tempLevel < parseInt(this.state.desiredLevel))
            {

                let sellPrice = Math.floor((1 + tempLevel / 99) * alchValue * 0.5);
                let experienceToNextLevel = levels[tempLevel + 1] - tempExperience;
                let experienceGainedPer = sellPrice / 20;
                tempExperience = tempExperience + experienceGainedPer;
                let newLevel = findCurrentLevel(tempExperience, levels);

                if(tempLevel !== newLevel)
                {
                    numberNeeded = numberNeeded + 1;
                    tempLevel = newLevel;
                }
                else
                {
                    let numberNeededForThisLevel = experienceToNextLevel / experienceGainedPer;
                    numberNeeded = numberNeeded + numberNeededForThisLevel;
                    tempExperience = tempExperience + ((numberNeededForThisLevel - 1) * experienceGainedPer)
                    tempLevel = tempLevel + 1;
                }
            }

            let manualCalcNumberNeeded = isFinite(numberNeeded) ? Math.ceil(numberNeeded) : 0;
            this.setState({manualCalcNumberNeeded: manualCalcNumberNeeded});
        }
        else
        {
            this.setState({manualCalcNumberNeeded: 0});
        }
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={TradingIcon} className="header-left"></Image>
                        <Header.Content>Trading
                            <Header.Subheader>Miscellaneous</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.tradingData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.tradingData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                <Grid columns={1} divided>
                <Grid.Row className="table-margin">
                    <Grid.Column stretched>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={CoinIcon} size="large"/>
                                <span className="divider-spacer">Commonly Sold Items</span>
                            </Header>
                        </Divider>

                        <Segment raised>
                            <Table celled color='yellow' compact='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Level</Table.HeaderCell>
                                        <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                        <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {buildRows(this.state.tradingData, this.state.currentLevel)}
                                </Table.Body>

                            </Table>
                        </Segment>

                        <Divider horizontal>
                            <Header as='h2'>
                                <Image src={CoinIcon} size="large"/>
                                <span className="divider-spacer">Manual Calculation</span>
                            </Header>
                        </Divider>
                        
                        <Segment raised color='yellow'>
                            <Form>
                                <Form.Group>
                                    <Form.Field>
                                        <label>Sell Price</label>
                                        <Input maxLength="6" style={{width: 170}} fluid placeholder='Sell price' onChange={(e) => this.handleSellPriceChange(e, this.state.tradingData)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Number Needed</label>{this.state.manualCalcNumberNeeded ? this.state.manualCalcNumberNeeded.toLocaleString(): 0}
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
        )
    }
}