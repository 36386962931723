export const levels = {1: 0, 2: 50, 3: 105, 4: 166, 5: 234, 6: 308, 7: 392, 8: 483, 9: 584, 10: 695,
    11: 818, 12: 954, 13: 1104, 14: 1269, 15: 1452, 16: 1652, 17: 1877, 18: 2122, 19: 2393, 20: 2693,
    21: 3023, 22: 3388, 23: 3790, 24: 4234, 25: 4724, 26: 5265, 27: 5861, 28: 6520, 29: 7248, 30: 8050,
    31: 8936, 32: 9913, 33: 10992, 34: 12183, 35: 13498, 36: 14949, 37: 16550, 38: 18318, 39: 20270, 40: 22424,
    41: 24802, 42: 27427, 43: 30325, 44: 33523, 45: 37055, 46: 40954, 47: 45257, 48: 50008, 49: 55254, 50: 61044,
    51: 67437, 52: 74494, 53: 82286, 54: 90887, 55: 100383, 56: 110867, 57: 122442, 58: 135220, 59: 149329, 60: 164905,
    61: 182101, 62: 201087, 63: 222048, 64: 245190, 65: 270740, 66: 298498, 67: 330092, 68: 364477, 69: 402440, 70: 444354,
    71: 490630, 72: 541721, 73: 598130, 74: 660408, 75: 729169, 76: 805086, 77: 888904, 78: 981446, 79: 1083619, 80: 1196427,
    81: 1320975, 82: 1458486, 83: 1610310, 84: 1777936, 85: 1963008, 86: 2167345, 87: 2392948, 88: 2642034, 89: 2917045, 90: 3220682,
    91: 3555922, 92: 3926056, 93: 4334716, 94: 4785912, 95: 5284071, 96: 5834083, 97: 6441343, 98: 7111811, 99: 7852067
};