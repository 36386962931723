import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import mining from "../../images/mining/mining.png";
import TopNav from '../other/TopNav';

export default class Calculators extends React.Component
{
    render()
    {
        return (
            <div>
                <TopNav></TopNav>
                <div className='center-calculators'>
                    <Card.Group itemsPerRow={6} stackable>
                        <Card raised color='yellow' href='guides/mining' image={mining} header='Mining' meta='Gathering' description='Mining is leveled by gathering ores.' extra={<a href='https://damascus.fandom.com/wiki/Mining' target='_blank' rel='noopener noreferrer'><Icon name='external alternate' />Wiki</a>}  />
                    </Card.Group>
                </div>
            </div>
        )
    }
}