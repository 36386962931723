import React from 'react';
import { Divider, Form, Grid, Header, Image, Input, Segment, Table } from "semantic-ui-react";
import { levels } from '../../config/Levels.js';
import AnvilIcon from "../../images/icons/Anvil Icon.svg";
import HelmIcon from "../../images/icons/Helm Icon.svg";
import Anvil from '../../images/metalworking/anvil.png';
import BronzeBar from '../../images/metalworking/bronzebar.png';
import CopperBar from '../../images/metalworking/copperbar.png';
import DamascusBar from '../../images/metalworking/damascusbar.png';
import ECopperBar from '../../images/metalworking/ecopperbar.png';
import EGoldBar from '../../images/metalworking/egoldbar.png';
import GoldBar from '../../images/metalworking/goldbar.png';
import IronBar from '../../images/metalworking/ironbar.png';
import MetalworkIcon from "../../images/metalworking/Metalwork Icon.png";
import PsychicBar from '../../images/metalworking/psychicbar.png';
import SilverBar from '../../images/metalworking/silverbar.png';
import SteelBar from '../../images/metalworking/steelbar.png';
import Coal from '../../images/mining/coal.png';
import CopperOre from '../../images/mining/copperore.png';
import DamascusOre from '../../images/mining/damascusore.png';
import GoldOre from '../../images/mining/goldore.png';
import IronOre from '../../images/mining/ironore.png';
import SilverOre from '../../images/mining/silverore.png';
import TinOre from '../../images/mining/tinore.png';
import PsywoodLogs from '../../images/survival/psywoodlogs.png';
import {
    buildRows, calculateMaterialsNeeded,
    calculateNumberNeeded,
    findCurrentLevel
} from "../../utility/Utility";
import TopNav from '../other/TopNav';



const metalworkingBarMakingData = [
    {level: 1, item: 'Copper Bar', experience: 3, image: CopperOre, secondaryImage: CopperBar, material: CopperOre, materialName: 'Copper Ore', materialNumber: 1},
    {level: 10, item: 'Bronze Bar', experience: 5, image: CopperOre, secondaryImage: TinOre, thirdImage: BronzeBar, material: CopperOre, materialName: 'Copper Ore', materialNumber: 1, secondaryMaterial: TinOre, secondaryMaterialName: 'Tin Ore', secondaryMaterialNumber: 1, combined: true},
    {level: 20, item: 'Iron Bar', experience: 8, image: IronOre, secondaryImage: IronBar, material: IronOre, materialName: 'Iron Ore', materialNumber: 1},
    {level: 30, item: 'Steel Bar', experience: 10, image: IronOre, secondaryImage: Coal, thirdImage: SteelBar, material: IronOre, materialName: 'Iron Ore', materialNumber: 1, secondaryMaterial: Coal, secondaryMaterialName: 'Coal', secondaryMaterialNumber: 1, combined: true},
    {level: 35, item: 'Silver Bar', experience: 11, image: SilverOre, secondaryImage: SilverBar, material: SilverOre, materialName: 'Silver Ore', materialNumber: 1},
    {level: 40, item: 'Psychic Bar', experience: 12, image: PsywoodLogs, secondaryImage: Coal, thirdImage: PsychicBar, material: PsywoodLogs, materialName: 'Psywood Logs', materialNumber: 1, secondaryMaterial: Coal, secondaryMaterialName: 'Coal', secondaryMaterialNumber: 2, combined: true},
    {level: 50, item: 'E. Copper Bar', experience: 14, image: CopperBar, secondaryImage: PsywoodLogs, thirdImage: ECopperBar, material: CopperBar, materialName: 'Copper Bar', materialNumber: 1, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 55, item: 'Gold Bar', experience: 16, image: GoldOre, secondaryImage: GoldBar, material: GoldOre, materialName: 'Gold Ore', materialNumber: 1},
    {level: 60, item: 'E. Gold Bar', experience: 18, image: GoldBar, secondaryImage: PsywoodLogs, thirdImage: EGoldBar, material: GoldBar, materialName: 'Gold Bar', materialNumber: 1, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 60, item: 'Gold Bar and E. Gold Bar', experience: 34, image: GoldOre, secondaryImage: EGoldBar, material: GoldOre, materialName: 'Gold Ore', materialNumber: 1, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Logs', secondaryMaterialNumber: 1},
    {level: 70, item: 'Damascus Bar', experience: 20, image: DamascusOre, secondaryImage: Coal, thirdImage: DamascusBar, material: DamascusOre, materialName: 'Damascus Ore', materialNumber: 1, secondaryMaterial: Coal, secondaryMaterialName: 'Coal', secondaryMaterialNumber: 4, combined: true}
];

const metalworkingForgeItemData = [
    {level: 1, item: 'Forge Copper Item at Anvil', experience: 1, image: CopperBar, secondaryImage: Anvil, material: CopperBar, materialName: 'Copper Bar', materialNumber: 1},
    {level: 10, item: 'Forge Bronze Item at Anvil', experience: 2, image: BronzeBar, secondaryImage: Anvil, material: BronzeBar, materialName: 'Bronze Bar', materialNumber: 1},
    {level: 20, item: 'Forge Iron Item at Anvil', experience: 3, image: IronBar, secondaryImage: Anvil, material: IronBar, materialName: 'Iron Bar', materialNumber: 1},
    {level: 30, item: 'Forge Steel Item at Anvil', experience: 4, image: SteelBar, secondaryImage: Anvil, material: SteelBar, materialName: 'Steel Bar', materialNumber: 1},
    {level: 40, item: 'Forge Psychic Item at Anvil', experience: 5, image: PsychicBar, secondaryImage: Anvil, material: PsychicBar, materialName: 'Psychic Bar', materialNumber: 1},
    {level: 50, item: 'Forge E. Copper Item at Anvil', experience: 6, image: ECopperBar, secondaryImage: Anvil, material: ECopperBar, materialName: 'E. Copper Bar', materialNumber: 1},
    {level: 60, item: 'Forge E. Gold Bar Item at Anvil', experience: 7, image: EGoldBar, secondaryImage: Anvil, material: EGoldBar, materialName: 'E. Gold Bar', materialNumber: 1},
    {level: 70, item: 'Forge Damascus Item at Anvil', experience: 8, image: DamascusBar, secondaryImage: Anvil, material: DamascusBar, materialName: 'Damascus Bar', materialNumber: 1}
];

const metalworkingBarAndForgeData = [
    {level: 1, item: 'Copper Bar and Forge Item at Anvil', experience: 4, image: CopperOre, secondaryImage: CopperBar, thirdImage: Anvil,  material: CopperOre, materialName: 'Copper Ore', materialNumber: 1},
    {level: 10, item: 'Bronze Bar and Forge Item at Anvil', experience: 7, image: CopperOre, secondaryImage: TinOre, thirdImage: BronzeBar, fourthImage: Anvil, material: CopperOre, materialName: 'Copper Ore', materialNumber: 1, secondaryMaterial: TinOre, secondaryMaterialName: 'Tin Ore', secondaryMaterialNumber: 1, combined: true},
    {level: 20, item: 'Iron Bar and Forge Item at Anvil', experience: 11, image: IronOre, secondaryImage: IronBar, thirdImage: Anvil, material: IronOre, materialName: 'Iron Ore', materialNumber: 1},
    {level: 30, item: 'Steel Bar and Forge Item at Anvil', experience: 14, image: IronOre, secondaryImage: Coal, thirdImage: SteelBar, fourthImage: Anvil, material: IronOre, materialName: 'Iron ore', materialNumber: 1, secondaryMaterial: Coal, secondaryMaterialName: 'Coal', secondaryMaterialNumber: 1, combined: true},
    {level: 40, item: 'Psychic Bar and Forge Item at Anvil', experience: 17, image: PsywoodLogs, secondaryImage: Coal, thirdImage: PsychicBar, fourthImage: Anvil, material: PsywoodLogs, materialName: 'Psywood Logs', materialNumber: 1, secondaryMaterial: Coal, secondaryMaterialName: 'Coal', secondaryMaterialNumber: 2, combined: true},
    {level: 50, item: 'E. Copper Bar and Forge Item at Anvil', experience: 20, image: CopperBar, secondaryImage: PsywoodLogs, thirdImage: ECopperBar, fourthImage: Anvil, material: CopperBar, materialName: 'Copper Bar', materialNumber: 1, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 60, item: 'E. Gold Bar and Forge Item at Anvil', experience: 25, image: GoldBar, secondaryImage: PsywoodLogs, thirdImage: EGoldBar, fourthImage: Anvil, material: GoldBar, materialName: 'Gold Bar', materialNumber: 1, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Logs', secondaryMaterialNumber: 1, combined: true},
    {level: 60, item: 'Gold Bar and E. Gold Bar and Forge Item at Anvil', experience: 41, image: GoldOre, secondaryImage: GoldBar, thirdImage: PsywoodLogs, fourthImage: EGoldBar, fifthImage: Anvil, material: GoldOre, materialName: 'Gold Ore', materialNumber: 1, secondaryMaterial: PsywoodLogs, secondaryMaterialName: 'Psywood Logs', secondaryMaterialNumber: 1},
    {level: 70, item: 'Damascus Bar and Forge Item at Anvil', experience: 28, image: DamascusOre, secondaryImage: Coal, thirdImage: DamascusBar, fourthImage: Anvil, material: DamascusOre, materialName: 'Damascus Ore', materialNumber: 1, secondaryMaterial: Coal, secondaryMaterialName: 'Coal', secondaryMaterialNumber: 4, combined: true},
];


export default class Metalworking extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {currentExperience: '', desiredLevel: 0, experienceNeeded: 0, metalworkingBarMakingData: metalworkingBarMakingData, metalworkingBarAndForgeData: metalworkingBarAndForgeData, metalworkingForgeItemData: metalworkingForgeItemData}
    }

    handleLevelChange(event)
    {
        let level = event.target.value === '' ? '' : event.target.value;
        let currentExperience = levels[level];
        this.setState({currentExperience: currentExperience});
        this.setState({currentLevel: level});
        this.handleExperienceChange(currentExperience, this.state.metalworkingBarMakingData, this.state.metalworkingForgeItemData, this.state.metalworkingBarAndForgeData, level)
    }

    handleExperienceChange(event, metalworkingBarMakingData, metalworkingForgeItemData, metalworkingBarAndForgeData, level)
    {
        let currentExperience = event ? event.target ? event.target.value : event : 0;

        this.setState({currentExperience: currentExperience});
        let experienceGoal = this.state.desiredLevel ? levels[this.state.desiredLevel] : 0;
        let experienceNeeded = experienceGoal - currentExperience;
        this.setState({experienceNeeded: experienceNeeded });
        let currentLevel;

        if(!level && level !== '')
        {
            currentLevel = findCurrentLevel(currentExperience, levels);
            this.setState({currentLevel: currentLevel});
        }
        else
        {
            currentLevel = level;
        }

        metalworkingBarMakingData = calculateNumberNeeded(metalworkingBarMakingData, experienceNeeded, currentLevel);
        this.setState({metalworkingBarMakingData: metalworkingBarMakingData});
        metalworkingBarMakingData = calculateMaterialsNeeded(metalworkingBarMakingData, currentLevel);
        this.setState({metalworkingBarMakingData: metalworkingBarMakingData});

        metalworkingForgeItemData = calculateNumberNeeded(metalworkingForgeItemData, experienceNeeded, currentLevel);
        this.setState({metalworkingForgeItemData: metalworkingForgeItemData});
        metalworkingForgeItemData = calculateMaterialsNeeded(metalworkingForgeItemData, currentLevel);
        this.setState({metalworkingForgeItemData: metalworkingForgeItemData});

        metalworkingBarAndForgeData = calculateNumberNeeded(metalworkingBarAndForgeData, experienceNeeded, currentLevel);
        this.setState({metalworkingBarAndForgeData: metalworkingBarAndForgeData});
        metalworkingBarAndForgeData = calculateMaterialsNeeded(metalworkingBarAndForgeData, currentLevel);
        this.setState({metalworkingBarAndForgeData: metalworkingBarAndForgeData});
    }

    handleDesiredLevelChange(event, metalworkingBarMakingData, metalworkingForgeItemData, metalworkingBarAndForgeData)
    {
        this.setState({desiredLevel: event.target.value});
        let experienceGoal = event.target.value ? levels[event.target.value] : 0;
        this.setState({experienceNeeded: experienceGoal - this.state.currentExperience });
        let currentLevel = findCurrentLevel(this.state.currentExperience);
        this.setState({currentLevel: currentLevel});

        if(this.state.currentExperience == '')
        {
            this.setState({currentExperience: levels[currentLevel]});
        }

        metalworkingBarMakingData = calculateNumberNeeded(metalworkingBarMakingData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({metalworkingBarMakingData: metalworkingBarMakingData});
        metalworkingBarMakingData = calculateMaterialsNeeded(metalworkingBarMakingData, currentLevel);
        this.setState({metalworkingBarMakingData: metalworkingBarMakingData});

        metalworkingForgeItemData = calculateNumberNeeded(metalworkingForgeItemData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({metalworkingForgeItemData: metalworkingForgeItemData});
        metalworkingForgeItemData = calculateMaterialsNeeded(metalworkingForgeItemData, currentLevel);
        this.setState({metalworkingForgeItemData: metalworkingForgeItemData});

        metalworkingBarAndForgeData = calculateNumberNeeded(metalworkingBarAndForgeData, experienceGoal - this.state.currentExperience, currentLevel);
        this.setState({metalworkingBarAndForgeData: metalworkingBarAndForgeData});
        metalworkingBarAndForgeData = calculateMaterialsNeeded(metalworkingBarAndForgeData, currentLevel);
        this.setState({metalworkingBarAndForgeData: metalworkingBarAndForgeData});
    }

    render()
    {
        return (
            <div>
                <TopNav></TopNav>

                <div>
                <Segment clearing>
                    <Header as='h1' dividing floated='left'>
                        <Image src={MetalworkIcon} className="header-left"></Image>
                        <Header.Content>Metalwork
                            <Header.Subheader>Artisan</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Header as='h1' dividing floated='right'>
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>Current Experience</label>
                                    <Input maxLength="7" style={{width: 170}} value={this.state.currentExperience} fluid placeholder='Current Experience' onChange={(e) => this.handleExperienceChange(e, this.state.metalworkingBarMakingData, this.state.metalworkingForgeItemData, this.state.metalworkingBarAndForgeData)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Current Level</label>
                                    <Input maxLength="2" style={{width: 170}} value={this.state.currentLevel} fluid placeholder='Current Level' onChange={(e) => this.handleLevelChange(e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Desired Level</label>
                                    <Input maxLength="2" style={{width: 170}}  fluid placeholder='Desired Level' onChange={(e) => this.handleDesiredLevelChange(e, this.state.metalworkingBarMakingData, this.state.metalworkingForgeItemData, this.state.metalworkingBarAndForgeData)} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Header>
                </Segment>
                
                <Divider/>

                    <Grid columns={1} divided>
                    <Grid.Row className="table-margin">
                        <Grid.Column stretched>

                        <Divider horizontal>
                        <Header as='h2'>
                            <Image src={AnvilIcon} size="large"/>
                            <span className="divider-spacer">Bars</span>
                        </Header>
                        </Divider>

                            <Segment raised>
                                <Table celled color='yellow' compact="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                            <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.metalworkingBarMakingData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>


                            <Divider horizontal>
                                <Header as='h2'>
                                <Image src={HelmIcon} size="large"/>
                                <span className="divider-spacer">Items</span>
                                </Header>
                            </Divider>

                            <Segment raised>
                                <Table celled color='yellow' compact="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                            <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.metalworkingForgeItemData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>

                            <Divider horizontal>
                                <Header as='h2'>
                                    <Image src={AnvilIcon} size="large"/>
                                    <span className="divider-spacer">Bars and Items</span>
                                </Header>
                            </Divider>

                            <Segment raised>

                                <Table celled color='yellow' compact="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Level</Table.HeaderCell>
                                            <Table.HeaderCell>Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Experience Gained Per Action / Item</Table.HeaderCell>
                                            <Table.HeaderCell>Number Needed to Reach Desired Level</Table.HeaderCell>
                                            <Table.HeaderCell>Materials Needed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {buildRows(this.state.metalworkingBarAndForgeData, this.state.currentLevel)}
                                    </Table.Body>

                                </Table>
                            </Segment>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </div>
                
                
            </div>
            
        )
    }
}